import {Box} from '~/components/kit/box';
import {Typography} from '~/components/kit/typography';
import {BlackHoleRankChip} from '~/entities/earn/black-hole/ui/black-hole-rank-chip';
import {getBlackHoleRank} from '~/entities/earn/black-hole/black-hole-ranks';
import {rem} from '~/shared/utils/style-helpers';
import {useAppTheme} from '~/components/kit';
import {RokeTokenLogo} from '~/entities/earn/roke-tokens';
import starBackground from './assets/balance-card-stars-bg.png';
import {ImgTransition} from '~/components/kit/img-transition';
import {Button} from '~/components/kit/button/button';
import {RokeTokens} from '~/entities/roke-tokens';
import {useSignals} from '@preact/signals-react/runtime';
import {
  airdropReleaseTime,
  isAirdropReleasedForUser,
} from '~/features/airdrops/feature-flag';
import {useModals} from '~/components/kit/modal';
import {Airdrops, useAirdropModel} from '~/entities/airdrops';
import {AirdropModal} from '~/features/airdrops/airdrop-modal';
import {AIRDROP_MODAL_NAME} from '../airdrop-modal';
import React from 'react';
import {formatCountdownWords} from '~/shared/utils/format/format-time';
import {formatNumber} from '~/shared/utils/format/format-numbers';

export function RokeTokenBalanceCard({
  blackHoleSize,
  ...rest
}: {
  blackHoleSize: number;
} & React.HTMLAttributes<HTMLDivElement>) {
  useSignals();
  const rank = getBlackHoleRank(blackHoleSize);
  const theme = useAppTheme();
  const modals = useModals();

  const isReleasedForUser = isAirdropReleasedForUser.value;
  // TODO: Enable airdrop
  const airdropModel = useAirdropModel(Airdrops.FirstSeason);
  const tokenBalance = airdropModel.airdrop.data.amount;
  const usdValue = formatNumber(RokeTokens.toFloat(tokenBalance));

  return (
    <React.Fragment>
      {!airdropModel.isAirdropPending && (
        <AirdropModal
          airdropName={Airdrops.FirstSeason}
          seasonRank={airdropModel.airdrop.data.rank}
          airdropAmount={airdropModel.airdrop.data.amount}
          isClaimed={airdropModel.isClaimed}
        />
      )}
      <Box
        bgImage={
          <ImgTransition
            src={starBackground}
            css={{
              width: '60%',
              right: '5%',
              top: '5%',
              position: 'absolute',
            }}
          />
        }
        backgroundColor="#0F061F"
        css={{
          padding: rem(20),
        }}
        radius={30}
        {...rest}
      >
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography.body
            css={{
              color: theme.colors.onSurfaceAlternativeSecondary,
              fontSize: theme.fontSize.s7,
            }}
          >
            Singularity Balance
          </Typography.body>
          <BlackHoleRankChip rank={rank} />
        </div>

        <div
          css={{
            paddingTop: rem(8),
          }}
        >
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography.h1
              css={{
                color: theme.colors.onSurfaceAlternative,
              }}
            >
              {RokeTokens.format(tokenBalance, {
                precision: 3,
              })}
            </Typography.h1>
            <RokeTokenLogo
              css={{
                marginLeft: rem(6),
              }}
            />
          </div>
          <div
            css={{
              color: theme.colors.onSurfaceAlternativeSecondary,
              fontSize: theme.fontSize.s6,
              marginTop: rem(4),
            }}
          >
            ≈ ${usdValue}
          </div>
        </div>

        {airdropModel.hasNoAirdrop ? (
          <Button
            color="purple"
            isDisabled
            isFlat
            css={{
              marginTop: rem(20),
              width: '100%',
              filter: isReleasedForUser ? 'grayscale(0)' : 'grayscale(0.5)',
            }}
          >
            No airdrop for you 🥺
          </Button>
        ) : (
          <Button
            color="purple"
            css={{
              marginTop: rem(20),
              width: '100%',
              filter: isReleasedForUser ? 'grayscale(0)' : 'grayscale(0.5)',
            }}
            isFlat
            isDisabled={!isReleasedForUser}
            onClick={() => {
              isReleasedForUser && modals.open(AIRDROP_MODAL_NAME);
            }}
          >
            {airdropModel.isClaimed
              ? 'Share Result'
              : isReleasedForUser
              ? 'Get $ROKE'
              : `Your drop in ${formatCountdownWords(
                  new Date(airdropReleaseTime.value),
                  true,
                )}`}
          </Button>
        )}
      </Box>
    </React.Fragment>
  );
}
