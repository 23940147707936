import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useAuthorizationToken} from '~/entities/user';
import {AdsGramApi, IAdsResponse} from './api';
import {isQueryReady} from '~/shared/utils/query';
import {toServerTimestamp} from '~/shared/utils/format/format-time';
import {trackUserWatchedAd} from './analytics';

export function useAdsGramModel() {
  const token = useAuthorizationToken();
  const queryClient = useQueryClient();

  const adsInfo = useQuery({
    queryKey: ['ads-info'],
    queryFn: () => {
      return AdsGramApi.getRemainingAds(token);
    },
  });

  const adsData: IAdsResponse = adsInfo.data?.data || {
    ad_logs_count: 0,
    ad_logs_count_limit: 0,
    next_try_time: toServerTimestamp(0),
    reward_amount: 0,
  };

  const watchAd = useMutation({
    mutationKey: ['ads-watch-ad'],
    mutationFn: () => {
      return AdsGramApi.watchAd(token);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['ads-info'], data);
      trackUserWatchedAd();
    },
  });

  return {
    isOverLimit: adsData.ad_logs_count >= adsData.ad_logs_count_limit,
    isReady: isQueryReady(adsInfo),
    adsInfo: {
      query: adsInfo,
      data: adsData,
    },
    watchAd,
  };
}
