import {lunarToFloat} from '~/entities/lunar-loot';
import {ClientTimestamp} from '~/shared/utils/format/format-time';

export class DockProgressCalculator {
  private minClaimTime: ClientTimestamp;
  private lastClaimedTime: ClientTimestamp;

  constructor({
    minClaimTime,
    lastClaimedTime,
  }: {
    minClaimTime: ClientTimestamp;
    lastClaimedTime: ClientTimestamp;
  }) {
    this.minClaimTime = minClaimTime;
    this.lastClaimedTime = lastClaimedTime;
  }

  get timeLeft() {
    const timeSinceLastClaim = Date.now() - this.lastClaimedTime;

    return Math.max(this.minClaimTime - timeSinceLastClaim, 0);
  }

  get percentageProgress() {
    return Math.min((Date.now() - this.lastClaimedTime) / this.minClaimTime, 1);
  }

  get canClaim() {
    return this.timeLeft === 0;
  }
}

// 1 second to account for desync between client and server
const DESYNC_OFFSET = 1000;

export class DockBalanceCalculator {
  private combinedIdleMiningSpeed: number;
  private lastClaimedTime: ClientTimestamp;
  private dockSize: ClientTimestamp;

  constructor({
    combinedIdleMiningSpeed,
    lastClaimedTime,
    dockSize,
  }: {
    combinedIdleMiningSpeed: number;
    lastClaimedTime: ClientTimestamp;
    dockSize: ClientTimestamp;
  }) {
    this.combinedIdleMiningSpeed = combinedIdleMiningSpeed;

    this.lastClaimedTime = lastClaimedTime;
    this.dockSize = dockSize;
  }

  get _elapsedTime() {
    const timeSinceLastClaim =
      Date.now() + DESYNC_OFFSET - this.lastClaimedTime;

    const elapsedTime = Math.min(this.dockSize, timeSinceLastClaim);

    return elapsedTime;
  }

  get dockBalance() {
    return Math.max(
      Math.round(this.combinedIdleMiningSpeed * this._elapsedTime),
      0,
    );
  }

  get dockMaxBalance() {
    console.debug('Dock Max Balance', {
      speedPerHour: lunarToFloat(this.combinedIdleMiningSpeed * 1000 * 3600),
      dockSizeHours: this.dockSize / 3600 / 1000,
      dockSizeMoons: lunarToFloat(this.combinedIdleMiningSpeed * this.dockSize),
    });
    return Math.round(this.combinedIdleMiningSpeed * this.dockSize);
  }

  get isFull() {
    return this._elapsedTime === this.dockSize;
  }

  get timeUntilFull() {
    return Math.max(this.dockSize - this._elapsedTime, 0);
  }
}

// export function calculateCurrentDockBalance(
//   idleMining: Pick<
//     IIdleMiningProps,
//     'combined_idle_mining_speed' | 'dock_size' | 'last_claimed_time'
//   >,
// ) {
//   const elapsedTime =
//     Math.min(
//       idleMining.dock_size,
//       Date.now() - new Date(idleMining.last_claimed_time).getTime(),
//     ) / 1000;

//   return Number(idleMining.combined_idle_mining_speed) * elapsedTime;
// }
