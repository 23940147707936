import {Box} from '~/components/kit/box';
import {Button} from '~/components/kit/button/button';
import {useModals} from '~/components/kit/modal';
import {Typography} from '~/components/kit/typography';
import {WALLET_CONNECT_MODAL_NAME} from './wallet-connect-modal';
import {useAppTheme} from '~/components/kit';
import {rem} from '~/shared/utils/style-helpers';
import {opacity} from '~/shared/utils/colors';

export function WalletConnectCard({...props}: React.HTMLProps<HTMLDivElement>) {
  const modals = useModals();
  const theme = useAppTheme();

  return (
    <Box
      backgroundColor="#786EED"
      css={{
        textAlign: 'center',
        color: theme.colors.onSurfaceAlternative,
        padding: rem(20),
      }}
      {...props}
    >
      <Typography.h6
        css={{
          marginBottom: rem(8),
          fontWeight: 800,
        }}
      >
        Extra activities for wallet connection!
      </Typography.h6>
      <Typography.body
        css={{
          marginBottom: rem(8),
          fontSize: theme.fontSize.s7,
          color: opacity(theme.colors.onSurfaceAlternative, 0.8),
        }}
      >
        Got an active ETH wallet? Plug it in for an extra gravity-defying
        bonuses. We're not just a bank, we're a financial black hole — sucking
        in profits, not light!
      </Typography.body>

      <Button
        color="dark"
        onClick={() => {
          modals.open(WALLET_CONNECT_MODAL_NAME);
        }}
        css={{
          width: '100%',
        }}
      >
        Connect Wallet
      </Button>
    </Box>
  );
}
