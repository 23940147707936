import {useEarnModel} from '~/entities/earn';
import {WalletIcon} from './wallet-icon';
import {Icon} from '~/components/kit/icons';
import {useClickAway} from '@uidotdev/usehooks';
import {useState} from 'react';
import {WalletControlMenu} from './wallet-control-menu';
import {rem} from '~/shared/utils/style-helpers';
import {Link} from '~/components/Link/Link';
import {RoutePath} from '~/app/navigation/routes';

export function WalletOpenButton() {
  const earnModel = useEarnModel();
  const [isMenuOpened, openMenu] = useState(false);

  const ref = useClickAway<HTMLButtonElement>(() => {
    if (isMenuOpened) {
      openMenu(false);
    }
  });

  if (!earnModel.isWalletConnected) {
    return (
      <Link to={RoutePath.OneTimeTasks}>
        <button
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Icon size={40}>
            <WalletIcon isDisconnected={!earnModel.isWalletConnected} />
          </Icon>
        </button>
      </Link>
    );
  }

  return (
    <button
      ref={ref}
      css={{
        display: 'flex',
        alignItems: 'center',
      }}
      onClick={() => {
        openMenu(!isMenuOpened);
      }}
    >
      <Icon size={40}>
        <WalletIcon isDisconnected={false}></WalletIcon>
      </Icon>

      {isMenuOpened && (
        <WalletControlMenu
          css={{
            position: 'absolute',
            zIndex: 2,
            top: rem(80),
            right: rem(20),
          }}
        />
      )}
    </button>
  );
}
