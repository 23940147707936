import React from 'react';
import {useAppTheme} from '~/components/kit';
import {Box} from '~/components/kit/box';
import {Icon} from '~/components/kit/icons';
import {Typography} from '~/components/kit/typography';
import {rem} from '~/shared/utils/style-helpers';
import {RocketPurple} from './assets/rocket-purple';
import {StarShine} from '~/components/kit/star';
import {BwButton} from '~/components/kit/bw-button';
import {ArrowRightIcon} from '~/components/kit/icons/arrow-right-icon';
import {Link} from '~/components/Link/Link';
import {Delimiter} from '~/components/kit/delimiter';
import {GlobeIcon} from '~/components/kit/icons/globe-icon';
import {RocketIcon} from '~/components/kit/icons/rocket-icon';

export function LeaderboardLinkCard({
  rank,
  topPercent,
  to,
}: {
  rank: number;
  topPercent: number;
  to: string;
}) {
  const theme = useAppTheme();
  return (
    <Link to={to}>
      <Box
        backgroundColor="#151542"
        css={{
          color: theme.colors.onSurfaceAlternative,
          padding: rem(18),
          display: 'flex',
          alignItems: 'center',
        }}
        radius={16}
        bgImage={
          <React.Fragment>
            <StarShine
              x={0.52}
              y={0.23}
              coreColor={'#8BDEB1'}
              rotation={0}
              starSize={0.6}
            ></StarShine>
            <StarShine
              x={0.62}
              y={0.33}
              coreColor={'#92D8FF'}
              rotation={0}
              starSize={1}
            ></StarShine>
            <StarShine
              x={0.67}
              y={0.23}
              coreColor={'#DDCCFF'}
              rotation={0}
              starSize={0.75}
            ></StarShine>
            <StarShine
              x={0.72}
              y={0.73}
              coreColor={'#D1F4FF'}
              rotation={0}
              starSize={0.5}
            ></StarShine>
          </React.Fragment>
        }
      >
        <Icon
          size={90}
          css={{
            position: 'absolute',
            zIndex: -1,
            top: rem(-20),
            transform: 'translateY(-15%)',
            right: 0,
          }}
        >
          <RocketPurple />
        </Icon>
        <div>
          <Typography.h4
            css={{
              marginBottom: rem(8),
            }}
          >
            Leaderboard
          </Typography.h4>
          <div
            css={{
              display: 'flex',
            }}
          >
            <Typography.h6
              css={{
                color: theme.colors.onSurfaceAlternativeSecondary,
                lineHeight: 1.35,
              }}
            >
              Your <br /> Ranking
            </Typography.h6>
            <Delimiter
              isVertical={true}
              color="#FFFFFF"
              css={{
                opacity: 0.2,
                alignSelf: 'stretch',
                marginLeft: rem(8),
                marginRight: rem(8),
              }}
            />
            <div>
              <Typography.body
                css={{
                  color: theme.colors.onSurfaceAlternativeSecondary,
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                <Icon
                  size={12}
                  css={{
                    color: theme.colors.accents.yellow.surface,
                    marginRight: rem(5),
                  }}
                >
                  <GlobeIcon />
                </Icon>{' '}
                <span
                  css={{
                    fontWeight: 700,
                    color: theme.colors.onSurfaceAlternative,
                  }}
                >
                  #{rank}
                </span>
                &nbsp;worldwide
              </Typography.body>
              <Typography.body
                css={{
                  color: theme.colors.onSurfaceAlternativeSecondary,
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                <Icon
                  size={12}
                  css={{
                    color: theme.colors.accents.yellow.surface,
                    marginRight: rem(5),
                  }}
                >
                  <RocketIcon />
                </Icon>{' '}
                <span
                  css={{
                    fontWeight: 700,
                    color: theme.colors.onSurfaceAlternative,
                  }}
                >
                  top{' '}
                  <span
                    css={{
                      color: theme.colors.accents.yellow.surface,
                    }}
                  >
                    {topPercent}%
                  </span>
                </span>
                &nbsp; astronauts
              </Typography.body>
            </div>
          </div>
        </div>

        <BwButton
          css={{
            flexShrink: 0,
            marginLeft: 'auto',
            height: 'unset',
            alignSelf: 'stretch',
          }}
        >
          <Icon size={20}>
            <ArrowRightIcon />
          </Icon>
        </BwButton>
      </Box>
    </Link>
  );
}
