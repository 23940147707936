import {useAppTheme, RewardValue} from '~/components/kit';
import {AspectRatio} from '~/components/kit/aspect-ratio';
import {Typography} from '~/components/kit/typography';
import {lunar} from '~/entities/lunar-loot';
import {shortNumber, toFloatCrystals} from '~/shared/utils/format/format-numbers';
import {rem} from '~/shared/utils/style-helpers';
import {RewardType} from '~/entities/rewards';

export function StreakSign({
  streak,
  reward,
  rewardType = 'lunar',
  ...rest
}: {streak: number; reward: number; rewardType: RewardType} & React.HTMLAttributes<HTMLDivElement>) {
  const theme = useAppTheme();
  const rewardValue = rewardType === 'lunar'
    ? shortNumber(Number(lunar(reward, {precision: 2})))
    : toFloatCrystals(reward);

  return (
    <AspectRatio
      ratio={143 / 75}
      css={{
        width: rem(100),
      }}
      {...rest}
    >
      <div
        css={{
          display: 'inline-flex',
          alignItems: 'center',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: rem(20),
            marginLeft: '5%',
            height: '60%',
          }}
        >
          <Typography.h1>{streak}</Typography.h1>
          <Typography.body
            css={{
              marginTop: rem(4),
              marginBottom: rem(4),
              fontSize: theme.fontSize.odds.s10,
              color: theme.colors.onSurfaceSecondary,
              fontWeight: 600,
            }}
          >
            Day streak
          </Typography.body>
          <div>
            <RewardValue
              value={'+' + rewardValue}
              rewardType={rewardType}
            />
          </div>
        </div>

        <div
          css={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            zIndex: -1,
          }}
        >
          <svg
            height="151"
            width="94"
            fill="none"
            viewBox="0 0 94 151"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            css={{
              width: '100%',
            }}
          >
            <ellipse cx="48.5952" cy="145.889" fill="black" rx="14" ry="4.5" />
            <line
              stroke="black"
              strokeWidth="5"
              x1="49.0952"
              x2="49.0952"
              y1="143.389"
              y2="89.3892"
            />
            <g filter="url(#filter0_d_3009_86390)">
              <rect
                height="93"
                width="75"
                fill="white"
                rx="16"
                x="11.5952"
                y="7.38916"
              />
              <rect
                height="92"
                width="74"
                rx="15.5"
                stroke="#111320"
                x="12.0952"
                y="7.88916"
              />
              <g filter="url(#filter1_d_3009_86390)">
                <path
                  d="M78.2617 3.54633L79.641 7.03143C79.8558 7.57422 80.5564 7.7192 80.969 7.30625L86.3352 1.93547C86.7228 1.54751 87.3662 1.64719 87.5923 2.14685C88.9064 5.05131 92.3079 13.6957 89.3548 19.7398C86.7058 25.1614 80.5567 28.0618 75.0236 25.6546C69.7256 23.3496 67.4962 17.6747 69.3978 12.219C71.2898 6.79063 75.5139 4.05734 77.2214 3.14176C77.6205 2.92776 78.0951 3.12525 78.2617 3.54633Z"
                  fill="#F7E958"
                />
                <path
                  d="M80.2465 6.79178L78.8673 3.30669C78.5707 2.55719 77.687 2.15314 76.9136 2.56783C75.143 3.51728 70.7526 6.35333 68.7829 12.0046C66.778 17.7566 69.1291 23.8003 74.7638 26.2517C80.6738 28.8229 87.1669 25.7011 89.9399 20.0257C91.5088 16.8146 91.3686 12.9726 90.7127 9.64022C90.0537 6.29183 88.853 3.35361 88.1856 1.87839C87.7607 0.93932 86.5646 0.784475 85.8745 1.47517L80.5083 6.84596C80.427 6.92736 80.2889 6.89878 80.2465 6.79178Z"
                  stroke="black"
                  strokeLinejoin="round"
                  strokeWidth="1.30247"
                />
              </g>
              <g filter="url(#filter2_d_3009_86390)">
                <path
                  d="M4.28039 37.5692L7.06889 39.3541C7.50319 39.6321 8.08011 39.3741 8.16246 38.8651L9.23351 32.2447C9.31088 31.7665 9.8234 31.5056 10.2435 31.7469C12.6854 33.1493 19.5879 37.5867 20.5938 43.4431C21.496 48.6963 18.5879 53.9508 13.3796 55.0839C8.39262 56.1688 3.86389 53.2539 2.40844 48.3622C0.960306 43.4952 2.57529 39.3547 3.3259 37.8167C3.50135 37.4572 3.94347 37.3535 4.28039 37.5692Z"
                  fill="#F7E958"
                />
                <path
                  d="M7.37902 38.8696L4.59052 37.0847C3.99083 36.7008 3.1489 36.8678 2.80893 37.5644C2.03055 39.1593 0.349457 43.4593 1.85707 48.5263C3.39156 53.6836 8.19804 56.7999 13.5019 55.646C19.0649 54.4358 22.1052 48.8449 21.1607 43.3457C20.6263 40.2343 18.5413 37.5537 16.3501 35.5046C14.1483 33.4457 11.7703 31.9604 10.53 31.248C9.74046 30.7946 8.80338 31.3015 8.66564 32.1529L7.59458 38.7732C7.57835 38.8735 7.46463 38.9244 7.37902 38.8696Z"
                  stroke="black"
                  strokeLinejoin="round"
                  strokeWidth="1.15051"
                />
              </g>
              <path
                d="M77.9674 11.4846L78.342 13.1994C78.4073 13.4982 78.78 13.6025 78.9909 13.381L81.591 10.6496C81.7693 10.4623 82.074 10.5031 82.1848 10.7368C82.824 12.0863 84.4692 16.0797 83.0828 18.9172C81.8372 21.4666 78.8667 22.9241 76.2649 21.7922C73.7736 20.7083 72.8107 17.8889 73.7048 15.3235C74.5847 12.799 76.6262 11.6263 77.485 11.2314C77.6909 11.1367 77.919 11.2631 77.9674 11.4846Z"
                fill="#FF3F3F"
              />
              <path
                d="M8.16949 43.4103L9.32372 44.4456C9.52483 44.626 9.84579 44.5082 9.88247 44.2405L10.3348 40.9403C10.3658 40.7139 10.6052 40.5858 10.8053 40.6961C11.9605 41.3329 15.202 43.3447 15.6742 46.094C16.0985 48.5642 14.7228 51.143 12.2737 51.6758C9.92871 52.186 7.78239 50.6631 7.098 48.3629C6.42453 46.0994 7.28161 44.2046 7.69303 43.478C7.7917 43.3038 8.02043 43.2766 8.16949 43.4103Z"
                fill="#FF3F3F"
              />
            </g>
            <defs>
              <filter
                height="100.992"
                id="filter0_d_3009_86390"
                width="92.1543"
                x="0.674805"
                y="0.397461"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood result="BackgroundImageFix" floodOpacity="0" />
                <feBlend
                  result="effect1_dropShadow_3009_86390"
                  in2="BackgroundImageFix"
                />
                <feBlend
                  result="shape"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_3009_86390"
                />
              </filter>
              <filter
                height="28.7212"
                id="filter1_d_3009_86390"
                width="25.7051"
                x="67.4265"
                y="0.397461"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood result="BackgroundImageFix" floodOpacity="0" />
                <feBlend
                  result="effect1_dropShadow_3009_86390"
                  in2="BackgroundImageFix"
                />
                <feBlend
                  result="shape"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_3009_86390"
                />
              </filter>
              <filter
                height="27.111"
                id="filter2_d_3009_86390"
                width="22.3815"
                x="0.674805"
                y="30.501"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood result="BackgroundImageFix" floodOpacity="0" />
                <feBlend
                  result="effect1_dropShadow_3009_86390"
                  in2="BackgroundImageFix"
                />
                <feBlend
                  result="shape"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_3009_86390"
                />
              </filter>
            </defs>
          </svg>
        </div>
      </div>
    </AspectRatio>
  );
}
