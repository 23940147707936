import React from 'react';
import {rem} from '~/shared/utils/style-helpers';

export function Icon({
  size,
  ...rest
}: React.HTMLProps<HTMLDivElement> & {
  size: number;
}) {
  return (
    <i
      css={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        width: rem(size),
        height: rem(size),
        svg: {
          width: '100%',
          height: '100%',
        },
        img: {
          width: '100%',
          height: '100%',
        },
      }}
      {...rest}
    ></i>
  );
}
