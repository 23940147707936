import {IAuthorizationResponse} from '~/api';
import {apiClient} from '~/api/shared/api-client';
import {ServerTimestamp} from '~/shared/utils/format/format-time';

export interface IAdsResponse {
  ad_logs_count: number;
  ad_logs_count_limit: number;
  next_try_time: ServerTimestamp;
  reward_amount: number;
}

function getRemainingAds(token: IAuthorizationResponse) {
  return apiClient.get<IAdsResponse>('/ad-logs/', {
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });
}

function watchAd(token: IAuthorizationResponse) {
  return apiClient.post<IAdsResponse>(
    '/ad-logs/',
    {},
    {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    },
  );
}

export const AdsGramApi = {
  getRemainingAds,
  watchAd,
};
