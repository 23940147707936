import discordIcon from './assets/discord-logo.svg';
import telegramIcon from './assets/telegram-logo.svg';
import xIcon from './assets/x-logo.svg';
import {rem} from '~/shared/utils/style-helpers';
import {IconButton} from '~/components/kit/button/button';
import {useUtils} from '@telegram-apps/sdk-react';

export type SocialMedia = 'twitter' | 'discord' | 'telegram';

const links: Record<SocialMedia, string> = {
  telegram: 'https://t.me/theroketo',
  discord: 'https://discord.com/invite/ydaDeEMF3t',
  twitter: 'https://x.com/theroketo',
};

const colors: Record<SocialMedia, string> = {
  twitter: '#E8E6F6',
  discord: '#8979FF',
  telegram: '#87B7FF',
};

interface ISocialIconProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  social: SocialMedia;
}

export function SocialMediaLink({social, onClick, ...rest}: ISocialIconProps) {
  const utils = useUtils();

  let icon = xIcon;
  if (social === 'discord') {
    icon = discordIcon;
  } else if (social === 'telegram') {
    icon = telegramIcon;
  }

  return (
    <IconButton
      backgroundColor={colors[social]}
      onClick={(e) => {
        onClick && onClick(e);

        if (social === 'telegram') {
          utils.openTelegramLink(links[social]);
        } else {
          utils.openLink(links[social]);
        }
      }}
      {...rest}
    >
      <img
        src={icon}
        alt={social}
        css={{
          width: rem(25),
          height: rem(15),
        }}
      />
    </IconButton>
  );
}
