import {getElementCircularly} from '~/shared/utils/arrays';

export const LightningLeft = ({
  mainColor,
  raysColor,
}: {
  mainColor: string;
  raysColor: string | string[];
}) => (
  <svg
    width="39"
    height="61"
    viewBox="0 0 39 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.1581 31.0422L1.50012 41.7851L26.034 40.4967L19.3751 49.7715L29.4247 42.4437L27.9325 48.3459M37.6202 7.32776L37.6202 14.3939L30.423 10.9663L33.4446 20.637L11.16 17.2094L23.554 29.4571M38.2031 58.3332L34.5593 59.3198L36.4916 52.7504L25.1581 59.3198L27.4442 50.2776"
      stroke={mainColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.1945 56.1535L10.901 53.6681L7.91878 52.5635L11.4011 50.3067"
      stroke={
        typeof raysColor === 'object'
          ? getElementCircularly(raysColor, 0)
          : raysColor
      }
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4828 4.20647L18.3834 9.79408L17.9722 5.97044L22.0703 9.82905"
      stroke={
        typeof raysColor === 'object'
          ? getElementCircularly(raysColor, 1)
          : raysColor
      }
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.55439 22.8947L3.19116 22.329"
      stroke={
        typeof raysColor === 'object'
          ? getElementCircularly(raysColor, 2)
          : raysColor
      }
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
