import {Typography, AestheticsLine} from '~/components/kit/typography';
import {rem} from '~/shared/utils/style-helpers';
import {RewardsContent} from '../components/rewards-content';
import {useAppTheme} from '~/components/kit';
import {useRewards} from '~/entities/rewards';
import {PartnerTask} from '~/features/partner-tasks/ui/partner-task';
import {useEarnModel} from '~/entities/earn';
import {WalletConnectCard} from '~/features/connect-wallet/wallet-connect-card';
import {WalletConnectModal} from '~/features/connect-wallet/wallet-connect-modal';
import React from 'react';

function CompletedTasksDelimiter({
  nClaimed,
  nMax,
  ...props
}: {
  nClaimed: number;
  nMax: number;
} & React.HTMLProps<HTMLDivElement>) {
  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
      }}
      {...props}
    >
      <div
        css={{
          flexGrow: 1,
          borderTop: `1px dashed`,
        }}
      ></div>
      <div
        css={{
          marginLeft: rem(8),
          marginRight: rem(8),
        }}
      >
        {nClaimed}/{nMax} Completed
      </div>
      <div
        css={{
          flexGrow: 1,
          borderTop: `1px dashed`,
        }}
      ></div>
    </div>
  );
}
export function OneTimeTasksPage() {
  const theme = useAppTheme();
  const rewards = useRewards();
  const unclaimedPartnerTasks = rewards.model.unclaimedPartnerTasks;
  const claimedPartnerTasks = rewards.model.claimedPartnerTasks;

  const earnModel = useEarnModel();

  return (
    <div>
      <WalletConnectModal />
      <header
        css={{
          margin: 'auto',
          marginBottom: rem(24),
          textAlign: 'center',
          borderRadius: 30,
        }}
      >
        <Typography.h3
          css={{
            fontWeight: 800,
            marginBottom: rem(10),
          }}
        >
          <AestheticsLine>One-time</AestheticsLine> Tasks
        </Typography.h3>

        <p
          css={{
            fontWeight: 500,
            fontSize: theme.fontSize.s7,
            color: theme.colors.onSurfaceTertiary,
          }}
        >
          Complete unique missions and collect Lunar Loot Rewards! Don't miss
          your chance to become a space hero — rewards await!
        </p>
      </header>

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: rem(12),
          marginBottom: rem(12),
        }}
      >
        {unclaimedPartnerTasks.map((task) => (
          <PartnerTask task={task} key={task.id} />
        ))}
      </div>
      {!earnModel.isWalletConnected ? (
        <WalletConnectCard
          css={{
            marginBottom: rem(12),
          }}
        />
      ) : null}
      {claimedPartnerTasks.length !== 0 ? (
        <React.Fragment>
          <CompletedTasksDelimiter
            css={{
              color: theme.colors.onSurfaceTertiary,
              marginTop: rem(12),
              marginBottom: rem(12),
            }}
            nClaimed={claimedPartnerTasks.length}
            nMax={unclaimedPartnerTasks.length + claimedPartnerTasks.length}
          />
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: rem(12),
              marginBottom: rem(12),
            }}
          >
            {claimedPartnerTasks.map((task) => (
              <PartnerTask task={task} key={task.id} />
            ))}
          </div>
        </React.Fragment>
      ) : null}
      <RewardsContent />
    </div>
  );
}
