import {useTranslation} from 'react-i18next';
import {useAppTheme} from '~/components/kit';
import {useRewards} from '~/entities/rewards';
import {rem} from '~/shared/utils/style-helpers';

import {SocialBoostReward} from '../ui/reward-cards';
import {Aidrops} from '../ui/reward-cards/airdrop';
import {StakesReward} from '../ui/reward-cards/stakes-reward';
import {
  isTwitterRewardEnabled,
  TweetReward,
} from '../ui/reward-cards/tweet-reward';

export function RewardsContent() {
  const rewardsModel = useRewards();
  const {t} = useTranslation();
  const theme = useAppTheme();

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: rem(12),
      }}
    >
      <SocialBoostReward></SocialBoostReward>
      {isTwitterRewardEnabled && <TweetReward></TweetReward>}
      {rewardsModel.hasReward('stake') && <StakesReward />}
      <Aidrops />
      {/* <LunarVoucherReward /> */}
      {/* <ClanBonus /> */}
    </div>
  );
}
