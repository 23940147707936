import {useRewards} from '~/entities/rewards';
import {
  RewardCard,
  RewardsCardDescription,
  RewardsCardTitle,
} from '../reward-card';
import {rem} from '~/shared/utils/style-helpers';
import {useAppTheme} from '~/components/kit';
import airdropPng from './assets/airdrop.png';
import {useDockModel} from '~/entities/dock';
import {lunar} from '~/entities/lunar-loot';

export function Aidrops() {
  const theme = useAppTheme();
  const dockModel = useDockModel();

  return (
    <RewardCard isDone={false}>
      <RewardsCardTitle
        css={{
          marginBottom: rem(8),
        }}
      >
        Get among the Best
      </RewardsCardTitle>
      <div
        css={{
          display: 'flex',
          gap: rem(12),
        }}
      >
        <div>
          <RewardsCardDescription>
            Roketo's L2 AirDrop will be distributed to{' '}
            <em
              css={{
                fontWeight: 800,
                color: theme.colors.onSurface,
              }}
            >
              Lunar Program
            </em>{' '}
            participants. Claim with frenz, to get highest rewards.
          </RewardsCardDescription>
        </div>
        <div>
          <div>
            <img
              src={airdropPng}
              css={{
                width: rem(104),
              }}
            ></img>
          </div>
        </div>
      </div>
    </RewardCard>
  );
}
