import {BetIcon} from '~/features/stakes/ui/bet-type-icon';
import {rem} from '~/shared/utils/style-helpers';
import {Icon} from '../icons';
import {useAppTheme} from '../theme';
import React from 'react';

export function ScrollTopButton({
  ...rest
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const theme = useAppTheme();

  return (
    <button
      css={{
        width: rem(76),
        height: rem(76),
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.surface,
      }}
      onClick={() => {
        window.scrollTo(0, 0);
      }}
      {...rest}
    >
      <Icon
        size={30}
        css={{
          transform: 'rotate(180deg)',
        }}
      >
        <BetIcon color="#FA1E91" isRotated={true} />
      </Icon>
    </button>
  );
}
