import {useAppTheme} from '~/components/kit';
import {PageLayout} from '~/components/layouts/page-layout';
import {rem} from '~/shared/utils/style-helpers';
import {Storage} from './ui/storage';
import {MiningDashboard} from './ui/mining-dashboard';
import {UpgradeModal} from './ui/upgrades';
import {useModals} from '~/components/kit/modal';
import {HowItWorks} from '~/components/kit/how-it-works';
import {useMiningPageView} from '~/shared/analytics/events/page-view';
import {ErrorIcon} from '~/components/kit/icons/error-icon';
import {Icon} from '~/components/kit/icons';
import {MicroButton} from '~/components/kit/button/button';
import {useUtils} from '@telegram-apps/sdk-react';
import {usePreloadPages} from '~/app/navigation/pages';
import {FriendsNavigationLink} from '~/features/friends/ui/friends-navigation-link';
import {IS_TESTNET_WARNING_ENABLED} from '~/shared/feature-flags';
import {LeaderboardLinkCard} from '~/entities/leaderboard/ui/leaderboard-link-card';
import {RoutePath} from '~/app/navigation/routes';
import {useDockModel} from '~/entities/dock';
import {Header} from '~/app/header';

const TestnetWarning = () => {
  const theme = useAppTheme();
  const utils = useUtils();

  return (
    <div
      css={{
        backgroundColor: theme.colors.accents.orange.surface,
        color: theme.colors.accents.orange.border,
        padding: rem(20),
        marginLeft: rem(-20),
        marginRight: rem(-20),
        marginTop: rem(-20),
        marginBottom: rem(16),
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Icon
          size={32}
          css={{
            marginRight: rem(12),
          }}
        >
          <ErrorIcon></ErrorIcon>
        </Icon>
        <span>You are in testnet environment</span>
      </div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: rem(10),
        }}
      >
        <MicroButton
          color="yellow"
          onClick={() => {
            utils.openTelegramLink('https://t.me/roketo_lunar_bot');
          }}
        >
          Switch to Mainnet
        </MicroButton>
      </div>
    </div>
  );
};
export function MiningPage() {
  usePreloadPages();

  const dock = useDockModel();

  const modals = useModals();

  useMiningPageView();

  return (
    <PageLayout>
      <UpgradeModal type="dock" name="dock"></UpgradeModal>
      <UpgradeModal type="rocket" name="rocket"></UpgradeModal>
      <UpgradeModal type="staking" name="staking"></UpgradeModal>

      {IS_TESTNET_WARNING_ENABLED && <TestnetWarning />}
      <Header
        css={{
          marginBottom: rem(20),
        }}
      />

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: rem(16),
        }}
      >
        <Storage />

        <MiningDashboard
          onClickUpgrade={(type) => {
            modals.open(type);
          }}
        />
        <LeaderboardLinkCard
          rank={dock.dock.rank}
          topPercent={dock.dock.top_percent}
          to={RoutePath.Leaderboard}
        />
        <HowItWorks
          onClick={() => {
            modals.open('onboarding');
          }}
        />
      </div>
    </PageLayout>
  );
}
