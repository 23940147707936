export const FriendsIcon = () => (
  <svg
    height="41"
    width="42"
    fill="none"
    viewBox="0 0 42 41"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="34.4885" cy="6.97674" fill="#C8BDFF" r="6.97674" />
    <path
      d="M31.4845 27.1341L28.0207 23.3817C28.0207 28.1129 26.0002 30.8865 21.3818 33.773L26.8661 32.907L31.4845 27.1341Z"
      fill="#A89ABA"
    />
    <path
      d="M35.2368 22.5158C35.2368 25.2119 33.4233 27.1342 31.4843 27.1342C29.5454 27.1342 27.7319 25.2119 27.7319 22.5158C27.7319 19.8198 29.5454 17.8975 31.4843 17.8975C33.4233 17.8975 35.2368 19.8198 35.2368 22.5158Z"
      stroke="black"
      strokeWidth="1.73188"
    />
    <path
      d="M34.4885 9.97675V3.97675M31.4885 6.97675H37.4885"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <ellipse
      cx="19.7453"
      cy="21.3957"
      fill="#C8BDFF"
      rx="13.6621"
      ry="13.0236"
      stroke="#C8BDFF"
      strokeWidth="11.5459"
    />
    <ellipse
      cx="19.7453"
      cy="21.3957"
      fill="white"
      rx="13.6621"
      ry="13.0236"
      stroke="black"
      strokeWidth="1.73188"
    />
    <ellipse
      cx="9.84295"
      cy="9.4702"
      fill="black"
      rx="9.84295"
      ry="9.4702"
      transform="matrix(-0.798213 -0.602376 -0.602381 0.798209 28.5881 20.2305)"
    />
    <ellipse
      cx="1.44324"
      cy="2.30917"
      fill="white"
      rx="1.44324"
      ry="2.30917"
      transform="matrix(0.83252 0.553995 -0.554 0.832517 9.79639 14.7223)"
    />
    <ellipse
      cx="8.10374"
      cy="21.3612"
      fill="white"
      rx="0.865944"
      ry="0.865939"
    />
    <ellipse
      cx="31.4844"
      cy="22.5158"
      fill="#F0056A"
      rx="2.88648"
      ry="3.7524"
    />
  </svg>
);
