/*
50% от часовой скорости

75% от часовой скрости

100% от часовой скорости 

150% от часовой скорости

200% от часовой скорости

250% от часовой скорости

300% от часовой скорости
*/
const MULTIPLIERS = [0.5, 0.75, 1, 1.5, 2, 2.5, 3];

export function getDailyRewardsArray(speed: number) {
  return MULTIPLIERS.map((multiplier) => multiplier * speed * 3600);
}
