import {rem} from '~/shared/utils/style-helpers';
import {Box} from '../box';
import {useAppTheme} from '../theme';

export function BwButton({
  children,
  ...rest
}: {
  children: React.ReactNode;
} & React.HTMLProps<HTMLDivElement>) {
  const theme = useAppTheme();

  return (
    <Box
      backgroundColor="white"
      shadowColor="black"
      shadowOffsetX={1}
      shadowOffsetY={1}
      borderWidth={1}
      css={{
        width: rem(30),
        height: rem(30),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        color: theme.colors.onSurface,
      }}
      radius={8}
      {...rest}
    >
      {children}
    </Box>
  );
}
