import {ServerTimestamp} from '~/shared/utils/format/format-time';
import {lunar} from '../lunar-loot';
import {UpgradeName} from '~/api/dock';
import {formatNumber} from '~/shared/utils/format/format-numbers';

export function formatDockSizeMoons(dockMaxBalance: number) {
  return formatNumber(
    lunar(dockMaxBalance, {
      precision: 4,
    }),
  );
}

export function formatDockSize(dockSize: ServerTimestamp) {
  return Number((dockSize / 3600).toFixed(2));
}

export function hourlySpeed(lunarLootSpeed: number) {
  return formatNumber(Number(lunar(lunarLootSpeed * 3600)));
}

export type UpgradeType = 'rocket' | 'dock' | 'staking';

const upgradeTypeToName: Record<UpgradeType, UpgradeName> = {
  rocket: 'lunar_loot_speed',
  dock: 'dock_size',
  staking: 'yield_percentage',
};

export function mapUpgradeTypeToName(type: UpgradeType): UpgradeName {
  return upgradeTypeToName[type];
}

export function getUpgradeLvlPropName(
  upgradeType: UpgradeType,
): 'lunar_loot_speed_lvl' | 'yield_percentage_lvl' | 'dock_size_lvl' {
  if (upgradeType === 'rocket') {
    return 'lunar_loot_speed_lvl';
  }
  if (upgradeType === 'staking') {
    return 'yield_percentage_lvl';
  }

  return 'dock_size_lvl';
}
