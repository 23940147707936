import {Fragment, useRef, useState} from 'react';
import {Icon} from '~/components/kit/icons';
import {CRYSTALS_MENU_MODAL_NAME, CrystalsMenuModal} from './crystals-menu-modal.tsx';
import {useModals} from '~/components/kit/modal/context.tsx';
import {rem} from '~/shared/utils/style-helpers/units.ts';
import {CrystalsMenuIcon} from '../assets/crystals-menu-icon.tsx';

export function CrystalsMenu() {
  const [showModal, setShowModal] = useState(false);
  const modals = useModals()
  const iconRef = useRef<HTMLButtonElement>(null);

  let menuTopPosition = 0;

  const buttonTop = iconRef.current?.getBoundingClientRect().top;
  const buttonHeight = iconRef.current?.getBoundingClientRect().height;
  const buttonLeft = iconRef.current?.getBoundingClientRect().left;
  const distanceBetweenIconAndMenu = 12;

  if (buttonTop && buttonHeight) {
    menuTopPosition = buttonTop + buttonHeight + distanceBetweenIconAndMenu;
  }

  const openModal = () => {
    setShowModal(true);
    modals.open(CRYSTALS_MENU_MODAL_NAME, {replace: true});
  };

  const closeModal = () => {
    setShowModal(false);
    modals.closeExact(CRYSTALS_MENU_MODAL_NAME);
  };

  return (
    <Fragment>
      <button
        css={{
          display: 'flex',
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onClick={openModal}
        ref={iconRef}
      >
        <Icon size={40}>
          <CrystalsMenuIcon />
        </Icon>
      </button>

      {showModal && (
        <CrystalsMenuModal top={menuTopPosition} afterClose={closeModal} floatingMenuIcon={
          <button
            css={{
              position: 'fixed',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 2,
              marginTop: rem(2),
              left: buttonLeft,
              top: buttonTop,
            }}
            onClick={closeModal}
          >
            <Icon size={40}>
              <CrystalsMenuIcon />
            </Icon>
          </button>
        } />
      )}
    </Fragment>
  );
}
