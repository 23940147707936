import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {Card, useAppTheme} from '~/components/kit';
import {Star, StarField} from '~/components/kit/star';
import {DockBalance} from '~/features/idle-mining';
import {rem} from '~/shared/utils/style-helpers';
import {DockProgressAndButton} from './dock-progress';
import {Upgrade} from '../upgrades';
import {UpgradeType} from '~/entities/upgrades';
import {useDockModel} from '~/entities/dock';
import {BoostVariants, MiningBoosts} from '~/features/idle-mining/ui/boosts';

const Divider = (props: React.HTMLProps<HTMLDivElement>) => {
  const theme = useAppTheme();

  return (
    <div
      css={{
        width: '100%',
        height: '1px',
        borderColor: theme.colors.onSurfaceTertiary,
        // dashed border
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' strokeWidth='4' stroke-dasharray='2%2c 8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
      }}
      {...props}
    ></div>
  );
};

export function MiningDashboard({
  onClickUpgrade,
}: {
  onClickUpgrade: (type: UpgradeType) => void;
}) {
  const theme = useAppTheme();
  const {t} = useTranslation();
  const dockQuery = useDockModel();

  const dockData = dockQuery.dock;

  return (
    <StarField
      stars={
        <React.Fragment>
          <Star
            starSize={1.2}
            x={0}
            y={0.17}
            rayColor="rgba(0, 0, 0, 1)"
            coreColor="rgba(255, 184, 239, 1)"
            rotation={40}
          ></Star>
          <Star
            starSize={1.2}
            x={0.95}
            y={0.12}
            rayColor="rgba(0, 0, 0, 1)"
            coreColor="#FFE76A"
            rotation={40}
          ></Star>
          <Star
            starSize={1.2}
            x={0.98}
            y={0.5}
            rayColor="rgba(0, 0, 0, 1)"
            coreColor="#C2B8FF"
            rotation={40}
          ></Star>
        </React.Fragment>
      }
    >
      <Card
        rounding="medium"
        css={{
          padding: `${rem(30)} ${rem(24)}`,
        }}
      >
        <div css={{textAlign: 'center'}}>
          <h3
            css={{
              fontSize: theme.fontSize.s3,
              fontWeight: 500,
            }}
          >
            <Trans
              t={t}
              i18nKey={'mining.dockBalance'}
              components={{
                bold: (
                  <span
                    css={{
                      fontWeight: 700,
                    }}
                  ></span>
                ),
              }}
            ></Trans>
          </h3>

          <DockBalance
            css={{
              fontSize: rem(46),
              whiteSpace: 'nowrap',
            }}
          />
        </div>
        <Divider
          css={{
            margin: `${rem(16)} 0`,
          }}
        />

        <DockProgressAndButton />
        <Divider
          css={{
            margin: `${rem(16)} 0`,
          }}
        />

        {dockData.has_boost && (
          <React.Fragment>
            <MiningBoosts dock={dockData} boostVariant={BoostVariants.JetpackBooster} />
            <Divider
              css={{
                margin: `${rem(16)} 0`,
              }}
            />
          </React.Fragment>
        )}
        {dockData.has_early_adopters_boost && (
          <React.Fragment>
            <MiningBoosts dock={dockData} boostVariant={BoostVariants.EarlyAdoptersBooster} />
            <Divider
              css={{
                margin: `${rem(16)} 0`,
              }}
            />
          </React.Fragment>
        )}
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: rem(28),
          }}
        >
          <Upgrade
            type="rocket"
            lvl={dockData.lunar_loot_speed_lvl}
            onClickUpgrade={onClickUpgrade}
          />
          <Upgrade
            type="dock"
            lvl={dockData.dock_size_lvl}
            onClickUpgrade={onClickUpgrade}
          />
          <Upgrade
            type="staking"
            lvl={dockData.yield_percentage_lvl}
            onClickUpgrade={onClickUpgrade}
          />
        </div>
      </Card>
    </StarField>
  );
}
