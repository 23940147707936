import React, {useMemo} from 'react';
import {keyframes} from '@emotion/react';
import {volumetricBoxShadow} from '~/components/kit/styles';
import {rem} from '~/shared/utils/style-helpers';
import {useAppTheme} from '~/components/kit';
import {Rocket} from './rocket';

interface ProgressBarProps {
  percentage: number;
}
/*
  Renders a progress bar element with striped background of color under 45%.
  Every stripe is animated to be constantly moving to the right.
*/

// create sharp linear-gradient css property from given colors.
function generateSharpGradient(colors: string[]) {
  const colorWidth = 100 / colors.length;

  return colors
    .map((color, index) => {
      return `${color} ${index * colorWidth}%, ${color} ${
        index * colorWidth + colorWidth
      }%`;
    })
    .join(', ');
}

// 52.9 - is chosen by hand, so that transform repeat is not visible
// TODO: find a way to calculate this value
const progressBarStripedKeyframes = keyframes`
  to  { transform: translateX(-52.9%) }
  from    { transform: translateX(0%) }
`;

// TODO: progress bar flickers still
export const DockProgressBar: React.FC<ProgressBarProps> = ({percentage}) => {
  const theme = useAppTheme();

  const width = 300;

  const colors = theme.colors.components.progressBar.progress;

  const quadraColors = useMemo(() => {
    return [...colors, ...colors, ...colors, ...colors];
  }, colors);

  return (
    <div
      css={[
        volumetricBoxShadow(theme, {shadowColor: theme.colors.onSurface}),
        {
          width: '100%',
          height: rem(53),
          backgroundColor: theme.colors.components.progressBar.background,
          border: `2px solid ${theme.colors.surfaceAlternativeCard}`,
          position: 'relative',
          borderRadius: 9999999,
          transform: `translate3d(0, 0, 0)`,
          overflow: percentage > 50 ? 'visible' : 'hidden',
        },
      ]}
    >
      <Rocket
        css={{
          height: rem(40),
          width: rem(90),
          position: 'absolute',
          right: `${100 - percentage}%`,
          top: '50%',
          transform: 'translateX(25%) translateY(-50%)',
          zIndex: 1,
        }}
      />
      <div
        css={{
          height: '100%',
          width: `${percentage}%`,
          position: 'relative',
          overflow: 'hidden',
          transform: `translate3d(0, 0, 0)`,
          zIndex: 0,
          borderRadius: 9999999,
        }}
      >
        <div
          css={{
            position: 'absolute',
            width: `${100 / (percentage / 100)}%`,
            height: '100%',
          }}
        >
          <div
            css={{
              position: 'absolute',
              height: '100%',
              width: `${width}%`,
              backgroundImage: `linear-gradient(45deg, ${generateSharpGradient(
                quadraColors,
              )})`,
              animation: `${progressBarStripedKeyframes} 20s linear infinite`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};
