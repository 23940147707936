export const CheckIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.02344"
        y="0.777832"
        width="16.7882"
        height="16.7882"
        rx="8.39412"
        fill="#5DCB90"
      />
      <rect
        x="1.02344"
        y="0.777832"
        width="16.7882"
        height="16.7882"
        rx="8.39412"
        stroke="black"
      />
      <g filter="url(#filter0_d_160_15031)">
        <path
          d="M12.333 7.08267L8.79394 10.6217L6.59412 8.42186"
          stroke="black"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_160_15031"
          x="5.44019"
          y="6.55234"
          width="7.42309"
          height="5.75365"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.623648" dy="0.623648" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_160_15031"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_160_15031"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
