export const DECIMALS = 8;
const DECIMALS_MP = 10 ** DECIMALS;

export const lunarToFloat = (value: string | number) => {
  return Number(value) / DECIMALS_MP;
};

export const floatToLunar = (value: string | number) => {
  return Math.round(Number(value) * DECIMALS_MP);
};

export const lunar = (
  value: string | number,
  {
    isSteady = false,
    precision,
  }:
    | {
        isSteady?: boolean;
        precision?: number;
      }
    | undefined = {},
): string => {
  let v;
  if (isSteady) {
    v = (Number(value) / DECIMALS_MP).toString();
  } else {
    v = Number(Number(value) / DECIMALS_MP).toString();
  }

  if (typeof precision !== 'undefined') {
    v = Number(v).toFixed(precision);
  }
  return v;
};

// Find such a precision that change per second is bigger than 1
export function findOptimalSpeedPrecision(speed: number) {
  return Math.min(
    Math.max(1, Math.ceil(DECIMALS - Math.log10(speed))),
    DECIMALS - 2,
  );
}
