import {useState, useEffect} from 'react';
import './animation.css';

type Image = {
  id: number;
  size: number;
  speed: number;
  x: number;
  delay: number;
  opacity: number;
  reset: boolean;
};

type FallingImagesProps = {
  imageSrc: string;
  visibleImages: number;
};

function generateImageParams() {
  const size = Math.random() * 10 + 10;
  const opacity = Math.min(size / 17, 1);

  return {
    size: size,
    x: Math.random() * 100,
    opacity: opacity,
  };
}

export const FallingImages: React.FC<FallingImagesProps> = ({
  imageSrc,
  visibleImages,
}) => {
  const [images, setImages] = useState<Image[]>([]);

  useEffect(() => {
    const initialImages: Image[] = Array.from({length: visibleImages}, () => ({
      id: Math.random(),
      reset: true,
      speed: Math.random() * 1 + 1,
      delay: Math.random() * 1000,
      ...generateImageParams(),
    }));

    setImages(initialImages);
  }, [visibleImages]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setImages((prevImages) =>
  //       prevImages.map((image) =>
  //         image.reset
  //           ? {
  //               ...image,
  //               reset: false,

  //               ...generateImageParams(),
  //             }
  //           : image,
  //       ),
  //     );
  //   }, 500);

  //   return () => clearInterval(interval);
  // }, []);

  const handleAnimationEnd = (id: number) => {
    setImages((prevImages) =>
      prevImages.map((image) =>
        image.id === id
          ? {
              ...image,
              ...generateImageParams(),
              reset: false,
            }
          : image,
      ),
    );
  };

  return (
    <div className="falling-container">
      {images.map((image) => (
        <img
          key={image.id}
          src={imageSrc}
          style={{
            width: `${image.size}px`,
            animationDuration: `${image.speed}s`,
            animationDelay: `${image.delay}ms`,
            opacity: image.opacity,
            left: `${image.x}%`,
          }}
          className="falling-image"
          onAnimationEnd={(e) => {}}
          onAnimationIteration={(e) => {
            handleAnimationEnd(image.id);
          }}
          alt="falling object"
        />
      ))}
    </div>
  );
};
