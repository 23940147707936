import {EnvironmentVariables} from '~/app/env';

export const LEADERBOARD_RELEASE_DATE = new Date('2024-06-13T14:30:00+01:00');

const IS_LEADERBOARD_RELEASED = () => {
  return LEADERBOARD_RELEASE_DATE < new Date();
};

export const isTestingEnvironment = () => {
  return ['dev', 'local-dev'].includes(EnvironmentVariables.APP_ENV);
};

const isStaging = () => {
  return EnvironmentVariables.APP_ENV === 'staging';
};

export const IS_LEADERBOARD_ENABLED = IS_LEADERBOARD_RELEASED();
export const IS_TESTNET_WARNING_ENABLED = isTestingEnvironment();

const ONE_WEEK = 7 * 24 * 60 * 60 * 1000;
const ONE_HOUR = 60 * 60 * 1000;
export const AIRDROP_START_DATE =
  isTestingEnvironment() || isStaging()
    ? new Date(new Date(1720483200000).getTime())
    : new Date(new Date(1720483200000).getTime());

export const AIRDROP_DISTRIBUTION_DATE = new Date(
  AIRDROP_START_DATE.getTime() + ONE_WEEK * 3,
);

export const IS_AIRDROP_STARTED = () => new Date() >= AIRDROP_START_DATE;

export const IS_EARN_PAGE_ENABLED = () => {
  return IS_AIRDROP_STARTED() || isTestingEnvironment() || isStaging();
};

export const IS_FIRST_SEASON_AIRDROP_ENDED = () =>
  new Date() >= AIRDROP_DISTRIBUTION_DATE;

export const IS_ERUDA_DEBUG_ENABLED = isTestingEnvironment();
