import {Squircle} from '@squircle-js/react';
import React from 'react';
import useResizeObserver from 'use-resize-observer';
import {rem} from '~/shared/utils/style-helpers';

export interface ISquircleBox extends React.HTMLProps<HTMLDivElement> {
  backgroundColor: string;
  shadowColor?: string;
  borderWidth?: number;
  shadowOffsetX?: number;
  shadowOffsetY?: number;
  radius?: number;

  bgImage?: React.ReactNode;
}

function _Box(
  {
    backgroundColor,
    shadowColor,
    borderWidth = 0,
    shadowOffsetX = 0,
    shadowOffsetY = 0,
    radius = 30,
    bgImage,
    children,
    ...rest
  }: ISquircleBox,
  outerRef?: React.ForwardedRef<HTMLDivElement>,
) {
  const {
    ref,
    width = 1,
    height = 1,
  } = useResizeObserver<HTMLDivElement>({
    box: 'content-box',
  });

  return (
    <div
      css={{
        position: 'relative',
        zIndex: 1,
      }}
      {...rest}
    >
      {/* Div to measure size using content-box. Because border-box does not work in safari */}
      <div
        ref={(el) => {
          if (outerRef && typeof outerRef === 'function') {
            outerRef(el);
          } else if (outerRef && typeof outerRef === 'object') {
            outerRef.current = el;
          }

          ref(el);
        }}
        css={{
          position: 'absolute',
          zIndex: -1,
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent',
        }}
      ></div>
      {borderWidth !== 0 && (
        <Squircle
          cornerRadius={radius + borderWidth}
          cornerSmoothing={1}
          width={width + 2 * borderWidth + shadowOffsetX}
          height={height + 2 * borderWidth + shadowOffsetY}
          css={{
            zIndex: -1,
            left: rem(-borderWidth),
            top: rem(-borderWidth),
            right: rem(-borderWidth - shadowOffsetX),
            bottom: rem(-borderWidth - shadowOffsetY),
            position: 'absolute',
            backgroundColor: shadowColor,
          }}
        ></Squircle>
      )}
      <Squircle
        cornerRadius={radius}
        cornerSmoothing={1}
        width={width}
        height={height}
        css={{
          zIndex: -1,
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          position: 'absolute',
          background: backgroundColor,
        }}
      >
        {bgImage}
      </Squircle>
      {children}
    </div>
  );
}

export const Box = React.forwardRef<HTMLDivElement, ISquircleBox>(_Box);
