import {useTranslation} from 'react-i18next';
import {
  RewardCard,
  RewardsCardDescription,
  RewardsCardTitle,
} from './reward-card';
import {rem} from '~/shared/utils/style-helpers';
import {Button} from '~/components/kit/button/button';
import {Reward} from '../reward/reward';
import xLogo from '~/entities/social-media/assets/x-logo.svg';
import {Star, StarField} from '~/components/kit/star';
import {useRewards} from '~/entities/rewards';
import {useUtils} from '@telegram-apps/sdk-react';
import {useCurrentUser} from '~/entities/user';
import {EnvironmentVariables} from '~/app/env';
import React from 'react';
import {generateTweetLink} from '~/features/twitter-post/twitter-post';

interface IRewardCardProps {}

export const isTwitterRewardEnabled =
  !!EnvironmentVariables.REWARD_TWITTER_POST_ID;

export function TweetReward({}: IRewardCardProps) {
  const utils = useUtils();
  const user = useCurrentUser();

  const rewards = useRewards();
  const {t} = useTranslation();

  const reward = rewards.model.getRewardByName('twitter-post');
  const userReward = rewards.model.userReward('twitter-post');

  const isReady = rewards.isReady;

  let isClaimed = rewards.model.isClaimed('twitter-post') && isReady;

  return (
    <StarField
      stars={
        <React.Fragment>
          <Star
            rotation={30}
            x={0.15}
            y={0.15}
            starSize={1}
            rayColor="#111320"
            coreColor="#FF8469"
          />
          <Star
            rotation={30}
            x={0}
            y={0.5}
            starSize={1}
            rayColor="#000000"
            coreColor="#FFE76A"
          />
          <Star
            rotation={30}
            x={0.95}
            y={0.45}
            starSize={1}
            rayColor="#000000"
            coreColor="#C2B8FF"
          />
        </React.Fragment>
      }
    >
      <RewardCard
        css={{
          textAlign: 'center',
          position: 'relative',
        }}
        isDone={isClaimed}
      >
        <RewardsCardTitle
          css={{
            marginBottom: rem(8),
          }}
        >
          {t('rewardsPage.tweetTitle')}
        </RewardsCardTitle>
        <RewardsCardDescription
          css={{
            marginBottom: rem(18),
          }}
        >
          {t('rewardsPage.tweetDescription')}
        </RewardsCardDescription>

        <div
          css={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            minHeight: isClaimed ? 'auto' : rem(50),
          }}
        >
          {isClaimed && reward ? (
            <Reward value={reward.amount} isAlternativeColor={true} />
          ) : reward ? (
            <Button
              color="pink"
              css={{
                width: '100%',
              }}
              onClick={() => {
                utils.openLink(generateTweetLink(user.referral_code));

                // do not send a request if twitter reward is already in progress
                if (userReward) {
                  return;
                }

                if (!rewards.claimRewardsMutation.isPending) {
                  rewards.claimRewardsMutation.mutate({
                    reward_id: reward.id,
                  });
                }
              }}
            >
              {t('rewardsPage.tweetButton')}
              <img
                src={xLogo}
                alt="x"
                css={{
                  width: rem(15),
                  height: rem(15),
                  marginLeft: rem(6),
                  marginRight: rem(16),
                }}
              />
              <Reward value={reward.amount} isAlternativeColor={true} />
            </Button>
          ) : (
            <Button
              color="pink"
              isLoading={true}
              css={{
                width: '100%',
              }}
            >
              Downloading matrix...
            </Button>
          )}
        </div>
      </RewardCard>
    </StarField>
  );
}
