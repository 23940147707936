import {ThemeProvider, useTheme as useEmotionTheme} from '@emotion/react';

type LightTheme = {
  colors: {
    background: string;
    surface: string;
    surfaceSecondary: string;
    surfaceSecondaryBorder: string;
    surfaceAlternative: string;
    surfaceAlternativeCard: string;
    onSurface: string;
    onSurfaceDivider: string;
    onSurfaceSecondary: string;
    onSurfaceTertiary: string;
    onSurfaceAlternative: string;
    onSurfaceAlternativeSecondary: string;
    onSurfaceCritical: string;
    shades: {
      dark: {
        surface: string;
        onSurface: string;
      };
    };

    success: {
      surface: string;
      onSurface: string;
      border: string;
    };
    critical: {
      surface: string;
      onSurface: string;
      border: string;
    };
    accents: {
      white: {
        surface: string;
        onSurface: string;
        border: string;
        background: string;
      };
      pink: {
        surface: string;
        onSurface: string;
        border: string;
        background: string;
      };
      yellow: {
        surface: string;
        onSurface: string;
        onSurfaceSecondary: string;
        border: string;
      };
      purple: {
        surface: string;
        onSurface: string;
        border: string;
        background: string;
      };
      blue: {
        surface: string;
        onSurface: string;
        border: string;
        background: string;
      };
      orange: {
        surface: string;
        onSurface: string;
        background: string;
        border: string;
      };
      red: {
        surface: string;
        onSurface: string;
        border: string;
        background: string;
      };
    };
    components: {
      progressBar: {
        progress: [
          '#857BFF',
          '#D06BFF',
          '#FCA21B',
          '#50ABFF',
          '#FF7ABA',
          '#5CD084',
          '#FF6F6F',
        ];
        background: '#59596E';
      };
      upgrade: {
        rocket: {
          background: string;
          border: string;
          button: string;
          assetBg: string;
          assetBgAdjacent: string;
        };
        dock: {
          background: string;
          border: string;
          button: string;
          assetBg: string;
          assetBgAdjacent: string;
        };
        staking: {
          background: string;
          border: string;
          button: string;
          assetBg: string;
          assetBgAdjacent: string;
        };
      };
    };
  };
  size: {};
  fontSize: {
    odds: {
      s10: 10;
      s15: 15;
      s13: 13;
      s11: 11;
    };
    // s7: 12;
    s7: 12;
    // s6: 14;
    s6: 14;
    s5: 16;
    s4: 18;
    s3: 22;
    // s2: 24;
    s2: 24;
    // s1: 28;
    s1: 28;
  };
};

const theme: LightTheme = {
  colors: {
    background: '#F0EFFC',
    surface: '#ffffff',
    surfaceSecondary: '#F4F3FF',
    surfaceSecondaryBorder: '#E0DFF2',
    surfaceAlternativeCard: '#111320',

    onSurface: '#08061F',
    onSurfaceCritical: '#FF6F6F',
    onSurfaceDivider: '#F4F3FC',
    onSurfaceTertiary: '#7E7E7E',
    onSurfaceSecondary: '#7C7A8E',
    surfaceAlternative: '#000000',
    onSurfaceAlternative: '#ffffff',
    onSurfaceAlternativeSecondary: 'rgba(255, 255, 255, 0.8)',

    shades: {
      dark: {
        surface: '#0F0720',
        onSurface: '#FFFFFF',
      },
    },
    success: {
      surface: '#E5FFF1',
      onSurface: '#00B754',
      border: '#E5FFF1',
    },
    critical: {
      surface: '#F3104B',
      onSurface: '#FFE5EF',
      border: '#FFE5EF',
    },
    components: {
      progressBar: {
        progress: [
          '#857BFF',
          '#D06BFF',
          '#FCA21B',
          '#50ABFF',
          '#FF7ABA',
          '#5CD084',
          '#FF6F6F',
        ],
        background: '#59596E',
      },
      upgrade: {
        rocket: {
          background: '#EEEDFF',
          border: '#D8D5FF',
          button: 'D8D5FF',
          assetBg: '#A29BFF',
          assetBgAdjacent: '#FF6F6F',
        },
        dock: {
          background: '#FFF8ED',
          border: '#FCA21B',
          button: '#FFEED5',
          assetBg: '#FFD79B',
          assetBgAdjacent: '#FF6F6F',
        },
        staking: {
          background: '#EDFAFF',
          border: '#FCA21B',
          button: '#FFEED5',
          assetBg: '#9BE1FF',
          assetBgAdjacent: '#FF6F6F',
        },
      },
    },
    accents: {
      white: {
        surface: '#FFFFFF',
        background: '#FFFFFF',
        border: '#08061F',
        onSurface: '#08061F',
      },
      pink: {
        surface: '#FFA0C2',
        background: '#FFA0C2',
        border: '#000000',
        onSurface: '#000000',
      },
      yellow: {
        surface: '#FDF278',
        onSurface: '#000000',
        onSurfaceSecondary: 'rgba(0, 0, 0, 0.8)',
        border: '#000000',
      },
      purple: {
        surface: '#D8D5FF',
        onSurface: '#000000',
        border: '#C3BFFF',
        background: '#EEEDFF',
      },
      orange: {
        surface: '#FFEED5',
        onSurface: '#000000',
        background: '#FFEED5',
        border: '#FCA21B',
      },
      red: {
        surface: '#F63C70',
        onSurface: '#FFFFFF',
        border: '#000000',
        background: '',
      },
      blue: {
        surface: '#D5F3FF',
        onSurface: '#000000',
        border: '#9BE1FF',
        background: '#EDFAFF',
      },
    },
  },
  size: {},
  fontSize: {
    odds: {
      s10: 10,
      s15: 15,
      s13: 13,
      s11: 11,
    },
    s7: 12,
    s6: 14,
    s5: 16,
    s4: 18,
    s3: 22,
    s2: 24,
    s1: 28,
  },
};

export type AppTheme = LightTheme;

export function useAppTheme(): AppTheme {
  const theme = useEmotionTheme();

  return theme as AppTheme;
}

export function AppThemeProvider({children}: {children: React.ReactNode}) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
