import {PageLayout} from '~/components/layouts/page-layout';
import sittingAstronautPng from '~/components/kit/full-screen-error/assets/sitting-astronaut.png';
import {ImgTransition} from '~/components/kit/img-transition';
import {rem} from '~/shared/utils/style-helpers';
import {Card, useAppTheme} from '~/components/kit';
import {SocialMediaLink} from '~/entities/social-media';
import {Typography} from '~/components/kit/typography';

export function TechnicalMaintenance() {
  const theme = useAppTheme();

  return (
    <PageLayout>
      <Card
        rounding="medium"
        css={{
          textAlign: 'center',
          paddingBottom: rem(40),
          paddingTop: rem(40),
        }}
      >
        <div
          css={{
            maxWidth: rem(250),
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Typography.h3>Technical Maintenance in Outer Space...</Typography.h3>
          <p
            css={{
              marginTop: rem(12),
              marginBottom: rem(20),
              fontSize: theme.fontSize.s6,
              color: theme.colors.onSurfaceTertiary,
            }}
          >
            Drink a cup of tea. <br /> Or maybe a few...
          </p>
        </div>

        <ImgTransition
          src={sittingAstronautPng}
          alt="sitting-astronaut"
          css={{
            height: rem(160),
          }}
        />
        <Typography.h6
          css={{
            marginBottom: rem(12),
            marginTop: rem(40),
          }}
        >
          Join our social media while you wait:
        </Typography.h6>
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridGap: rem(10),
            marginLeft: 'auto',
            marginRight: 'auto',
            justifyContent: 'center',
            width: rem(150),
          }}
        >
          <SocialMediaLink social="telegram" />
          <SocialMediaLink social="twitter" />
        </div>
      </Card>
    </PageLayout>
  );
}
