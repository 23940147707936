import React, {useEffect} from 'react';
import {
  Modal,
  ModalSteps,
  ModalStepsControlButton,
  useModalSteps,
  useModals,
} from '~/components/kit/modal';
import {rem} from '~/shared/utils/style-helpers';
import {ScrollableContent} from '~/features/ui/scrollable-content';
import {
  Step1,
  bgImage as Step1BgImage,
  backgroundColor as step1BackgroundColor,
} from './step1';
import {
  Step2,
  bgImage as Step2BgImage,
  backgroundColor as step2BackgroundColor,
} from './step2';
import {
  Step3,
  bgImage as Step3BgImage,
  backgroundColor as step3BackgroundColor,
} from './step3';
import {
  Step4,
  bgImage as Step4BgImage,
  backgroundColor as step4BackgroundColor,
} from './step4';

export const TRAILBLAZER_HOWTO_MODAL_NAME = 'trailblazer-howto';

export function TrailblazerHowToModal({afterClose}: {afterClose?: () => void}) {
  const modals = useModals();
  const isOpen = modals.isOpen(TRAILBLAZER_HOWTO_MODAL_NAME);

  const steps = useModalSteps({
    steps: [Step1, Step2, Step3, Step4],
  });

  const stepsMeta: Record<number, any> = {
    0: {
      backgroundColor: step1BackgroundColor,
      bgImage: <Step1BgImage />,
    },
    1: {
      backgroundColor: step2BackgroundColor,
      bgImage: <Step2BgImage />,
    },
    2: {
      backgroundColor: step3BackgroundColor,
      bgImage: <Step3BgImage />,
    },
    3: {
      backgroundColor: step4BackgroundColor,
      bgImage: <Step4BgImage />,
    },
  };

  useEffect(() => {
    if (isOpen) {
      steps.setStep(0);
    }
  }, [isOpen]);

  return (
    <Modal
      isClosable={false}
      name={TRAILBLAZER_HOWTO_MODAL_NAME}
      afterClose={afterClose}
      controlButton={
        <ModalStepsControlButton
          steps={steps}
          afterLastStep={() => {
            modals.close();
          }}
        />
      }
      backgroundColor={stepsMeta[steps.step].backgroundColor}
      bgImage={stepsMeta[steps.step].bgImage}
    >
      <div
        css={{
          overflow: 'hidden',
        }}
        {...steps.bindDrag()}
      >
        <ScrollableContent
          css={{
            height: rem(430),
            userSelect: 'none',
          }}
        >
          <steps.Step />
        </ScrollableContent>
        <ModalSteps
          isAlternative={true}
          steps={steps.length}
          activeStep={steps.step}
        />
      </div>
    </Modal>
  );
}
