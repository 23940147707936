export const BellIcon = () => (
  <svg
    height="60"
    width="60"
    fill="none"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.6843 41.0278C37.4705 43.9619 35.7293 46.9777 32.7953 47.7639C29.8612 48.5501 26.8453 46.8089 26.0592 43.8748M11.771 29.2918C11.057 26.7285 11.7995 23.9571 13.6996 22.0943M30.2684 18.5256C30.8261 17.5222 31.0098 16.3095 30.6893 15.1131C30.0341 12.668 27.5209 11.217 25.0758 11.8722C22.6308 12.5273 21.1798 15.0405 21.8349 17.4856C22.1555 18.682 22.921 19.6404 23.9057 20.2305M45.2517 20.3207C44.5884 17.7438 42.5597 15.715 39.9827 15.0518M40.0663 25.3171C39.4119 22.8748 37.6649 20.8326 35.2096 19.6396C32.7542 18.4466 29.7917 18.2005 26.9737 18.9556C24.1558 19.7107 21.7132 21.405 20.1833 23.6658C18.6534 25.9267 18.1616 28.5689 18.816 31.0111C19.8987 35.0519 19.6884 38.2755 19.0047 40.7252C18.2254 43.5171 17.8358 44.913 17.941 45.1933C18.0615 45.514 18.1486 45.602 18.468 45.7258C18.7472 45.834 19.9201 45.5197 22.2659 44.8911L44.0186 39.0625C46.3644 38.434 47.5373 38.1197 47.725 37.8864C47.9398 37.6195 47.9712 37.4997 47.9151 37.1618C47.8662 36.8664 46.8307 35.8523 44.7599 33.8241C42.943 32.0445 41.149 29.3578 40.0663 25.3171Z"
      stroke="#FF5481"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="23"
    />
    <path
      d="M36.6843 41.0278C37.4705 43.9619 35.7293 46.9777 32.7953 47.7639C29.8612 48.5501 26.8453 46.8089 26.0592 43.8748M11.771 29.2918C11.057 26.7285 11.7995 23.9571 13.6996 22.0943M30.2684 18.5256C30.8261 17.5222 31.0098 16.3095 30.6893 15.1131C30.0341 12.668 27.5209 11.217 25.0758 11.8722C22.6308 12.5273 21.1798 15.0405 21.8349 17.4856C22.1555 18.682 22.921 19.6404 23.9057 20.2305M45.2517 20.3207C44.5884 17.7438 42.5597 15.715 39.9827 15.0518M40.0663 25.3171C39.4119 22.8748 37.6649 20.8326 35.2096 19.6396C32.7542 18.4466 29.7917 18.2005 26.9737 18.9556C24.1558 19.7107 21.7132 21.405 20.1833 23.6658C18.6534 25.9267 18.1616 28.5689 18.816 31.0111C19.8987 35.0519 19.6884 38.2755 19.0047 40.7252C18.2254 43.5171 17.8358 44.913 17.941 45.1933C18.0615 45.514 18.1486 45.602 18.468 45.7258C18.7472 45.834 19.9201 45.5197 22.2659 44.8911L44.0186 39.0625C46.3644 38.434 47.5373 38.1197 47.725 37.8864C47.9398 37.6195 47.9712 37.4997 47.9151 37.1618C47.8662 36.8664 46.8307 35.8523 44.7599 33.8241C42.943 32.0445 41.149 29.3578 40.0663 25.3171Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3.66667"
    />
  </svg>
);
