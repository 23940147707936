import React from 'react';
import {useAppTheme} from '~/components/kit/theme';
import {ModalTitle} from '~/components/kit/modal';
import {ImgTransition} from '~/components/kit/img-transition';
import {AspectRatio} from '~/components/kit/aspect-ratio';
import {Markdown} from '~/components/kit/markdown';
import howToBanner1 from '../assets/howto-banner-1.png';

export const Step1 = () => {
  const theme = useAppTheme();

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <ModalTitle
        css={{
          color: theme.colors.onSurfaceAlternative,
          paddingLeft: 0,
          paddingRight: 0,
          textShadow: `0px 0px 10px #0C0748`,
        }}
      >
        Welcome to Galactic&nbsp;Trailblazer!
      </ModalTitle>
      <div css={{flexGrow: 1}} />
      <Markdown
        css={{
          color: theme.colors.onSurfaceAlternative,
          fontSize: theme.fontSize.s5,
        }}
      >
        Astronaut, your cosmic mission: crack the daily planetary code. Arrange
        the planets in what you think is the secret sequence and see how many
        you've guessed correctly. We won't tell you which ones though - that's
        part of the cosmic mystery! Each correct planet earns you points and
        precious Lunar Loot.
      </Markdown>
    </div>
  );
};

export const backgroundColor = '#29346D';

export const bgImage = () => (
  <AspectRatio ratio={704 / 670}>
    <ImgTransition
      src={howToBanner1}
      css={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'bottom center',
      }}
    />
  </AspectRatio>
);

