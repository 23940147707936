export const RocketIcon = () => (
  <svg
    height="12"
    width="11"
    fill="none"
    viewBox="0 0 11 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1372_35227)">
      <path
        d="M5.50004 7.37495L4.12504 5.99995M5.50004 7.37495C6.14026 7.13146 6.75445 6.82437 7.33337 6.45828M5.50004 7.37495V9.66662C5.50004 9.66662 6.88879 9.41453 7.33337 8.74995C7.82837 8.00745 7.33337 6.45828 7.33337 6.45828M4.12504 5.99995C4.36894 5.36719 4.67605 4.76065 5.04171 4.18953C5.57575 3.33565 6.31937 2.6326 7.20183 2.14724C8.0843 1.66189 9.07626 1.41037 10.0834 1.41662C10.0834 2.66328 9.72587 4.85412 7.33337 6.45828M4.12504 5.99995H1.83337C1.83337 5.99995 2.08546 4.6112 2.75004 4.16662C3.49254 3.67162 5.04171 4.16662 5.04171 4.16662M2.06254 8.06245C1.37504 8.63995 1.14587 10.3541 1.14587 10.3541C1.14587 10.3541 2.86004 10.125 3.43754 9.43745C3.76296 9.05245 3.75837 8.4612 3.39629 8.1037C3.21814 7.93366 2.98347 7.83541 2.73731 7.8278C2.49116 7.82018 2.25086 7.90375 2.06254 8.06245Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.916667"
      />
    </g>
    <defs>
      <clipPath id="clip0_1372_35227">
        <rect
          height="11"
          width="11"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
