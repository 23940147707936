import {useState} from 'react';
import useResizeObserver from 'use-resize-observer';

const BASE_WIDTH = 104;

export function AestheticsBg({}) {
  const [size, setSize] = useState({width: 0, height: 0});

  const {ref} = useResizeObserver({
    onResize: (size) => {
      if (size.width !== 0 && size.height !== 0) {
        setSize({
          width: size.width || 0,
          height: size.height || 0,
        });
      }
    },
  });

  const scaled = (value: number) => (value / BASE_WIDTH) * size.width;

  const sc = scaled;

  return (
    <div
      ref={ref}
      css={{
        position: 'absolute',
        left: '-5%',
        top: 0,
        width: '110%',
        height: '110%',
        zIndex: -1,
      }}
    >
      <svg
        width={size.width || 0}
        height="25"
        viewBox={`0 0 ${size.width} 25`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Vector 62"
          d={`M${sc(8)} 16
          C${sc(9)} 14 ${sc(24)} 7 ${sc(34)} 10
          C${sc(43)} 13 ${sc(50)} 15 ${sc(64)} 14
          C${sc(77)} 13 ${sc(75)} 5 ${sc(94)} 12`}
          stroke="#FFD1F5"
          strokeWidth="18"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
}
