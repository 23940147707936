import {useEffect, useRef} from 'react';
import {client as mixpanelClient} from './mixpanel';
import {User} from '~/api/user';
import {fullName} from '~/entities/referral-link/utilts';
import {EnvironmentVariables} from '~/app/env';
import {shouldSendAnalyticsEvents} from './model';
import {logger} from '../debug';
import {isAnalyticsWhitelisted} from './whitelist';

export interface UserProps {
  referrer: string;
  balance: number;
  lunarLootSpeedLvl: number;
  dockSizeLvl: number;
  stakingLvl: number;
  socialBoosts: string[];
  referralsCount: number;
  isPremium: boolean;
  blackHoleSize: number;
}

export function isAnalyticsEnabledForUser(user: User) {
  const isEnabled =
    user.telegram_id % 3 === 0 ||
    isAnalyticsWhitelisted(String(user.telegram_id));

  logger.debug(
    '[Analytics]: Is enabled for user with id',
    user.telegram_id,
    isEnabled,
  );

  return isEnabled;
}

const shouldSendEvents = () => {
  return (
    shouldSendAnalyticsEvents.value && !EnvironmentVariables.ANALYTICS_ECO_MODE
  );
};

export const analyticsAdapter = {
  track: (event: string, data: Record<string, any>) => {
    logger.debug('[Analytics]:', event, shouldSendEvents());

    if (!shouldSendEvents()) {
      return;
    }

    mixpanelClient.track(event, data);
  },
  identify: (user: User) => {
    mixpanelClient.identify(String(user.telegram_id));
    mixpanelClient.people.set_once({
      name: fullName(user),
    });
  },
  props({
    referrer,
    balance,
    lunarLootSpeedLvl,
    dockSizeLvl,
    stakingLvl,
    socialBoosts,
    isPremium,
    referralsCount,
    blackHoleSize,
  }: Partial<UserProps>) {
    const props = {
      ['Referrer']: referrer,
      ['Current balance']: balance,
      ['Lunar Loot Speed lvl']: lunarLootSpeedLvl,
      ['Dock Size lvl']: dockSizeLvl,
      ['Staking lvl']: stakingLvl,
      ['Social boost']: socialBoosts,
      ['Referrals count']: referralsCount,
      ['Is premium']: isPremium,
      ['Black hole size']: blackHoleSize,
    };

    // delete empty props
    Object.keys(props).forEach(
      // @ts-ignore
      (key) => props[key] === undefined && delete props[key],
    );

    mixpanelClient.people.set(props);
  },

  trackPageview: (pageName: string, props: Record<string, any> = {}) => {
    logger.debug('[Analytics]: PageView', pageName, shouldSendEvents());

    if (!shouldSendEvents()) {
      return;
    }

    mixpanelClient.track_pageview({
      page: pageName,
      ...props,
    });
  },
};

export function useTrackPageView(
  pageName: string,
  props: Record<string, any> = {},
) {
  const isSent = useRef(false);
  useEffect(() => {
    if (isSent.current) {
      return;
    }

    analyticsAdapter.trackPageview(pageName, props);

    return () => {
      isSent.current = true;
    };
  }, [pageName]);
}

export function useTrackOnMount(trackFn: () => void, isReady: boolean) {
  const isSent = useRef(false);

  useEffect(() => {
    if (isSent.current || !isReady) {
      return;
    }

    trackFn();

    return () => {
      isSent.current = true;
    };
  }, [trackFn, isReady]);
}
