import {effect} from '@preact/signals-react';
import {retrieveLaunchParams} from '@telegram-apps/sdk';
import {dockData} from '~/entities/dock';
import {lunarToFloat} from '~/entities/lunar-loot';
import {referralsData} from '~/entities/referral-link/model';
import {
  rewardsData,
  RewardsPageModel,
  userRewardsData,
} from '~/entities/rewards';
import {userData} from '~/entities/user';
import {analyticsAdapter} from '~/shared/analytics';

effect(() => {
  if (userData.value) {
    const miniAppParams = retrieveLaunchParams();

    const user = userData.value;

    analyticsAdapter.props({
      referrer: String(user.referred_by?.telegram_id || ''),
      isPremium: miniAppParams.initData?.user?.isPremium || undefined,
    });
  }
});

effect(() => {
  if (dockData.value) {
    const dock = dockData.value;
    analyticsAdapter.props({
      lunarLootSpeedLvl: dock.lunar_loot_speed_lvl,
      dockSizeLvl: dock.dock_size_lvl,
      stakingLvl: dock.yield_percentage_lvl,
      balance: lunarToFloat(dock.balance),
      blackHoleSize: lunarToFloat(dock.black_hole_balance),
    });
  }
});

effect(() => {
  if (referralsData.value) {
    const refData = referralsData.value;

    analyticsAdapter.props({
      referralsCount: refData.total_referrals,
    });
  }
});

effect(() => {
  if (userRewardsData.value && rewardsData.value) {
    const userRewards = userRewardsData.value;
    const rewards = rewardsData.value;

    const model = new RewardsPageModel(rewards, userRewards);

    analyticsAdapter.props({
      socialBoosts: model.claimedRewards.map((reward) => {
        return reward.name;
      }),
    });
  }
});
