import {initMiniApp, retrieveLaunchParams} from '@telegram-apps/sdk';
import {usePrevious} from '@uidotdev/usehooks';
import {createContext, useContext, useEffect, useState} from 'react';
import {logger} from '../debug';

export const [miniapp] = initMiniApp();
const params = retrieveLaunchParams();

interface ColorRequest {
  priority: number;
  token: string;
  color: string;
}

const DEFAULT_COLOR = '#FFF';

miniapp.setHeaderColor(DEFAULT_COLOR);

export function isMobile() {
  const p = params.platform;

  const targetWords = ['macos', 'desktop'];

  const IS_MOBILE = !targetWords.some((word) => p.includes(word));

  return IS_MOBILE;
}

export function isAndroid() {
  return params.platform.includes('android');
}

export function isIOS() {
  return params.platform.includes('ios');
}

const TelegramMiniAppContext = createContext<{
  colors: ColorRequest[];
  setColors: (colors: ColorRequest[]) => void;
}>({
  colors: [],
  setColors: (colors: ColorRequest[]) => {},
});

export function TelegramMiniAppProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [colors, setColors] = useState<ColorRequest[]>([]);

  useEffect(() => {
    const updateColor = () => {
      const color = colors[0]?.color || DEFAULT_COLOR;
      miniapp.setHeaderColor(color as `#${string}`);
    };
    updateColor();
  }, [colors]);

  return (
    <TelegramMiniAppContext.Provider
      value={{
        colors,
        setColors,
      }}
    >
      {children}
    </TelegramMiniAppContext.Provider>
  );
}

export function useHeaderColor(
  {color, priority, token}: ColorRequest,
  isActive: boolean,
) {
  const {colors, setColors} = useContext(TelegramMiniAppContext);

  const prevActive = usePrevious(isActive);
  useEffect(() => {
    logger.debug('Token color:', token, isActive);
    if (isActive !== prevActive) {
      if (isActive) {
        // add new color request if it doesn't exist
        if (!colors.some((req) => req.token === token)) {
          logger.debug('Add color request', token);
          setColors(
            [...colors, {color, priority, token}].sort((a, b) =>
              a.priority > b.priority ? -1 : 1,
            ),
          );
        }
      } else {
        logger.debug('Remove color request', token);
        const afterFilter = colors.filter((req) => req.token !== token);

        logger.debug('color Before/After filter:', colors, afterFilter);
        setColors(afterFilter);
      }
    }
  }, [isActive, prevActive]);

  useEffect(() => {
    return () => {
      logger.debug('UNMOUNT: Remove color request', token);
      const afterFilter = colors.filter((req) => req.token !== token);

      logger.debug('color Before/After filter:', colors, afterFilter);
      setColors(colors.filter((req) => req.token !== token));
    };
  }, []);
}
