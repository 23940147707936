import {useTranslation} from 'react-i18next';
import {
  RewardCard,
  RewardsCardDescription,
  RewardsCardTitle,
} from './reward-card';
import {rem} from '~/shared/utils/style-helpers';
import {SocialIcon} from '../social-icons';
import {RewardsPageModel, useRewards} from '~/entities/rewards';
import {RewardName} from '~/api/rewards';
import {useUtils} from '@telegram-apps/sdk-react';
import {SocialMedia} from '~/entities/social-media';

interface IRewardCardProps {}

const links: Record<Exclude<RewardName, 'twitter-post' | 'stake'>, string> = {
  telegram: 'https://t.me/theroketo',
  discord: 'https://discord.com/invite/ydaDeEMF3t',
  twitter: 'https://x.com/theroketo',
};

function SocialReward({
  name,
  ...rest
}: {
  name: Exclude<RewardName, 'twitter-post' | 'stake'>;
  model: RewardsPageModel;
} & React.HTMLProps<HTMLDivElement>) {
  const rewards = useRewards();
  const utils = useUtils();

  const reward = rewards.model.getRewardByName(name);

  if (!reward) {
    return null;
  }

  return (
    <SocialIcon
      social={name as SocialMedia}
      isChecked={rewards.model.isClaimed(name)}
      reward={reward.amount}
      doBeforeOpen={() => {
        if (
          !rewards.model.isClaimed(name) &&
          !rewards.claimRewardsMutation.isPending
        ) {
          rewards.claimRewardsMutation.mutate({
            reward_id: reward.id,
          });
        }
      }}
      {...rest}
    />
  );
}

export function SocialBoostReward({}: IRewardCardProps) {
  const rewards = useRewards();

  const {t} = useTranslation();

  return (
    <RewardCard
      isDone={
        rewards.model.isClaimed('twitter') &&
        rewards.model.isClaimed('telegram') &&
        rewards.model.isClaimed('discord')
      }
      css={{
        textAlign: 'center',
      }}
    >
      <RewardsCardTitle
        css={{
          marginBottom: rem(8),
        }}
      >
        {t('rewardsPage.socialsTitle')}
      </RewardsCardTitle>
      <RewardsCardDescription
        css={{
          marginBottom: rem(18),
        }}
      >
        {t('rewardsPage.socialsDescription')}
      </RewardsCardDescription>

      <div
        css={{
          display: 'flex',
          justifyContent: 'space-around',
          gap: rem(20),
          minHeight: rem(87),
        }}
      >
        <SocialReward name="twitter" model={rewards.model} />
        <SocialReward name="telegram" model={rewards.model} />
        {/* <SocialReward name="discord" model={rewards.model} /> */}
      </div>
    </RewardCard>
  );
}
