import {useQueryClient, useQuery, useMutation} from '@tanstack/react-query';
import {
  DockApi,
  IDockInfoResponse,
  IMultipleUpgradesResponse,
} from '~/api/dock';
import {useDockModel} from '~/entities/dock';
import {multipleUpgradeCostInitialData} from '~/entities/upgrades/initial-data';
import {
  UpgradeType,
  mapUpgradeTypeToName,
  getUpgradeLvlPropName,
} from '~/entities/upgrades';
import {useAuthorizationToken} from '~/entities/user';
import {isAllQueriesReady} from '~/shared/utils/query';
import {trackUpgrade} from './analytics';

class UpgradesCalculator {
  dock: IDockInfoResponse;
  upgrades: IMultipleUpgradesResponse;

  constructor(dock: IDockInfoResponse, upgrades: IMultipleUpgradesResponse) {
    this.dock = dock;
    this.upgrades = upgrades;
  }

  get boost() {
    if (this.dock.has_boost || this.dock.has_early_adopters_boost) {
      return this.dock.boost_multiplier;
    }

    return 1;
  }

  _rocketNextSpeed({
    upgradesArray,
    currentLvl,
  }: {
    upgradesArray: IMultipleUpgradesResponse['lunar_loot_speed_upgrades'];
    currentLvl: number;
  }) {
    return (
      (upgradesArray[currentLvl]?.value || 0) * this.boost +
      this.dock.referral_bonus
    );
  }

  _rocketCurrentValue({
    upgradesArray,
    currentLvl,
  }: {
    upgradesArray: IMultipleUpgradesResponse['lunar_loot_speed_upgrades'];
    currentLvl: number;
  }) {
    return (
      (upgradesArray[currentLvl - 1]?.value || 0) * this.boost +
      this.dock.referral_bonus
    );
  }

  nextLvlValue(upgradeType: UpgradeType) {
    const currentLvl = this.dock[getUpgradeLvlPropName(upgradeType)];
    const upgradesArray = getUpgradeArray(this.upgrades, upgradeType);

    if (upgradeType === 'rocket') {
      return this._rocketNextSpeed({
        upgradesArray,
        currentLvl,
      });
    }

    return upgradesArray[currentLvl]?.value || 0;
  }

  currentLvlValue(upgradeType: UpgradeType) {
    const currentLvl = this.dock[getUpgradeLvlPropName(upgradeType)];
    const upgradesArray = getUpgradeArray(this.upgrades, upgradeType);

    if (upgradeType === 'rocket') {
      return this._rocketCurrentValue({
        upgradesArray,
        currentLvl,
      });
    }

    return upgradesArray[currentLvl - 1]?.value || 0;
  }
}

export function useUpgradeModel(upgradeType: UpgradeType) {
  const token = useAuthorizationToken();
  const queryClient = useQueryClient();
  const dockModel = useDockModel();

  const upgradeName = mapUpgradeTypeToName(upgradeType);

  const getUpgradesCost = useQuery({
    queryKey: ['upgrades-cost'],
    queryFn: () => {
      return DockApi.getUpgrades(token);
    },
    refetchInterval: 0,
    refetchOnMount: false,
    staleTime: Infinity,
  });

  const getUpgradesCostData =
    getUpgradesCost.data || multipleUpgradeCostInitialData();

  const buyUpgrade = useMutation({
    mutationKey: ['buy-upgrade', upgradeName],
    mutationFn: () => {
      return DockApi.buyUpgrade(upgradeName, token);
    },
    onSuccess: (data) => {
      trackUpgrade({
        type: upgradeName,
        lvl: data.data[getUpgradeLvlPropName(upgradeType)],
      });

      // getUpgradeCost.refetch();
      queryClient.setQueryData(['dock'], data);
    },
  });

  const currentLvl = dockModel.dock[getUpgradeLvlPropName(upgradeType)];

  const upgradeLvlsArray = getUpgradeArray(
    getUpgradesCostData.data,
    upgradeType,
  );

  const _isReady = isAllQueriesReady(dockModel.dockQuery, getUpgradesCost);

  const upgradesCalculator = new UpgradesCalculator(
    dockModel.dock,
    getUpgradesCostData.data,
  );

  return {
    buyMutation: buyUpgrade,
    costQuery: getUpgradesCost,
    dockQuery: dockModel.dockQuery,

    isReady: _isReady,
    isMaxLvl: _isReady && currentLvl >= upgradeLvlsArray.length,
    upgrade: {
      lvl: currentLvl,
      cost: upgradeLvlsArray[currentLvl - 1]?.cost || 0,
      currentValue: upgradesCalculator.currentLvlValue(upgradeType),
      nextValue: upgradesCalculator.nextLvlValue(upgradeType),
    },
  };
}

function getUpgradeArray<T extends UpgradeType>(
  upgrades: IMultipleUpgradesResponse,
  type: T,
) {
  if (type === 'dock') {
    return upgrades.dock_size_upgrades;
  } else if (type === 'rocket') {
    return upgrades.lunar_loot_speed_upgrades;
  }

  return upgrades.yield_percentage_upgrades;
}
