import React from 'react';
import {RewardValue} from '~/components/kit';
import {Bonus} from '~/components/kit/bonus/bonus';
import {lunar} from '~/entities/lunar-loot';
import {RewardType} from '~/entities/rewards';
import {formatNumber, toFloatCrystals} from '~/shared/utils/format/format-numbers';

interface IRewardProps {
  isAlternativeColor: boolean;
  value: number;
  rewardType?: RewardType;
}

export function Reward({
  value,
  isAlternativeColor,
  rewardType = 'lunar',
  ...rest
}: IRewardProps & Omit<React.HTMLProps<HTMLDivElement>, 'color'>) {
  const formattedValue = rewardType === 'lunar'
    ? formatNumber(lunar(value))
    : formatNumber(toFloatCrystals(value));

  return (
    <Bonus color={isAlternativeColor ? 'alternative' : 'primary'} {...rest}>
      <span>+</span>
      <RewardValue value={formattedValue} rewardType={rewardType} />
    </Bonus>
  );
}
