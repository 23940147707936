import leBootsPng from './assets/le-boots.png';
import blackholeBgJpg from './assets/black-hole-bg.jpg';
import dontPanicSvg from './assets/dont-panic.svg';
import attentionSpaceCadetsSvg from './assets/attention-space-cadets.svg';
import astronautSittingPng from './assets/astronaut-sitting.png';
import starfallSvg from './assets/starfall.svg';
import astronautPointingPng from './assets/astronaut-pointing.png';
import {ImgTransition} from '~/components/kit/img-transition';
import {AspectRatio} from '~/components/kit/aspect-ratio';
import {rem} from '~/shared/utils/style-helpers';
import {AppTheme, useAppTheme} from '~/components/kit';
import {Typography} from '~/components/kit/typography';
import styled from '@emotion/styled';
import {Box} from '~/components/kit/box';
import {Delimiter} from '~/components/kit/delimiter';
import {opacity} from '~/shared/utils/colors';
import astrocatSvg from './assets/astrocat.svg';
import {ScrollTopButton} from '~/components/kit/scrolltop-button';
import {WalletConnectForm} from '~/features/connect-wallet';

const TextWithBackground = styled.span<{theme: AppTheme}>(({theme}) => ({
  background: theme.colors.surfaceAlternative,
  borderRadius: 999999,
  padding: `${rem(0)} ${rem(8)}`,
  lineHeight: 1.3,
}));

export function BlackholeEventExplainer() {
  const theme = useAppTheme();

  return (
    <div
      css={{
        position: 'relative',
        overflow: 'hidden',
        marginLeft: rem(-20),
        marginRight: rem(-20),
        marginBottom: rem(-20),
      }}
    >
      <div
        css={{
          position: 'relative',
          paddingLeft: rem(20),
          paddingRight: rem(20),
        }}
      >
        <div
          css={{
            marginTop: rem(40),
          }}
        >
          <Typography.h4 css={{textAlign: 'center', marginBottom: rem(24)}}>
            No Time To Explain, <br /> connect your EVM wallet
          </Typography.h4>

          <WalletConnectForm />
        </div>

        <div
          css={{
            fontSize: theme.fontSize.s5,
            fontWeight: 600,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            marginTop: rem(20),
          }}
        >
          <p>Wallet Connection Required</p>

          <p
            css={{
              color: theme.colors.onSurfaceSecondary,
            }}
          >
            for potential future rewards
          </p>
        </div>

        <ImgTransition
          src={blackholeBgJpg}
          css={{
            position: 'absolute',
            bottom: '0%',
            left: 0,
            right: 0,
            zIndex: -1,
          }}
        />
        <div
          css={{
            marginTop: rem(90),
          }}
        >
          <AspectRatio
            ratio={90 / 251}
            css={{
              maxWidth: rem(250),
              margin: 'auto',
            }}
          >
            <ImgTransition
              src={attentionSpaceCadetsSvg}
              css={{
                width: '100%',
              }}
            />
          </AspectRatio>

          <p
            css={{
              textAlign: 'center',
              fontSize: theme.fontSize.s6,
              fontWeight: 600,
              marginTop: rem(12),
            }}
          >
            <TextWithBackground theme={theme}>
              We gotta tell you something
            </TextWithBackground>
            <br />
            <TextWithBackground theme={theme}>
              really important
            </TextWithBackground>
          </p>

          <Typography.h3
            css={{
              textAlign: 'center',
              maxWidth: rem(220),
              margin: 'auto',
              color: theme.colors.onSurfaceAlternative,
              marginTop: rem(12),
            }}
          >
            <TextWithBackground theme={theme}>
              We've Detected a
            </TextWithBackground>
            <br />
            <TextWithBackground theme={theme}>
              GIANT&nbsp;BLACK&nbsp;HOLE
            </TextWithBackground>
          </Typography.h3>

          <div
            css={{
              display: 'flex',
              minHeight: rem(300),
            }}
          >
            <ImgTransition
              src={astronautPointingPng}
              css={{
                height: rem(90),
                marginTop: 'auto',
                marginLeft: 'auto',
              }}
            />
          </div>
        </div>
      </div>

      <div>
        <div
          css={{
            position: 'relative',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: rem(130),
          }}
        >
          <Typography.h4>
            And It's Hungry for
            <br />
            Your Lunar Coin!
          </Typography.h4>
          <ImgTransition
            src={starfallSvg}
            css={{
              position: 'absolute',
              height: '100%',
              top: 0,
              zIndex: -1,
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          />
        </div>

        <div
          css={{
            paddingLeft: rem(20),
            paddingRight: rem(20),
          }}
        >
          <AspectRatio
            ratio={224 / 335}
            css={{
              maxWidth: rem(335),
              margin: 'auto',
            }}
          >
            <ImgTransition src={dontPanicSvg} css={{width: '100%'}} />
          </AspectRatio>
          <Typography.h3
            css={{
              textAlign: 'center',
              marginTop: rem(24),
              color: '#FDF278',
              marginBottom: rem(50),
            }}
          >
            Here's Our Stellar Plan
          </Typography.h3>

          <Box
            radius={20}
            backgroundColor="#331A5C"
            css={{
              padding: rem(20),
            }}
          >
            <div css={{display: 'flex', alignItems: 'center', gap: rem(20)}}>
              <AspectRatio
                ratio={79 / 64}
                css={{
                  flexShrink: 0,
                  width: rem(64),
                }}
              >
                <ImgTransition src={astronautSittingPng}></ImgTransition>
              </AspectRatio>

              <Typography.h4>Our Totally-Not-Suspicious Mission</Typography.h4>
            </div>

            <Delimiter
              color={theme.colors.onSurfaceAlternative}
              css={{
                marginTop: rem(10),
              }}
            />

            <ol
              css={{
                display: 'flex',
                flexDirection: 'column',

                gap: rem(20),
                fontSize: theme.fontSize.s4,
                fontWeight: 500,
                color: theme.colors.onSurfaceAlternative,
                lineHeight: 1.3,
                counterReset: 'list',
                li: {
                  display: 'flex',
                },
                'li:before': {
                  counterIncrement: 'list',
                  fontSize: theme.fontSize.s6,
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  content: 'counter(list, decimal)',
                  width: rem(18),
                  height: rem(18),
                  padding: rem(4),
                  marginRight: rem(10),
                  flexShrink: 0,
                  border: `1px solid ${opacity(
                    theme.colors.onSurfaceAlternative,
                    0.5,
                  )}`,
                  textAlign: 'center',
                  borderRadius: '50%',
                  background: '#100522',
                },
              }}
            >
              <li>
                <div>
                  <p>
                    <span
                      css={{
                        textDecoration: 'underline',
                      }}
                    >
                      Dock
                    </span>{' '}
                    Your EVM Wallet
                  </p>
                  <p
                    css={{
                      fontSize: theme.fontSize.s7,
                      color: theme.colors.onSurfaceAlternative,
                      opacity: 0.6,
                      fontWeight: 400,
                    }}
                  >
                    It's your ticket to... something cool
                  </p>
                </div>
              </li>

              <li>Toss Your Lunar Coin into the Black Hole's Gaping Maw</li>
              <li>
                <div>
                  Keep Feeding! More Lunar Coin = More&nbsp;
                  <span
                    css={{
                      position: 'relative',
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                  >
                    Airdop Reward
                    <div
                      css={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        width: '100%',
                        height: '1.75em',
                        background: theme.colors.surfaceAlternative,
                        color: '#FA1E91',
                        fontWeight: 800,
                        borderBottom: `2px solid ${theme.colors.onSurfaceSecondary}`,
                        transform: 'rotate(-6deg)',
                      }}
                    >
                      TOP SECRET
                    </div>
                  </span>
                </div>
              </li>
              <li>
                <div>
                  Stay Tuned for Mysterious Transmissions from HQ
                  <p
                    css={{
                      fontSize: theme.fontSize.s7,
                      color: theme.colors.onSurfaceAlternative,
                      opacity: 0.6,
                      fontWeight: 400,
                    }}
                  >
                    We promise it's not space static... probably
                  </p>
                </div>
              </li>
            </ol>

            <Delimiter
              color={theme.colors.onSurfaceAlternative}
              css={{
                margin: `${rem(20)} 0`,
              }}
            />

            <div>
              <Typography.h3>Why Connect Your Wallet?</Typography.h3>
              <Typography.body
                css={{
                  fontSize: theme.fontSize.s4,
                  color: theme.colors.onSurfaceAlternativeSecondary,
                  fontWeight: 400,
                  marginTop: rem(8),
                }}
              >
                It's your cosmic mailbox for potential space treasures. Don't
                miss out on any surprise moon deliveries!
              </Typography.body>
            </div>

            <Delimiter
              color={theme.colors.onSurfaceAlternative}
              css={{
                margin: `${rem(20)} 0`,
              }}
            />

            <div css={{}}>
              <Typography.h3>Calling All Risk-Takers!</Typography.h3>
              <Typography.body
                css={{
                  fontSize: theme.fontSize.s4,
                  color: theme.colors.onSurfaceAlternativeSecondary,
                  fontWeight: 400,
                  marginTop: rem(8),
                }}
              >
                The more Lunar Coin you chuck into the void, the... um...
                shinier your space boots will be!
              </Typography.body>

              <div
                css={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <ImgTransition
                  src={leBootsPng}
                  css={{
                    height: rem(60),
                    marginTop: rem(-10),
                    marginBottom: rem(-20),
                  }}
                ></ImgTransition>
              </div>
            </div>
          </Box>

          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: rem(50),
            }}
          >
            <Typography.h3>Just Remember, Astronaut...</Typography.h3>
            <Typography.h3
              css={{
                marginTop: rem(18),
              }}
            >
              No Refunds in Space
            </Typography.h3>

            <div
              css={{
                marginBottom: rem(-40),
                marginTop: rem(20),
              }}
            >
              <AspectRatio
                ratio={269 / 232}
                css={{
                  width: rem(260),
                }}
              >
                <ImgTransition src={astrocatSvg}></ImgTransition>
              </AspectRatio>
            </div>
            <ScrollTopButton
              css={{
                position: 'absolute',
                bottom: rem(20),
                right: rem(20),
                zIndex: 2,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
