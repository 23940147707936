import {analyticsAdapter} from '~/shared/analytics';
import {IPlanetGuess} from './api/trailblazer-api';
import {lunar} from '~/entities/lunar-loot';

export function trackTrailblazerGuess(guessObject: IPlanetGuess) {
  analyticsAdapter.track('Trailblazer: submit guess', {
    correct: guessObject.planets_decoded_count,
    reward: lunar(guessObject.reward_amount),
    crystalReward: guessObject.crystal_amount,
  });
}
