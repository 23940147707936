import {Component, type ComponentType, type JSX} from 'react';
import MiningPage from '~/pages/mining-page';
import {
  LazyReferralsPage,
  LazyRewardsPage,
  LazyTradingPage,
  FriendsLazyPage,
  LeaderboardLazyPage,
  LeaderboardMiningLazyPage,
  LeaderboardStakesLazyPage,
  EarnLazyPage,
} from './pages';
import {Navigate, Outlet, Route} from 'react-router-dom';
import {TrailblazerPage} from '~/pages/earn-page/trailblazer-page';
import {EarnPage} from '~/pages/earn-page';
import {DailyTasksPage} from '~/pages/rewards-page/pages/daily-tasks';
import {OneTimeTasksPage} from '~/pages/rewards-page/pages/one-time-rewards-page';

export interface IRoute {
  path: string;
  title?: string;
  Component?: ComponentType;
  children?: IRoute[];
  element?: JSX.Element;
}

export const RoutePath = {
  Referrals: '/referrals',

  Mining: '/',
  MiningLeaderboard: '/mining/leaderboard',
  MiningLeaderboardWorlwide: '/mining/leaderboard/worldwide',
  MiningLeaderboardFriends: '/mining/leaderboard/friends',

  Friends: '/friends',

  Stakes: '/stakes',
  StakesLeaderboard: '/stakes/leaderboard',
  StakesLeaderboardWorldwide: '/stakes/leaderboard/worldwide',
  StakesLeaderboardFriends: '/stakes/leaderboard/friends',

  Leaderboard: '/leaderboard',
  LeaderboardMining: '/leaderboard/mining',
  LeaderboardStakes: '/leaderboard/stakes',

  Earn: '/earn',
  BlackHole: '/earn/blackhole',
  Trailblazer: '/earn/trailblazer',
  DailyTasks: '/earn/daily-tasks',
  OneTimeTasks: '/earn/one-time-tasks',
};

export const routes: IRoute[] = [
  {
    path: RoutePath.Earn,
    Component: () => <Outlet />,
    title: 'Earn',
    children: [
      {
        path: RoutePath.Trailblazer,
        Component: TrailblazerPage,
      },
      {
        path: RoutePath.BlackHole,
        Component: EarnPage,
      },
      {
        path: '',
        Component: LazyRewardsPage,
        children: [
          {
            path: RoutePath.DailyTasks,
            Component: DailyTasksPage,
          },
          {
            path: RoutePath.OneTimeTasks,
            Component: OneTimeTasksPage,
          },
          {
            path: '',
            Component: () => <Navigate to={RoutePath.DailyTasks} />,
          },
        ],
      },
    ],
  },
  {
    path: RoutePath.Referrals,
    Component: LazyReferralsPage,
    title: 'Referrals',
  },
  {
    path: RoutePath.Stakes,
    Component: LazyTradingPage,
    title: 'Stakes',
  },
  {
    path: RoutePath.Mining,
    Component: MiningPage,
    title: 'Mining',
  },
  {
    path: RoutePath.Leaderboard,
    Component: LeaderboardLazyPage,
    title: 'Leaderboards',
    children: [
      {
        path: RoutePath.LeaderboardMining,
        Component: LeaderboardMiningLazyPage,
      },
      {
        path: RoutePath.LeaderboardStakes,
        Component: LeaderboardStakesLazyPage,
      },
      {
        path: '',
        Component: () => <Navigate to={RoutePath.LeaderboardMining} />,
      },
    ],
  },
  // {
  //   path: RoutePath.Earn,
  //   title: 'Earn',
  //   Component: () => <Outlet />,
  //   children: [
  //     {
  //       path: RoutePath.Trailblazer,
  //       Component: TrailblazerPage,
  //     },
  //     {
  //       path: '',
  //       Component: EarnLazyPage,
  //     },
  //   ],
  // },
  {
    path: RoutePath.Friends,
    Component: FriendsLazyPage,
    title: 'Friends',
  },
].filter(Boolean) as IRoute[];

export function renderRoute(route: IRoute): React.ReactNode {
  return (
    <Route
      path={route.path}
      Component={route.Component}
      element={route.element}
      key={route.path}
    >
      {route.children && route.children.map(renderRoute)}
    </Route>
  );
}
