/**

src/features/trailblazer/assets/planets/bubblegum-giant.png
 src/features/trailblazer/assets/planets/cryptonova.png
 src/features/trailblazer/assets/planets/donutopia.png
 src/features/trailblazer/assets/planets/flufforia.png
 src/features/trailblazer/assets/planets/goopiter.png
 src/features/trailblazer/assets/planets/holeyverse.png
 src/features/trailblazer/assets/planets/lavalampia.png
 src/features/trailblazer/assets/planets/prickle-prime.png
 src/features/trailblazer/assets/planets/zapzilla.png
 */

import {ImgTransition} from '~/components/kit/img-transition';

import bubbleGumGiantPng from '../assets/planets/bubblegum-giant.png';
import cryptonovaPng from '../assets/planets/cryptonova.png';
import donutopiaPng from '../assets/planets/donutopia.png';
import flufforiaPng from '../assets/planets/flufforia.png';
import goopiterPng from '../assets/planets/goopiter.png';
import holeyversePng from '../assets/planets/holeyverse.png';
import lavalampiaPng from '../assets/planets/lavalampia.png';
import pricklePrimePng from '../assets/planets/prickle-prime.png';
import zapzillaPng from '../assets/planets/zapzilla.png';
import {AspectRatio} from '~/components/kit/aspect-ratio';
import {rem} from '~/shared/utils/style-helpers';
import {useSortable} from '@dnd-kit/sortable';
import {PlanetName} from '../lib';

export const PLANET_IMAGES: Record<PlanetName, string> = {
  'bubblegum-giant': bubbleGumGiantPng,
  cryptonova: cryptonovaPng,
  donutopia: donutopiaPng,
  flufforia: flufforiaPng,
  goopiter: goopiterPng,
  holeyverse: holeyversePng,
  lavalampia: lavalampiaPng,
  'prickle-prime': pricklePrimePng,
  zapzilla: zapzillaPng,
};

const PLANET_BG_COLOR: Record<PlanetName, string> = {
  'bubblegum-giant': '#0C506E',
  cryptonova: '#830077',
  donutopia: '#7A244F',
  flufforia: '#005982',
  goopiter: '#194815',
  holeyverse: '#00278A',
  lavalampia: '#68262E',
  'prickle-prime': '#744900',
  zapzilla: '#006074',
};

export function ExoPlanet({
  name,
  ...props
}: {
  name: PlanetName;
} & React.HTMLAttributes<HTMLDivElement>) {
  const {active, attributes, isDragging, listeners, setNodeRef, transition} =
    useSortable({
      id: name,
    });

  const isActive = isDragging && active?.id === name;
  return (
    <AspectRatio
      ratio={1}
      ref={setNodeRef}
      css={{
        borderRadius: rem(20),
        backgroundColor: isActive ? 'transparent' : PLANET_BG_COLOR[name],
        transition: `${transition} border-color `,
        boxShadow: isActive ? 'none' : '2px 3px 0px 0px #000',
        border: '2px dashed',
        borderColor: isActive ? 'rgba(255, 255, 255, 0.22)' : 'transparent',
        touchAction: 'none',
      }}
      {...props}
      {...attributes}
      {...listeners}
    >
      {isActive ? (
        <div></div>
      ) : (
        <ImgTransition src={PLANET_IMAGES[name]}></ImgTransition>
      )}
    </AspectRatio>
  );
}
