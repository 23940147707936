import React from 'react';
import {useAppTheme} from '~/components/kit/theme';
import {ModalTitle} from '~/components/kit/modal';
import {ImgTransition} from '~/components/kit/img-transition';
import {AspectRatio} from '~/components/kit/aspect-ratio';
import {Markdown} from '~/components/kit/markdown';
import howToBanner3 from '../assets/howto-banner-3.png';

export const Step3 = () => {
  const theme = useAppTheme();

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <ModalTitle
        css={{
          color: theme.colors.onSurfaceAlternative,
          paddingLeft: 0,
          paddingRight: 0,
          textShadow: `0px 0px 10px #0C0748`,
        }}
      >
        Unlimited Attempts for Space Explorers
      </ModalTitle>
      <div css={{flexGrow: 1}} />
      <Markdown
        css={{
          color: theme.colors.onSurfaceAlternative,
          fontSize: theme.fontSize.s5,
          textShadow: `0px 0px 10px #0C0748`,
        }}
      >
        Be carefuly about your guess - you've got just one try each day. But
        remember, this stellar event won't last forever, so start trailblazing
        soon! Check your attempt history to track your progress, but don't get
        too attached - it resets daily faster than a shooting star.
      </Markdown>
    </div>
  );
};

export const backgroundColor = '#0F0016';

export const bgImage = () => (
  <AspectRatio ratio={753 / 668}>
    <div
      css={{
        position: 'absolute',
        top: '60%',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        objectFit: 'cover',
        opacity: 1,
        zIndex: 1,
        background: `linear-gradient(180deg, transparent, ${backgroundColor} 100%)`,
      }}
    ></div>
    <ImgTransition
      src={howToBanner3}
      css={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'bottom center',
      }}
    />
  </AspectRatio>
);
