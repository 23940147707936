export const ErrorIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="vuesax/bold/information">
      <g id="vuesax/bold/information_2">
        <g id="information">
          <path
            id="Vector"
            d="M14.3736 7.16L13.4736 6.10667C13.3069 5.90667 13.1669 5.53333 13.1669 5.26667V4.13333C13.1669 3.42667 12.5869 2.84667 11.8802 2.84667H10.7469C10.4802 2.84667 10.1002 2.70667 9.90025 2.54L8.84691 1.64C8.38691 1.24667 7.63358 1.24667 7.17358 1.64L6.10691 2.54C5.90691 2.70667 5.53358 2.84667 5.26691 2.84667H4.11358C3.40691 2.84667 2.82691 3.42667 2.82691 4.13333V5.26667C2.82691 5.52667 2.69358 5.9 2.52691 6.1L1.62691 7.16C1.24025 7.62667 1.24025 8.37333 1.62691 8.82667L2.52691 9.88667C2.69358 10.08 2.82691 10.46 2.82691 10.72V11.86C2.82691 12.5667 3.40691 13.1467 4.11358 13.1467H5.27358C5.53358 13.1467 5.91358 13.2867 6.11358 13.4533L7.16691 14.3533C7.62691 14.7467 8.38025 14.7467 8.84025 14.3533L9.89358 13.4533C10.0936 13.2867 10.4669 13.1467 10.7336 13.1467H11.8669C12.5736 13.1467 13.1536 12.5667 13.1536 11.86V10.7267C13.1536 10.46 13.2936 10.0867 13.4602 9.88667L14.3602 8.83333C14.7669 8.38 14.7669 7.62667 14.3736 7.16ZM7.50025 5.42C7.50025 5.14667 7.72691 4.92 8.00025 4.92C8.27358 4.92 8.50025 5.14667 8.50025 5.42V8.64C8.50025 8.91333 8.27358 9.14 8.00025 9.14C7.72691 9.14 7.50025 8.91333 7.50025 8.64V5.42ZM8.00025 11.2467C7.63358 11.2467 7.33358 10.9467 7.33358 10.58C7.33358 10.2133 7.62691 9.91333 8.00025 9.91333C8.36691 9.91333 8.66691 10.2133 8.66691 10.58C8.66691 10.9467 8.37358 11.2467 8.00025 11.2467Z"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);
