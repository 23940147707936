import {UpgradeType} from '~/entities/upgrades';
import {analyticsAdapter, useTrackOnMount} from '~/shared/analytics';

export function trackUpgrade({type, lvl}: {type: string; lvl: number}) {
  analyticsAdapter.track('Upgrade: buy upgrade', {
    type,
    lvl,
  });
}

export function useTrackOpenUpgradeModal(
  {
    upgradeType,
    currentLvl,
    upgradeButtonStatus,
  }: {
    upgradeType: UpgradeType;
    currentLvl: number;
    upgradeButtonStatus: boolean;
  },
  isReady: boolean,
) {
  useTrackOnMount(() => {
    analyticsAdapter.track('Mining: booster upgrade form open', {
      upgrade_type: upgradeType,
      current_lvl: currentLvl,
      upgrade_button_status: upgradeButtonStatus ? 'active' : 'inactive',
    });
  }, isReady);
}
