export const WavyExclamationIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    height="24"
    width="24"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.7499 2.45031C11.4499 1.86031 12.5799 1.86031 13.2599 2.45031L14.8399 3.80031C15.1399 4.05031 15.7099 4.26031 16.1099 4.26031H17.8099C18.8699 4.26031 19.7399 5.13031 19.7399 6.19031V7.89031C19.7399 8.29031 19.9499 8.85031 20.1999 9.15031L21.5499 10.7303C22.1399 11.4303 22.1399 12.5603 21.5499 13.2403L20.1999 14.8203C19.9499 15.1203 19.7399 15.6803 19.7399 16.0803V17.7803C19.7399 18.8403 18.8699 19.7103 17.8099 19.7103H16.1099C15.7099 19.7103 15.1499 19.9203 14.8499 20.1703L13.2699 21.5203C12.5699 22.1103 11.4399 22.1103 10.7599 21.5203L9.17988 20.1703C8.87988 19.9203 8.30988 19.7103 7.91988 19.7103H6.16988C5.10988 19.7103 4.23988 18.8403 4.23988 17.7803V16.0703C4.23988 15.6803 4.03988 15.1103 3.78988 14.8203L2.43988 13.2303C1.85988 12.5403 1.85988 11.4203 2.43988 10.7303L3.78988 9.14031C4.03988 8.84031 4.23988 8.28031 4.23988 7.89031V6.20031C4.23988 5.14031 5.10988 4.27031 6.16988 4.27031H7.89988C8.29988 4.27031 8.85988 4.06031 9.15988 3.81031L10.7499 2.45031Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M12 8.12988V12.9599"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M11.9946 16H12.0036"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);
