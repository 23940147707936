import {Link} from 'react-router-dom';
import {RoutePath} from '~/app/navigation/routes';
import {AspectRatio} from '~/components/kit/aspect-ratio';
import {ImgTransition} from '~/components/kit/img-transition';
import trailblazerBannerPng from './assets/trailblazer-ad-banner.png';

export function TrailblazerAdBanner() {
  return (
    <Link to={RoutePath.Trailblazer}>
      <AspectRatio ratio={150 / 335}>
        <ImgTransition src={trailblazerBannerPng} />
      </AspectRatio>
    </Link>
  );
}
