import {ClientTimestamp} from '~/shared/utils/format/format-time';

export class YieldCalculator {
  private yieldInfo;

  constructor(yieldInfo: {
    yield_percentage: string;
    yield_period: ClientTimestamp;
    last_yield_time: ClientTimestamp;
  }) {
    this.yieldInfo = yieldInfo;
  }

  yieldSize(balance: number) {
    return Number(this.yieldInfo.yield_percentage) * balance;
  }

  get canClaimIn(): Date {
    return new Date(
      this.yieldInfo.yield_period + this.yieldInfo.last_yield_time - Date.now(),
    );
  }

  get canClaim() {
    return (
      Date.now() > this.yieldInfo.yield_period + this.yieldInfo.last_yield_time
    );
  }
}
