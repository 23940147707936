export const ADD_FRIEND_ACTION = {
  name: 'add-friend',
  priority: 20,
};

export const ONBOARDING_ACTION = {
  name: 'onboarding',
  priority: 10,
};

// News go last
export const NEWS_NOTIFICATION_ACTION = {
  name: 'news-notification',
  priority: 1,
};

// All actions with priority in descending order
// for viewing purposes
const allActions = [ADD_FRIEND_ACTION, ONBOARDING_ACTION];
