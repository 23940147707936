import {User} from '~/api';
import {toClientTimestamp} from '~/shared/utils/format/format-time';

export function isCreatedAtLeastDaysAgo(user: User, days: number) {
  const createdAt = new Date(toClientTimestamp(user.created_at));
  const now = new Date();
  const diff = now.getTime() - createdAt.getTime();

  return diff / (1000 * 60 * 60 * 24) >= days;
}

export function isBeforeDate(targetDate: Date, originDate: Date) {
  return targetDate.getTime() < originDate.getTime();
}
