import {Chip} from '~/components/kit/chip';
import {BlackHoleRank} from '../black-hole-ranks';

export function BlackHoleRankChip({rank}: {rank: BlackHoleRank}) {
  return (
    <Chip
      css={{
        background: rank.color,
        color: rank['text-color'],
        fontWeight: 700,
      }}
    >
      {rank.name}
    </Chip>
  );
}
