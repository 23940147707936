import React from 'react';
import {useAppTheme} from '~/components/kit';
import {Box} from '~/components/kit/box';
import {TextButton} from '~/components/kit/button/button';
import {Delimiter} from '~/components/kit/delimiter';
import {Icon} from '~/components/kit/icons';
import {ChainLinkIcon} from '~/components/kit/icons/chain-link';
import {EthereumIcon} from '~/components/kit/icons/ethereum-icon';
import {useDockModel} from '~/entities/dock';
import {useEarnModel} from '~/entities/earn';
import {RokeTokenLogo} from '~/entities/earn/roke-tokens';
import {RokeTokens} from '~/entities/roke-tokens';
import {useCurrentUser} from '~/entities/user';
import {rem} from '~/shared/utils/style-helpers';
function trimMiddle(address: string) {
  return address.slice(0, 6) + '...' + address.slice(-4);
}

function _WalletControlMenu(
  {...rest}: React.HTMLAttributes<HTMLDivElement>,
  ref?: React.ForwardedRef<HTMLDivElement>,
) {
  const dock = useDockModel();

  const earnModel = useEarnModel();

  const theme = useAppTheme();

  const user = useCurrentUser();

  return (
    <Box
      backgroundColor={theme.colors.surface}
      shadowColor={theme.colors.onSurface}
      borderWidth={1}
      shadowOffsetX={0}
      shadowOffsetY={2}
      radius={14}
      css={[
        {
          minWidth: rem(170),
          padding: rem(12),
        },
      ]}
      {...rest}
      ref={ref}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Icon size={24}>
          <EthereumIcon />
        </Icon>
        <Icon
          size={16}
          css={{
            marginRight: rem(6),
            marginLeft: rem(4),
          }}
        >
          <ChainLinkIcon />
        </Icon>
        <div
          css={{
            fontSize: theme.fontSize.s6,
            fontWeight: 500,
          }}
        >
          {trimMiddle(user.wallet_address)}
        </div>
      </div>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          marginTop: rem(10),
        }}
      >
        <RokeTokenLogo
          css={{
            marginRight: rem(8),
          }}
        ></RokeTokenLogo>
        <div
          css={{
            fontSize: theme.fontSize.s6,
            fontWeight: 500,
          }}
        >
          {RokeTokens.format(dock.dock.airdrop_balance || 0, {
            precision: 2,
          })}
        </div>
      </div>
      <div
        css={{
          fontSize: theme.fontSize.odds.s10,
          textAlign: 'left',
          maxWidth: rem(170),
          marginTop: rem(8),
          lineHeight: 1.3,
        }}
      >
        Token transfers to the wallet will be available after the network launch
      </div>
      <Delimiter
        color={theme.colors.onSurfaceTertiary}
        css={{
          marginTop: rem(12),
          marginBottom: rem(4),
        }}
      />

      <TextButton
        color="red"
        css={{
          border: 0,
          boxShadow: 'none !important',
        }}
        isDisabled={earnModel.disconnectWalletMutation.isPending}
        onClick={() => {
          earnModel.disconnectWalletMutation.mutate();
        }}
      >
        Disconnect Wallet
      </TextButton>
    </Box>
  );
}

export const WalletControlMenu = React.forwardRef(_WalletControlMenu);
