import {keyframes} from '@emotion/react';

export const floatingUpAndDownKeyframes = keyframes`
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(1rem);
  }
`;
