import {UseQueryResult} from '@tanstack/react-query';

export function isQueryReady(query: UseQueryResult<any>) {
  return query.isFetched && query.isSuccess;
}

export function isAllQueriesReady(...queries: UseQueryResult<any>[]) {
  return queries.every((query) => {
    return isQueryReady(query);
  });
}
