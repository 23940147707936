export const BalanceCrystalsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.00169 14.2926L9.80315 20.9604C11.0236 22.1569 12.9769 22.1569 14.1973 20.9604L20.9986 14.2926C22.131 13.1825 22.2573 11.402 21.2931 10.1431L18.4963 6.49179C17.9025 5.71643 16.9815 5.26175 16.0048 5.26175H7.99562C7.01898 5.26175 6.09801 5.71642 5.50413 6.49174L2.70727 10.1431C1.74297 11.402 1.8693 13.1825 3.00169 14.2926L4.35871 12.9084L3.00169 14.2926Z" fill="#D047B2" stroke="#FFC2F2" strokeWidth="3.87688"/>
    <path d="M11.1602 19.5753L4.35871 12.9074C3.92574 12.483 3.87744 11.8022 4.24614 11.3209L7.043 7.6695C7.27007 7.37306 7.6222 7.19922 7.99562 7.19922H16.0048C16.3782 7.19922 16.7304 7.37307 16.9575 7.66952L19.7542 11.3209C20.1229 11.8022 20.0746 12.483 19.6416 12.9074L12.8403 19.5752C12.3737 20.0327 11.6268 20.0327 11.1602 19.5753Z" fill="#D047B2"/>
    <path d="M11.1602 19.5753L4.35871 12.9074C3.92574 12.483 3.87744 11.8022 4.24614 11.3209L7.043 7.6695C7.27007 7.37306 7.6222 7.19922 7.99562 7.19922H16.0048C16.3782 7.19922 16.7304 7.37307 16.9575 7.66952L19.7542 11.3209C20.1229 11.8022 20.0746 12.483 19.6416 12.9074L12.8403 19.5752C12.3737 20.0327 11.6268 20.0327 11.1602 19.5753Z" stroke="black" strokeWidth="1.2" strokeLinejoin="round"/>
    <path d="M4.94369 8.77363C4.83558 8.67131 4.61037 8.72923 4.5717 8.58285C4.53244 8.43231 4.75732 8.38864 4.81798 8.26018C4.83079 8.23326 4.84307 8.20593 4.85582 8.17854C4.90098 8.17774 4.92586 8.15192 4.93821 8.11181C5.03862 8.01787 5.12316 7.91296 5.17995 7.78742C5.21585 7.76522 5.2314 7.73412 5.22206 7.69239C5.28452 7.5897 5.34704 7.48748 5.4095 7.3848C5.42411 7.36708 5.43913 7.34883 5.45374 7.33111L5.45214 7.33324C5.46375 7.31453 5.47529 7.29536 5.4869 7.27665L5.48797 7.27413C5.52239 7.25167 5.53234 7.21806 5.53061 7.1795L5.52734 7.18328C5.53936 7.16404 5.55177 7.14428 5.56378 7.12504C5.61241 7.08539 5.6365 7.03413 5.63465 6.97145L5.63538 6.96992C5.64933 6.93763 5.66281 6.9054 5.67669 6.87264C5.78495 6.55134 5.90121 6.23268 5.96711 5.89853C5.97807 5.84206 5.97268 5.76474 6.04019 5.74036C6.13532 5.70587 6.1257 5.80807 6.16024 5.84949C6.81657 6.6393 7.6054 7.2468 8.58661 7.59096C8.67192 7.62082 8.79106 7.634 8.66776 7.76767C7.9311 8.56499 7.46224 9.50463 7.17647 10.5742C6.66466 9.99075 6.15245 9.4377 5.4761 9.07246C5.45456 9.05852 5.43248 9.04418 5.41093 9.03024C5.39366 9.01947 5.37638 9.00869 5.35958 8.99786C5.3413 8.98676 5.32256 8.97572 5.30428 8.96462C5.29641 8.95629 5.28701 8.95385 5.27607 8.95732C5.25232 8.94464 5.22864 8.93242 5.2049 8.91974L5.20856 8.92205C5.18909 8.90923 5.16961 8.89641 5.1506 8.88353L5.1522 8.88471C5.13339 8.87322 5.11498 8.86119 5.09617 8.84969C5.06656 8.83596 5.03741 8.82217 5.0078 8.80844C4.98612 8.79688 4.96437 8.78486 4.94316 8.77323L4.94369 8.77363Z" fill="white"/>
    <path d="M12.1745 18.1974C11.7287 17.1477 11.4802 16.0341 11.1296 14.9536C10.5602 13.1974 10.0143 11.4343 9.45297 9.67602C9.34685 9.34205 9.3446 9.19112 9.7937 9.2705C11.6921 9.60634 13.5979 9.90648 15.5005 10.2229C15.5883 10.2375 15.6721 10.2789 15.7581 10.3086C15.746 10.4145 15.7557 10.5284 15.7192 10.6259C14.8229 12.9837 14.0024 15.3752 12.6782 17.5484C12.5343 17.784 12.4509 18.0639 12.1757 18.1988L12.1745 18.1974Z" fill="#EE83FF"/>
    <path d="M8.97228 11.3999C9.70828 13.3434 10.4079 15.1915 11.1312 17.102C10.7358 16.8093 10.5404 16.4249 10.2568 16.1195C9.45153 15.2497 8.65684 14.3707 7.84291 13.508C7.65337 13.3075 7.60047 13.1647 7.79429 12.9285C8.18325 12.4559 8.54424 11.959 8.97163 11.3993L8.97228 11.3999Z" fill="#6236CC"/>
    <path d="M16.2124 11.4001C15.3265 13.28 14.4843 15.0676 13.6137 16.9157C14.0308 16.6549 14.2557 16.2869 14.5623 16.0047C15.4332 15.2006 16.2943 14.3865 17.1733 13.5902C17.3779 13.4051 17.4419 13.267 17.2671 13.0162C16.9164 12.5147 16.5954 11.991 16.2131 11.3995L16.2124 11.4001Z" fill="#6236CC"/>
  </svg>
);
