const TWITTER_POSTS_BATCH_1 = [
  {
    text: '🚀 Just hopped on the Roketo rocket! 🌈 Competing with friends to farm tokens & predict crypto prices. Join the fun! #REF_LINK #HASHTAGS',
  },
  {
    text: "🌟 New app alert! Roketo is live and it’s a colorful crypto playground. Predict prices, farm tokens, and flex on friends. Who's in? #REF_LINK #HASHTAGS",
  },
  {
    text: '🚀 @theroketo Roketo is my new crypto obsession! Farming tokens and crushing price predictions. Ready to join the fun? Let’s goooo! #REF_LINK #HASHTAGS',
  },
  {
    text: '🎮 @theroketo Roketo is where it’s at! Predict crypto prices, farm tokens, and compete with your crew. Who’s ready to moon? 🌕 #REF_LINK #HASHTAGS',
  },
  {
    text: '💎 @theroketo Roketo just dropped and it’s lit!🔥 Predict crypto prices, farm tokens, and compete with frenz. Let’s get those gains together! #REF_LINK #HASHTAGS',
  },
  {
    text: '💰 @theroketo Roketo just launched and it’s a blast! Farming tokens and nailing price predictions. Jump in and show off your crypto skills! #REF_LINK #HASHTAGS',
  },
  {
    text: "🚀 @theroketo Roketo's new app is fire!🔥 Competing with friends in crypto price predictions and token farming. Come join the ride! #REF_LINK #HASHTAGS",
  },
  {
    text: '🏆 @theroketo Roketo: the ultimate crypto mini-game app is here! Predict prices, farm tokens, and compete with frenz. Are you game? #REF_LINK #HASHTAGS',
  },
  {
    text: '🌈 @theroketo Roketo is live! Predict crypto prices, farm tokens, and flex on your friends. Let’s see who’s the real crypto master! #REF_LINK #HASHTAGS',
  },
  {
    text: '🚀 Just discovered @theroketo Roketo! Competing with friends to farm tokens & predict crypto prices. Who’s ready for some fun? #REF_LINK #HASHTAGS',
  },
  {
    text: '🌟 @theroketo Roketo is the new wave! Predict prices, farm tokens, and compete with your crypto frenz. Ready to blast off? #REF_LINK #HASHTAGS',
  },
  {
    text: "🚀 @theroketo Roketo is my latest addiction! Predict crypto prices and farm tokens while competing with friends. Let's get this bread! 🍞 #REF_LINK #HASHTAGS",
  },
  {
    text: "🎮 @theroketo Roketo is live and it’s epic! Predict crypto prices, farm tokens, and show your friends who's boss. Join me! #REF_LINK #HASHTAGS",
  },
  {
    text: '🚀 @theroketo Roketo just dropped a colorful new app! Predict crypto prices, farm tokens, and battle your friends. Let’s rocket to the moon! 🌕 #REF_LINK #HASHTAGS',
  },
  {
    text: '🌈 @theroketo Roketo is the ultimate crypto playground! Compete with friends, farm tokens, and predict prices. Who’s in for the challenge? #REF_LINK #HASHTAGS',
  },
  {
    text: "🚀 @theroketo Roketo just launched and it's amazing! Farming tokens and predicting crypto prices with friends. Join the fun and compete with me! #REF_LINK #HASHTAGS",
  },
  {
    text: "🌟 @theroketo Roketo is here and it's a game changer! Predict prices, farm tokens, and compete with your squad. Let’s see who’s the best! #REF_LINK #HASHTAGS",
  },
  {
    text: "🚀 @theroketo Roketo's new app is a blast! Predict crypto prices, farm tokens, and challenge your friends. Ready to join the fun? #REF_LINK #HASHTAGS",
  },
  {
    text: "🌈 @theroketo Roketo is the latest crypto craze! Compete with friends, predict prices, and farm tokens. Who's ready to dominate? #REF_LINK #HASHTAGS",
  },
  {
    text: "🚀 Just joined @theroketo Roketo! Competing with friends to predict crypto prices and farm tokens. Who's up for the challenge? #REF_LINK #HASHTAGS",
  },
  {
    text: "🎮 @theroketo Roketo is lit!🔥 Predicting crypto prices, farming tokens, and competing with friends. Let's see who's the real crypto champ! #REF_LINK #HASHTAGS",
  },
  {
    text: "🚀 @theroketo Roketo is my new favorite! Predict crypto prices, farm tokens, and battle your friends. Who's ready to join me? #REF_LINK #HASHTAGS",
  },
  {
    text: "🌟 @theroketo Roketo just launched and it's awesome! Predict prices, farm tokens, and compete with friends. Let’s see who comes out on top! #REF_LINK #HASHTAGS",
  },
  {
    text: "🚀 @theroketo Roketo is here and it's fire! Predict crypto prices, farm tokens, and compete with your crew. Ready to have some fun? #REF_LINK #HASHTAGS",
  },
  {
    text: "🌈 @theroketo Roketo is the ultimate crypto mini-game! Compete with friends, predict prices, and farm tokens. Who's ready to play? #REF_LINK #HASHTAGS",
  },
  {
    text: '🚀 Just discovered @theroketo Roketo! Predicting crypto prices and farming tokens while competing with friends. Who’s in? #REF_LINK #HASHTAGS',
  },
  {
    text: '🎮 @theroketo Roketo is my new obsession! Predict prices, farm tokens, and show your friends who’s boss. Join the fun! #REF_LINK #HASHTAGS',
  },
  {
    text: '🚀 @theroketo Roketo just launched and it’s a blast! Farming tokens and predicting crypto prices with friends. Who’s ready to join me? #REF_LINK #HASHTAGS',
  },
  {
    text: '🌟 @theroketo Roketo is the new crypto playground! Predict prices, farm tokens, and compete with your friends. Let’s see who’s the best! #REF_LINK #HASHTAGS',
  },
  {
    text: '🚀 @theroketo Roketo is here and it’s awesome! Competing with friends to farm tokens and predict crypto prices. Join the fun! #REF_LINK #HASHTAGS',
  },
  {
    text: '🌈 @theroketo Roketo is the latest craze! Predict crypto prices, farm tokens, and challenge your friends. Ready to dominate? #REF_LINK #HASHTAGS',
  },
  {
    text: "🚀 Just hopped on @theroketo Roketo! Predicting crypto prices and farming tokens with friends. Who's up for the challenge? #REF_LINK #HASHTAGS",
  },
  {
    text: '🎮 @theroketo Roketo is live and it’s epic! Predict prices, farm tokens, and compete with your crew. Who’s ready for some fun? #REF_LINK #HASHTAGS',
  },
  {
    text: '🚀 @theroketo Roketo just dropped and it’s lit!🔥 Predict crypto prices, farm tokens, and battle your friends. Join the ride! #REF_LINK #HASHTAGS',
  },
  {
    text: "🌟 @theroketo Roketo is here and it's a game changer! Predict prices, farm tokens, and compete with friends. Let’s see who’s the best! #REF_LINK #HASHTAGS",
  },
  {
    text: "🚀 @theroketo Roketo's new app is a blast! Predict crypto prices, farm tokens, and challenge your squad. Ready to have some fun? #REF_LINK #HASHTAGS",
  },
  {
    text: '🌈 @theroketo Roketo is the ultimate crypto playground! Compete with friends, predict prices, and farm tokens. Who’s ready to play? #REF_LINK #HASHTAGS',
  },
  {
    text: '🚀 @theroketo Roketo is my latest addiction! Predicting crypto prices and farming tokens with friends. Who’s in? #REF_LINK #HASHTAGS',
  },
  {
    text: '🎮 @theroketo Roketo is live and it’s awesome! Predict prices, farm tokens, and show your friends who’s boss. Join the fun! #REF_LINK #HASHTAGS',
  },
];
const TWITTER_POSTS_BATCH_2 = [
  {
    text: "🚀 @theroketo Roketo just dropped, and I'm predicting crypto prices like a psychic. 🧙‍♂️ Join me or stay REKT, your call. #REF_LINK #FOMO #WAGMI #HASHTAGS",
  },
  {
    text: 'Diamond Hands are cool, but predicting crypto prices with @theroketo Roketo is ICONIC. 🚀 #REF_LINK #HODL #Moon #HASHTAGS',
  },
  {
    text: "@theroketo Roketo's app: where your crystal ball meets the blockchain. 🔮😂 But ofc #REF_LINK #DYOR #CryptoGems  #HASHTAGS",
  },
  {
    text: "Tried @theroketo Roketo's new app and got 100% correct predit rate (did 1 try). Still waiting for my Lambo though. 🚗💨 #REF_LINK #Bullish #CryptoDreams  #HASHTAGS",
  },
  {
    text: 'On @theroketo Roketo like a whale on a pump. 🐋🚀 #REF_LINK #ApeIntoThis #HASHTAGS',
  },
  {
    text: "@theroketo Roketo's airdrops will be HUGE! Don't miss out, apes! 🚀💰 #REF_LINK #FOMO #DiamondHands #HASHTAGS",
  },
  {
    text: 'Just discovered a new gem: Roketo! Predict crypto prices, earn rewards, and get those airdrops! 🌕 #REF_LINK #Moon #Pump #HASHTAGS',
  },
  {
    text: '@theroketo Roketo is live and the FOMO is REAL. Predict prices, farm tokens, win big. 🌟 #REF_LINK #WAGMI #CryptoFrenz #HASHTAGS',
  },
  {
    text: "If you're not on @theroketo Roketo, are you even crypto-ing? 🚀 Predict, earn, and HODL! #REF_LINK #Bullish #Moon  #HASHTAGS",
  },
  {
    text: "@theroketo Roketo's airdrops will make your portfolio explode! 🚀 Don't miss out! #REF_LINK #Bagholder #HODLer  #HASHTAGS",
  },
  {
    text: "Just joined @theroketo Roketo's new app - a brilliant platform to compete in predicting crypto prices and farm tokens. Great potential for serious rewards. 🚀 #REF_LINK #HASHTAGS",
  },
  {
    text: "@theroketo Roketo's predictive platform is a game-changer for crypto enthusiasts. Compete with your real friends and earn real rewards. 💡 #REF_LINK #HASHTAGS",
  },
  {
    text: '@theroketo Roketo offers a unique way to engage with crypto markets, even for non web3 userbase. Predict, compete, and earn. 🧠🚀 #REF_LINK #HASHTAGS',
  },
  {
    text: '@theroketo Roketo is the latest gem in the crypto space, combining prediction and yield farming. Worth checking out. #REF_LINK #HASHTAGS',
  },
  {
    text: "@theroketo Roketo's airdrop rewards will be significant for early adopters. Exciting times ahead! 🚀 #REF_LINK #CryptoCommunity #HODL #HASHTAGS",
  },
  {
    text: "Kicking back and farming tokens on @theroketo Roketo. Who's joining me? 🚀 #REF_LINK #ChillVibes #CryptoLife #HASHTAGS",
  },
  {
    text: 'Predicting crypto prices on @theroketo Roketo is my new zen. 🧘‍♂️🌕 #REF_LINK #Relax #HODL #HASHTAGS',
  },
  {
    text: "@theroketo Roketo's app is live. Predict prices, earn tokens, no stress. #REF_LINK #CryptoCool #Yield  #HASHTAGS",
  },
  {
    text: 'Easygoing days on @theroketo Roketo, farming tokens and predicting prices. 🚀 #REF_LINK #HODL #ChillCrypto #HASHTAGS',
  },
  {
    text: 'Just discovered @theroketo Roketo. Cool platform for predicting crypto prices and earning tokens. 🧘‍♂️🌟 #REF_LINK #CryptoZen #HODL #HASHTAGS',
  },
  {
    text: "@theroketo Roketo's app just launched and it's a game-changer! Predict prices, farm tokens, and get those airdrops! 🚀🌕 #REF_LINK #MoonMission #Bullish #HASHTAGS",
  },
  {
    text: '@theroketo Roketo is where the real apes are predicting prices and farming tokens. Get in or get left behind! 🚀💰 #REF_LINK #WAGMI #PumpIt #HASHTAGS',
  },
  {
    text: "@theroketo Roketo's predictive platform is the future of crypto engagement. Don't sleep on this gem! 🌕🧠 #REF_LINK #CryptoRevolution #FOMO #HASHTAGS",
  },
  {
    text: "@theroketo Roketo's app is a must-try for all serious HODLers. Predict, compete, and win big! 🚀 #REF_LINK #CryptoGains #DiamondHands #HASHTAGS",
  },
  {
    text: "@theroketo Roketo: The ultimate test of your crypto prediction skills. Join me and let's win those airdrops! 🚀💡 #REF_LINK #CryptoPro #Moon #HASHTAGS",
  },
  {
    text: "@theroketo Roketo's new app is live and we're all gonna make it! Predict prices, farm tokens, and earn massive rewards! 🚀💎 #REF_LINK #WAGMI #Moon #HASHTAGS",
  },
  {
    text: '@theroketo Roketo is where the smart money is predicting prices and earning rewards. WAGMI, frenz! 🌕🚀 #REF_LINK #Bullish #CryptoGains #HASHTAGS',
  },
  {
    text: "@theroketo Roketo's platform is a win-win for crypto enthusiasts. Predict, earn, and let's all make it! 🧠💰 #REF_LINK #WAGMI #Pump #HASHTAGS",
  },
  {
    text: "@theroketo Roketo's app is a diamond in the rough. Predict prices, farm tokens, and let's ride this rocket together! 🚀💎 #REF_LINK #WAGMI #MoonMission #HASHTAGS",
  },
  {
    text: "@theroketo Roketo's predictive and farming platform is the key to our crypto future. Join me and WAGMI! 🌟💰 #REF_LINK #CryptoCommunity #Bullish #HASHTAGS",
  },
  {
    text: 'Why predict the weather when you can predict crypto prices on @theroketo Roketo? 🌧️→🌕🚀 #REF_LINK #CryptoHobby #PredictAndWin #HASHTAGS',
  },
  {
    text: 'My paper hands are turning into diamond hands thanks to @theroketo Roketo. 😂🚀 #REF_LINK #FromZeroToHero #Moon #HASHTAGS',
  },
  {
    text: "Trading's too mainstream. I'm into predicting crypto prices on @theroketo Roketo now. 🧙‍♂️🚀 #REF_LINK #CoolKids #CryptoTrends #HASHTAGS",
  },
  {
    text: 'My new job title: Professional Crypto Predictor on @theroketo Roketo. Still on my probation period tho. 😂💼 #REF_LINK #LivingTheDream #Bullish #HASHTAGS',
  },
  {
    text: "@theroketo Roketo is the new playground for crypto predictors. Join me and let's make some serious gains! 🚀🌟 #REF_LINK #CryptoFrenz #MoonMission #HASHTAGS",
  },
  {
    text: "Don't miss out on @theroketo Roketo's new app. Predict prices, farm tokens, and let's conquer the crypto world together! 🚀💰 #REF_LINK #HODL #Bullish #HASHTAGS",
  },
  {
    text: "@theroketo Roketo's platform is a goldmine for those with sharp prediction skills. Are you up for the challenge? 🌕🧠 #REF_LINK #CryptoPros #WAGMI #HASHTAGS",
  },
  {
    text: "@theroketo Roketo's app is the place to be for crypto enthusiasts. Predict, compete, and let's earn those rewards! 🚀🌟 #REF_LINK #HODLer #Moon #HASHTAGS",
  },
  {
    text: "@theroketo Roketo's new app is out! Predict crypto prices, farm tokens, and win big rewards. 🚀🌟 #REF_LINK #CryptoGems #HODL #HASHTAGS",
  },
  {
    text: '@theroketo Roketo: Predict prices, earn tokens, and ride the crypto wave! 🌊🚀 #REF_LINK #Bullish #FOMO #HASHTAGS',
  },
  {
    text: "Check out @theroketo Roketo's new app for predictive price competition and token farming. 🚀💰 #REF_LINK #CryptoFrenz #Moon #HASHTAGS",
  },
  {
    text: "@theroketo Roketo's app is the latest gem in crypto. Predict, compete, and earn huge rewards! 🌕🚀 #REF_LINK #HODL #DiamondHands #HASHTAGS",
  },
  {
    text: "@theroketo Roketo's airdrops are here! Predict prices, farm tokens, and grab your share. 🚀💎 #REF_LINK #Bullish #CryptoGains #HASHTAGS",
  },
  {
    text: "@theroketo Roketo's app launch is a game-changer! Predict crypto prices, earn tokens, and get those airdrops! 🚀🌕 #REF_LINK #MoonMission #Bullish #HASHTAGS",
  },
  {
    text: "@theroketo Roketo's predictive platform is the hottest thing in crypto right now! Don't miss out! 🚀🔥 #REF_LINK #FOMO #CryptoHype #HASHTAGS",
  },
  {
    text: "@theroketo Roketo's app is taking off! Predict, compete, and earn massive rewards. 🚀💰 #REF_LINK #Bullish #CryptoDreams #HASHTAGS",
  },
  {
    text: "@theroketo Roketo's airdrops are epic! Predict prices, farm tokens, and join the crypto revolution! 🚀🌟 #REF_LINK #Moon #HODL #HASHTAGS",
  },
  {
    text: "@theroketo Roketo's app is live and the hype is real! Predict, earn, and ride the rocket to the moon! 🚀🌕 #REF_LINK #FOMO #Bullish #HASHTAGS",
  },
];

export const TWITTER_POSTS = {
  posts: [...TWITTER_POSTS_BATCH_1, ...TWITTER_POSTS_BATCH_2],
};
