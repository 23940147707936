import {PageLayout} from '~/components/layouts/page-layout';
import {Card} from '../card';
import {Markdown} from '../markdown';
import {logger} from '~/shared/debug';
import sittingAstronautPng from './assets/sitting-astronaut.png';
import {ImgTransition} from '../img-transition';
import {rem} from '~/shared/utils/style-helpers';

export function FullscreenError({error}: {error: string | unknown}) {
  logger.debug('FullscreenError', error);
  return (
    <PageLayout>
      <Card
        rounding="medium"
        css={{
          textAlign: 'center',
        }}
      >
        <Markdown
          css={{
            maxWidth: rem(250),
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <h1>We are cooking something...</h1>
          <p>Sometimes it explodes... Try again</p>
        </Markdown>

        <ImgTransition
          src={sittingAstronautPng}
          alt="sitting-astronaut"
          css={{
            height: rem(200),
          }}
        />
      </Card>
    </PageLayout>
  );
}
