export function ScrollableContent({
  children,
  ref,
  ...rest
}: {children: React.ReactNode} & React.HTMLProps<HTMLDivElement>) {
  return (
    <div
      css={{
        overflow: 'auto',
      }}
      {...rest}
    >
      {children}
    </div>
  );
}
