import {useMutation} from '@tanstack/react-query';
import {useAuthorizationToken} from '~/entities/user';
import {DockApi} from '~/api/dock';
import {trackClaimedYield} from './analytics';
import {useDockModel} from '~/entities/dock';
import {lunarToFloat} from '~/entities/lunar-loot';

export function useClaimYield() {
  const dock = useDockModel();

  const token = useAuthorizationToken();

  const mutation = useMutation({
    mutationKey: ['claim-yield'],
    mutationFn: async () => {
      const data = await DockApi.claimYield(token);

      trackClaimedYield({
        amount: lunarToFloat(dock.yieldCalculator.yieldSize(dock.dock.balance)),
      });
      return data;
    },
  });

  return mutation;
}
