import {useState} from 'react';
import {useAppTheme} from '~/components/kit';
import {Box} from '~/components/kit/box';
import {Button} from '~/components/kit/button/button';
import {Delimiter} from '~/components/kit/delimiter';
import {Typography} from '~/components/kit/typography';
import {useEarnModel} from '~/entities/earn';
import {rem} from '~/shared/utils/style-helpers';
import {isAddress} from 'viem/utils';

// 0x8aC7Ba450bEa997fE00b2dd1bd6fD844521d7e55
export function WalletConnectForm() {
  const theme = useAppTheme();
  const [wallet, setWallet] = useState<string>('');

  const earnModel = useEarnModel();

  const isValid = isAddress(wallet);
  const submitError = earnModel.connectWalletMutation.error?.message;
  return (
    <Box
      backgroundColor="#786EED"
      radius={20}
      borderWidth={3}
      shadowOffsetY={1}
      shadowOffsetX={1}
      shadowColor={theme.colors.onSurface}
      css={{
        padding: `${rem(25)} ${rem(20)}`,
      }}
    >
      <Typography.h3
        css={{
          color: theme.colors.onSurfaceAlternative,
          textAlign: 'center',
        }}
      >
        Your EVM Wallet Adress
      </Typography.h3>

      <div
        css={{
          marginTop: rem(20),
        }}
      >
        <input
          placeholder="0x..."
          css={{
            borderRadius: 12,
            background: theme.colors.surface,
            border: 'none',
            padding: `${rem(15)} ${rem(14)}`,
            width: '100%',
          }}
          onChange={(e) => setWallet(e.target.value)}
          disabled={earnModel.connectWalletMutation.isPending}
        ></input>

        <ul
          css={{
            listStyleType: 'disc',
            marginLeft: '1.25em',
            fontSize: theme.fontSize.s6,
            color: theme.colors.onSurfaceAlternative,
            marginTop: rem(10),
          }}
        >
          <li>We'll verify your wallet connection</li>
        </ul>
      </div>

      <Delimiter
        css={{marginTop: rem(14)}}
        color={theme.colors.onSurfaceAlternative}
      />

      {submitError && (
        <p
          css={{
            fontSize: theme.fontSize.odds.s10,
            color: theme.colors.onSurfaceCritical,
            marginTop: rem(10),
          }}
        >
          {submitError}
        </p>
      )}

      <Button
        color="dark"
        isLoading={earnModel.connectWalletMutation.isPending}
        isDisabled={earnModel.connectWalletMutation.isPending || !isValid}
        css={{
          width: '100%',
          marginTop: rem(18),
        }}
        onClick={() => {
          earnModel.connectWalletMutation.mutate(wallet);
        }}
      >
        Connect Wallet
      </Button>
    </Box>
  );
}
