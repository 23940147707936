import {useAppTheme} from '~/components/kit';
import {rem} from '~/shared/utils/style-helpers';

interface IChipProps extends Omit<React.HTMLProps<HTMLDivElement>, 'color'> {
  variant?: 'small' | 'medium' | 'large';
}

export function Chip({children, variant = 'small', ...rest}: IChipProps) {
  const theme = useAppTheme();

  const fontSize =
    variant === 'small' ? rem(theme.fontSize.s7) : rem(theme.fontSize.s5);
  const padding =
    variant === 'small' ? `${rem(4)} ${rem(6)}` : `${rem(4)} ${rem(12)}`;

  return (
    <div
      css={{
        display: 'inline-flex',
        justifyContent: 'center',
        lineHeight: 1,
        minWidth: rem(70),
        padding: padding,
        alignItems: 'center',
        fontSize: fontSize,
        fontWeight: 500,
        borderRadius: 9999999,
      }}
      {...rest}
    >
      {children}
    </div>
  );
}

export function ChipValueDelta({
  variant,
  children,
  ...rest
}: {variant: 'negative' | 'positive'} & React.HTMLProps<HTMLDivElement>) {
  const theme = useAppTheme();

  const colorSets = {
    negative: {
      bgColor: '#FFE5EC',
      textColor: '#FB4776',
    },
    positive: {
      textColor: '#00B754',
      bgColor: '#E5FFF1',
    },
  };

  const colorSet =
    variant === 'positive' ? colorSets.positive : colorSets.negative;

  return (
    <Chip
      css={{
        fontWeight: 700,
        fontSize: theme.fontSize.s7,
        padding: `${rem(4)} ${rem(8)}`,
        minWidth: 0,
        background: colorSet.bgColor,
        color: colorSet.textColor,
      }}
      {...rest}
    >
      {children}
    </Chip>
  );
}
