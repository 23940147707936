// Generated from SVG to Code Figma Plugin
export const ChainLinkIcon = () => (
  <svg
    height="16"
    width="16"
    fill="none"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.99998 4.66699H4.66665C2.8257 4.66699 1.33331 6.15938 1.33331 8.00033C1.33331 9.84128 2.8257 11.3337 4.66665 11.3337H5.99998C7.84093 11.3337 9.33331 9.84128 9.33331 8.00033M11 11.3337H11.3333C13.1743 11.3337 14.6666 9.84128 14.6666 8.00033C14.6666 6.15938 13.1743 4.66699 11.3333 4.66699H9.99998C8.15903 4.66699 6.66665 6.15938 6.66665 8.00033"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.33333"
    />
  </svg>
);
