import {rem} from '~/shared/utils/style-helpers';
import {createAvatar} from '@dicebear/core';
import {lorelei} from '@dicebear/collection';
import {useEffect, useState} from 'react';
import {useAppTheme} from '../theme';

interface IAvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  id: string;
  size: 'small' | 'medium' | 'large' | number;
}

function seedRNG(seed: string) {
  let seedValue = 0;
  for (let i = 0; i < seed.length; i++) {
    seedValue += seed.charCodeAt(i);
  }
  return seedValue;
}

export function Avatar({id, size, ...rest}: IAvatarProps) {
  const theme = useAppTheme();

  const sizePx =
    size === 'small'
      ? 30
      : size === 'medium'
      ? 40
      : size === 'large'
      ? 64
      : size;
  const [avatarDataUri, setAvatarDataUri] = useState<string | null>(null);

  let bgColors = [
    theme.colors.accents.orange.background,
    theme.colors.accents.pink.background,
    theme.colors.accents.purple.background,
    theme.colors.accents.red.background,
    theme.colors.accents.yellow.onSurfaceSecondary,
  ];

  bgColors = theme.colors.components.progressBar.progress;

  useEffect(() => {
    const createDataUri = async () => {
      setAvatarDataUri(
        await createAvatar(lorelei, {
          seed: id,
        }).toDataUri(),
      );
    };

    createDataUri();
  }, [id]);

  return (
    <div
      css={{
        width: rem(sizePx),
        height: rem(sizePx),
        border: `1px solid ${theme.colors.onSurface}`,
        borderRadius: '50%',
        overflow: 'hidden',
        flexShrink: 0,
        backgroundColor: id
          ? bgColors[seedRNG(id) % bgColors.length]
          : 'transparent',
      }}
      {...rest}
    >
      {avatarDataUri && id ? (
        <img
          src={avatarDataUri}
          alt="avatar"
          css={{
            transform: 'scale(1.15)',
          }}
        />
      ) : (
        ''
      )}
    </div>
  );
}
