import React from 'react';
import {IDockInfoResponse} from '~/api/dock';
import {useAppTheme} from '~/components/kit';
import {Chip} from '~/components/kit/chip';
import {ImgTransition} from '~/components/kit/img-transition';
import {rem} from '~/shared/utils/style-helpers';
import jetpackPng from './assets/jetpack.png';
import {Typography} from '~/components/kit/typography';
import {
  formatCountdownWords,
  toClientTimestamp,
} from '~/shared/utils/format/format-time';
import {useCurrentUser} from '~/entities/user';

const ONE_WEEK = 7 * 24 * 60 * 60 * 1000;

export enum BoostVariants {
  JetpackBooster = 'Jetpack Booster',
  EarlyAdoptersBooster = 'Early Adopters Booster',
}

export function MiningBoosts({
  dock,
  boostVariant,
  ...rest
}: {dock: IDockInfoResponse} & {
  boostVariant: BoostVariants;
} & React.HTMLProps<HTMLDivElement>) {
  const theme = useAppTheme();
  const user = useCurrentUser();

  return (
    <div
      css={{
        textAlign: 'center',
        display: 'flex',
        gap: rem(10),
      }}
      {...rest}
    >
      <div
        css={{
          width: 87,
          height: 87,
          flexShrink: 0,
          position: 'relative',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 89 89"
          preserveAspectRatio="none"
          fill="none"
        >
          <circle cx="44.6038" cy="44.6384" r="43.6535" fill="#50ABFF" />
          <circle cx="44.6036" cy="44.6383" r="39.3429" fill="#FF7ABA" />
          <circle cx="44.6037" cy="44.6378" r="34.6181" fill="#FCA21B" />
          <circle cx="44.6037" cy="44.6372" r="29.4609" fill="#C854FF" />
        </svg>
        <ImgTransition
          src={jetpackPng}
          css={{
            position: 'absolute',
            top: '55%',
            left: '48%',
            transform: 'translateX(-50%) translateY(-50%)',
            width: '110%',
          }}
        ></ImgTransition>
      </div>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          css={{
            textAlign: 'left',
            marginBottom: rem(8),
          }}
        >
          <Typography.body>{boostVariant}</Typography.body>
          <Typography.h4>
            {boostVariant === BoostVariants.JetpackBooster &&
              formatCountdownWords(
                new Date(toClientTimestamp(user.created_at) + ONE_WEEK),
              )}
            {boostVariant === BoostVariants.EarlyAdoptersBooster &&
              formatCountdownWords(
                new Date(
                  toClientTimestamp(dock.early_adopters_boost_expires_at),
                ),
              )}
          </Typography.h4>
        </div>
        <div
          css={{
            marginTop: 'auto',
          }}
        >
          <Chip
            variant="large"
            css={{
              fontWeight: 600,
              color: theme.colors.accents.purple.onSurface,
              backgroundColor: theme.colors.accents.purple.surface,
            }}
          >
            Lunar Coin Speed x{dock.boost_multiplier}
          </Chip>
        </div>
      </div>
    </div>
  );
}
