import React, {forwardRef} from 'react';

interface IAspectRatioProps {
  ratio?: number;
}

export const AspectRatio = forwardRef<
  HTMLDivElement,
  IAspectRatioProps & React.HTMLProps<HTMLDivElement>
>(
  (
    {
      ratio = 1,
      children,
      ...props
    }: IAspectRatioProps & React.HTMLProps<HTMLDivElement>,
    ref?,
  ) => {
    return (
      <div ref={ref} {...props}>
        <div
          css={{
            width: '100%',
            position: 'relative',
            paddingTop: `${ratio * 100}%`,
            '& > *': {
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: '100%',
            },
          }}
        >
          {children}
        </div>
      </div>
    );
  },
);
