import {
  Modal,
  ModalSteps,
  ModalStepsControlButton,
  ModalTitle,
  useModalSteps,
  useModals,
} from '~/components/kit/modal';
import lunarLootAbsorptionTextSvg from '../assets/lunar-loot-absorption.svg';
import {AspectRatio} from '~/components/kit/aspect-ratio';
import {ImgTransition} from '~/components/kit/img-transition';
import {rem} from '~/shared/utils/style-helpers';
import {useAppTheme} from '~/components/kit';
import {Markdown} from '~/components/kit/markdown';
import planetAPng from '../assets/planet-a.png';
import planetBPng from '../assets/planet-b.png';
import {keyframes} from '@emotion/react';
import {floatingUpAndDownKeyframes} from '../shared/animations';
import React from 'react';

export const BLACK_HOLE_HOW_IT_WORKS_MODAL = 'blackhole-how-it-works';

function Step1() {
  const theme = useAppTheme();

  return (
    <React.Fragment>
      <ModalTitle
        css={{
          color: theme.colors.onSurfaceAlternative,
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        Cosmic&nbsp;Crisis&nbsp;Alert, Astronauts!
      </ModalTitle>
      <div
        css={{
          position: 'relative',
          marginLeft: rem(-20),
          marginRight: rem(-20),
          overflow: 'hidden',
        }}
      >
        <ImgTransition
          src={planetBPng}
          css={{
            position: 'absolute',
            top: '30%',
            left: rem(-40),
            width: rem(80),
            animation: `${floatingUpAndDownKeyframes} ease-out 5s infinite alternate`,
          }}
        />
        <ImgTransition
          src={planetAPng}
          css={{
            position: 'absolute',
            top: '30%',
            width: rem(80),
            right: rem(-40),
            animation: `${floatingUpAndDownKeyframes} ease-in 7s infinite alternate`,
          }}
        />
        <AspectRatio
          ratio={263 / 317}
          css={{
            textAlign: 'center',
            display: 'flex',
            maxWidth: rem(160),
            margin: 'auto',
            marginTop: rem(12),
          }}
        >
          <ImgTransition
            src={lunarLootAbsorptionTextSvg}
            css={{
              width: '100%',
              maxWidth: rem(300),
            }}
          />
        </AspectRatio>
      </div>

      <Markdown
        css={{
          color: theme.colors.onSurfaceAlternativeSecondary,
          marginTop: rem(12),
        }}
      >
        <p>
          A massive Black Hole just appeared nearby, and our space station's a
          bit light on supplies.{' '}
          <em>But wait... we've got tons of Lunar Coin!</em>
        </p>{' '}
        <p>
          Space cadets, it's time for a bold mission: toss that Lunar Coin into
          the Black Hole. Keep throwing until your arms get tired. Mine for
          more, then repeat! Why, you ask?...
        </p>
      </Markdown>
    </React.Fragment>
  );
}

function Step2() {
  const theme = useAppTheme();
  return (
    <React.Fragment>
      <ModalTitle
        css={{
          color: theme.colors.onSurfaceAlternative,
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        Trust us, it's for science...
      </ModalTitle>

      <Markdown
        css={{
          color: theme.colors.onSurfaceAlternativeSecondary,
          marginTop: rem(12),
          em: {
            fontWeight: 600,
          },
        }}
      >
        <p>...and maybe an Airdrop that could save us all!</p>
        <p>
          Keep mining and throwing Lunar Coin into the void.{' '}
          <em>The more you toss, the better our chances!</em>
        </p>
        <p>
          <em
            css={{
              color: theme.colors.onSurfaceAlternative,
            }}
          >
            Your mission:
          </em>{' '}
          Fatten up that Black Hole to its maximum rank. It'll grow as you feed
          it Lunar Coin! Who knows what the max rank could be?!
        </p>
        <p>
          <em
            css={{
              color: theme.colors.onSurfaceAlternative,
            }}
          >
            Pro tip:
          </em>{' '}
          Invite five fellow space cadets as referrals. Together, they'll
          activate your Referral Boost, helping you bulk up your Black Hole
          twice as fast!
        </p>
      </Markdown>
    </React.Fragment>
  );
}

export function BlackholeModal(props: React.HTMLProps<HTMLDivElement>) {
  const theme = useAppTheme();
  const steps = useModalSteps({
    steps: [Step1, Step2],
  });
  const modals = useModals();

  return (
    <Modal
      controlButton={
        <ModalStepsControlButton
          steps={steps}
          afterLastStep={() => {
            modals.closeExact(BLACK_HOLE_HOW_IT_WORKS_MODAL);
          }}
        />
      }
      name={BLACK_HOLE_HOW_IT_WORKS_MODAL}
      backgroundColor={'#0D051E'}
      css={{
        color: theme.colors.onSurfaceAlternative,
      }}
      afterClose={() => {
        steps.setStep(0);
      }}
    >
      <steps.Step></steps.Step>

      <ModalSteps
        steps={steps.length}
        activeStep={steps.step}
        isAlternative={true}
      />
    </Modal>
  );
}
