import {signal} from '@preact/signals-react';
import {useQuery} from '@tanstack/react-query';
import {IReferralInfoResponse, ReferralsApi} from '~/api/referrals';
import {useAuthorizationToken} from '../user';
import {makeAxiosResponse} from '~/api/shared/api-client';

export const referralsData = signal<IReferralInfoResponse | null>(null);

const EMPTY_REFERRAL_INFO: IReferralInfoResponse = {
  referral_bonus: 0,
  total_referrals: 0,
  total_referrals_by_week: [],
  n_last_referrals: [],
  earned_on_referral_program: '0',
  total_active_referrals: 0,
};

export function useReferralInfo() {
  const token = useAuthorizationToken();

  const query = useQuery({
    queryKey: ['referral-info'],
    queryFn: async () => {
      const response = await ReferralsApi.getReferralInfo(token);

      referralsData.value = response.data;

      return response;
    },
    refetchInterval: 0,
    refetchOnMount: false,
    staleTime: 10 * 60 * 1000,
  });

  return {
    query,
    data: (query.data || makeAxiosResponse(EMPTY_REFERRAL_INFO)).data,
  };
}
