import {RewardValue, useAppTheme} from '~/components/kit';
import {ImgTransition} from '~/components/kit/img-transition';
import {MODAL_PADDING, Modal} from '~/components/kit/modal';
import {AspectRatio} from '~/components/kit/aspect-ratio';
import {Typography} from '~/components/kit/typography';
import {Markdown} from '~/components/kit/markdown';
import {rem} from '~/shared/utils/style-helpers';
import React, {useState, useEffect} from 'react';
import {BlackHoleRankChip} from '~/entities/earn/black-hole/ui/black-hole-rank-chip';
import {useDockModel} from '~/entities/dock';
import {getBlackHoleRank} from '~/entities/earn/black-hole/black-hole-ranks';
import {lunar} from '~/entities/lunar-loot';
import {formatNumber} from '~/shared/utils/format/format-numbers';
import {Delimiter} from '~/components/kit/delimiter';
import {RokeTokenLogo} from '~/entities/earn/roke-tokens/ui/token-logo';
import {Button} from '~/components/kit/button/button';
import {Box} from '~/components/kit/box';
import {Icon} from '~/components/kit/icons';
import {WavyExclamationIcon} from '~/components/kit/icons/wavy-exclamation-icon';
import {opacity} from '~/shared/utils/colors';
import {RokeTokens} from '~/entities/roke-tokens';
import {useAirdropModel} from '~/entities/airdrops';

export const AIRDROP_MODAL_NAME = 'black-hole-airdrop';

const BANNER_AMOUNT = 11;

const bgColor: Record<number, string> = {
  0: '#1D1C31',
  1: '#090B22',
  2: '#26152A',
  3: '#0B052C',
  4: '#1D014C',
  5: '#27134E',
  6: '#0B0D33',
  7: '#152437',
  8: '#2D2435',
  9: '#0F131A',
  10: '#10032D',
};

const importAirdropBannerImage = async (index: number) => {
  switch (index) {
    case 0:
      return (await import('./assets/airdrop-modal-banner-0.png')).default;
    case 1:
      return (await import('./assets/airdrop-modal-banner-1.png')).default;
    case 2:
      return (await import('./assets/airdrop-modal-banner-2.png')).default;
    case 3:
      return (await import('./assets/airdrop-modal-banner-3.png')).default;
    case 4:
      return (await import('./assets/airdrop-modal-banner-4.png')).default;
    case 5:
      return (await import('./assets/airdrop-modal-banner-5.png')).default;
    case 6:
      return (await import('./assets/airdrop-modal-banner-6.png')).default;
    case 7:
      return (await import('./assets/airdrop-modal-banner-7.png')).default;
    case 8:
      return (await import('./assets/airdrop-modal-banner-8.png')).default;
    case 9:
      return (await import('./assets/airdrop-modal-banner-9.png')).default;
    case 10:
      return (await import('./assets/airdrop-modal-banner-10.png')).default;
    default:
      return (await import('./assets/airdrop-modal-banner-0.png')).default;
  }
};

export function AirdropModal({
  airdropName,
  airdropAmount,
  seasonRank,
  isClaimed,
}: {
  airdropName: string;
  airdropAmount: number;
  seasonRank: number;
  isClaimed: boolean;
}) {
  const theme = useAppTheme();

  const dockModel = useDockModel();
  const airdrops = useAirdropModel(airdropName);
  const [bannerImage, setBannerImage] = useState<string>('');
  const index = dockModel.dock.id % BANNER_AMOUNT;

  const backgroundColor = bgColor[index] || '#1D1C31';

  useEffect(() => {
    const loadImage = async () => {
      const img = await importAirdropBannerImage(index);
      setBannerImage(img);
    };
    loadImage();
  }, [index]);

  return (
    <Modal
      backgroundColor={backgroundColor}
      name={AIRDROP_MODAL_NAME}
      css={{
        color: theme.colors.onSurfaceAlternative,
      }}
      bgImage={
        <AspectRatio ratio={163 / 335} css={{}}>
          <div
            css={{
              position: 'absolute',
              top: '90%',
              bottom: 0,
              left: 0,
              right: 0,
              width: '100%',
              objectFit: 'cover',
              opacity: 1,
              zIndex: 1,
              background: `linear-gradient(180deg, transparent, ${backgroundColor} 100%)`,
            }}
          ></div>
          <ImgTransition
            src={bannerImage}
            css={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'bottom center',
            }}
          />
        </AspectRatio>
      }
      borderWidth={2}
    >
      <AspectRatio
        ratio={163 / 335}
        css={{
          marginLeft: MODAL_PADDING * -1,
          marginTop: MODAL_PADDING * -1,
          marginRight: MODAL_PADDING * -1,
        }}
      ></AspectRatio>
      <div>
        <Typography.h1
          css={{
            textAlign: 'center',
            marginBottom: rem(12),
          }}
        >
          You got Airdropped!
        </Typography.h1>
        <Markdown
          css={{
            textAlign: 'center',
            color: theme.colors.onSurfaceAlternativeSecondary,
            fontSize: theme.fontSize.odds.s15,
          }}
        >
          <p>
            Your enthusiastic Lunar Loot tossing has paid off big time. Here's
            your cosmic reward — use it wisely on your next space adventure!
          </p>
          <p>
            <em
              css={{
                color: theme.colors.onSurfaceAlternative,
              }}
            >
              Remember:
            </em>{' '}
            The stars may be silent, but your epic yeeting echoed across the
            galaxy!
          </p>
        </Markdown>
      </div>

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: rem(10),
        }}
      >
        <AirdropDetailRow
          label="You Finished at"
          value={
            <div
              css={{
                color: theme.colors.onSurfaceAlternative,
              }}
            >
              {seasonRank} place
            </div>
          }
        ></AirdropDetailRow>
        <AirdropDetailRow
          label="Singularity Rank"
          value={
            <BlackHoleRankChip
              rank={getBlackHoleRank(dockModel.dock.black_hole_balance)}
            ></BlackHoleRankChip>
          }
        ></AirdropDetailRow>
        <AirdropDetailRow
          label="Lunar Loot Thrown In"
          value={
            <div
              css={{
                color: theme.colors.onSurfaceAlternative,
              }}
            >
              <RewardValue
                value={formatNumber(
                  lunar(dockModel.dock.black_hole_balance, {
                    precision: 3,
                  }),
                )}
              />
            </div>
          }
        ></AirdropDetailRow>
      </div>

      <Delimiter
        color={theme.colors.onSurfaceTertiary}
        css={{
          marginTop: rem(12),
          marginBottom: rem(12),
        }}
      />

      <div>
        <Typography.h7
          css={{
            color: theme.colors.onSurfaceAlternativeSecondary,
          }}
        >
          Your Reward for Event Achievements:
        </Typography.h7>

        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: rem(10),
          }}
        >
          <Typography.h1
            css={{
              display: 'inline-flex',
              alignItems: 'center',
            }}
          >
            {RokeTokens.format(airdropAmount, {
              precision: 3,
            })}
            <RokeTokenLogo
              css={{
                marginLeft: rem(6),
              }}
            />
          </Typography.h1>
          <Typography.h6>
            ≈ $
            {RokeTokens.format(airdropAmount, {
              precision: 2,
            })}
          </Typography.h6>
        </div>
      </div>

      {isClaimed ? (
        <Box
          backgroundColor="#3A385E"
          radius={12}
          css={{
            padding: `${rem(16)} ${rem(14)}`,
            fontSize: theme.fontSize.s7,
            display: 'flex',
            alignItems: 'center',
            marginTop: rem(20),
          }}
        >
          <Icon
            size={32}
            css={{
              marginRight: rem(8),
            }}
          >
            <WavyExclamationIcon />
          </Icon>
          <p
            css={{
              color: opacity(theme.colors.onSurfaceAlternative, 0.9),
            }}
          >
            Check your token balance by clicking the wallet icon in the top
            right corner
          </p>
        </Box>
      ) : (
        <Button
          color="red"
          css={{
            width: '100%',
            marginTop: rem(20),
          }}
          onClick={() => {
            airdrops.claimAirdrop.mutate();
          }}
          isDisabled={airdrops.claimAirdrop.isPending}
          isLoading={airdrops.claimAirdrop.isPending}
        >
          Claim Reward
        </Button>
      )}
    </Modal>
  );
}

function AirdropDetailRow({
  label,
  value,
  ...rest
}: {
  label: string;
  value: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>) {
  const theme = useAppTheme();
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: theme.fontSize.s7,
        color: theme.colors.onSurfaceAlternativeSecondary,
      }}
      {...rest}
    >
      <Typography.h7>{label}</Typography.h7>
      {value}
    </div>
  );
}
