import {useDockModel} from '~/entities/dock';
import {useTrackPageView} from '../analytics';

export function useMiningPageView() {
  const dockModel = useDockModel();

  useTrackPageView('Mining', {
    claim_moons_button_status: dockModel.dockProgressCalculator.canClaim
      ? 'active'
      : 'inactive',
    claim_yield_button_status: dockModel.yieldCalculator.canClaim
      ? 'active'
      : 'inactive',
  });
}
