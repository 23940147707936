export function TooltipTail(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.83992 4.22993C12.3096 -1.27104 20.6096 -0.281191 22.6883 5.88147V5.88147C23.2799 7.63534 23.2655 9.56938 22.3743 11.1917C17.7039 19.694 8.97428 23.2763 0.220337 24.8645C4.13998 19.5384 5.92008 16.1754 6.13146 11.4801C6.21565 9.60999 6.62182 7.74656 7.62052 6.1632L8.83992 4.22993Z"
        fill="currentColor"
      />
    </svg>
  );
}
