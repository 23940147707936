import {Box} from '~/components/kit/box';
import {ImgTransition} from '~/components/kit/img-transition';
import {rem} from '~/shared/utils/style-helpers';
import dailyMissionBg from './assets/daily-mission-bg.png';
import {StreakSign} from './streak-sign';
import {Typography} from '~/components/kit/typography';
import {useAppTheme} from '~/components/kit';
import {DailyMissionMarks} from './daily-mission-marks';
import {DAYLY_CRYSTAL_REWARD_VALUES, DAYLY_REWARD_TYPES, useDailyMission} from '../../model';
import {Button} from '~/components/kit/button/button';
import {Reward} from '~/pages/rewards-page/ui/reward';
import React from 'react';
import {Countdown, secondsUntilDate} from '~/shared/utils/hooks';
import {
  toClientTimestamp,
} from '~/shared/utils/format/format-time';

const from = '#D884F5';
const to = '#A242C4';
const gradient = `linear-gradient(180deg, ${from} 0%, ${from} 40%, ${to} 41%, ${to} 100%)`;

export function DailyMissionCard(props: React.HTMLProps<HTMLDivElement>) {
  const theme = useAppTheme();

  const dailyMissionModel = useDailyMission();

  const isReady = dailyMissionModel.isReady;
  const streakDays = dailyMissionModel.statsData.streak_days;

  const lastClaimReward = dailyMissionModel.model.lastClaimReward;
  const lunarClaimReward = dailyMissionModel.model.claimReward;

  const lastClaimeDayIdx = streakDays > 0 ? streakDays - 1 : 0;
  const lastRewardType = DAYLY_REWARD_TYPES[lastClaimeDayIdx];
  const lastClaimedReward = lastRewardType === 'lunar' ? lastClaimReward : DAYLY_CRYSTAL_REWARD_VALUES[lastClaimeDayIdx];

  const nextRewardType = DAYLY_REWARD_TYPES[lastClaimeDayIdx + 1];
  const nextReward = nextRewardType === 'lunar' ? lunarClaimReward : DAYLY_CRYSTAL_REWARD_VALUES[lastClaimeDayIdx + 1];

  const buttonCanClaimContent = (
    <React.Fragment>
      Claim & get{' '}
      <Reward
        value={nextReward}
        rewardType={nextRewardType}
        isAlternativeColor
        css={{
          marginLeft: rem(8),
        }}
      ></Reward>
    </React.Fragment>
  );

  const buttonClaimedContent = (
    <React.Fragment>
      Next bonus in&nbsp;
      <Countdown
        timeLeft={secondsUntilDate(
          new Date(toClientTimestamp(dailyMissionModel.statsData.can_claim_at)),
        )}
        onEnd={() => {}}
        css={{
          color: theme.colors.onSurface,
          fontWeight: 'black',
        }}
      />
    </React.Fragment>
  );

  return (
    <Box
      backgroundColor={gradient}
      css={{
        padding: rem(20),
        minHeight: rem(335),
      }}
      bgImage={
        <ImgTransition
          src={dailyMissionBg}
          css={{
            width: '100%',
            marginTop: rem(100),
          }}
        />
      }
      {...props}
    >
      <div
        css={{
          display: 'flex',
        }}
      >
        <StreakSign
          streak={streakDays}
          reward={lastClaimedReward}
          rewardType={lastRewardType}
          css={{
            flexShrink: 0,
          }}
        />
        <div
          css={{
            marginLeft: rem(16),
          }}
        >
          <Typography.h3
            css={{
              color: theme.colors.onSurfaceAlternative,
            }}
          >
            Daily&nbsp;Rewards <br />
            for diligent&nbsp;miners!
          </Typography.h3>
        </div>
      </div>

      {isReady && (
        <DailyMissionMarks
          dailyRewardsArray={dailyMissionModel.model.dailyRewardsArray}
          checkedMarks={dailyMissionModel.model.checkedMarks}
          css={{
            marginLeft: rem(-20),
            marginRight: rem(-20),
            paddingBottom: rem(40),
          }}
        ></DailyMissionMarks>
      )}

      <p
        css={{
          fontSize: theme.fontSize.s7,
          fontWeight: 600,
          color: theme.colors.onSurfaceAlternative,
          textAlign: 'center',
        }}
      >
        Your streak now is longer than{' '}
        <span
          css={{
            color: '#FDF278',
          }}
        >
          {dailyMissionModel.statsData.streak_top_percent}%
        </span>{' '}
        of astronauts
      </p>

      <Button
        color="pink"
        css={{
          width: '100%',
          marginTop: rem(8),
          fontWeight: 800,
        }}
        isDisabled={!dailyMissionModel.model.canClaim()}
        isLoading={dailyMissionModel.claimQuery.isPending}
        onClick={() => {
          dailyMissionModel.claimQuery.mutate();
        }}
      >
        {dailyMissionModel.model.canClaim()
          ? buttonCanClaimContent
          : buttonClaimedContent}
      </Button>
    </Box>
  );
}
