export const StakingUpgradeIcon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="vuesax/linear/flash">
      <g id="flash">
        <path
          id="Vector"
          d="M5.07492 11.0777H7.64992V17.0777C7.64992 18.4777 8.40826 18.761 9.33326 17.711L15.6416 10.5444C16.4166 9.66938 16.0916 8.94438 14.9166 8.94438H12.3416V2.94438C12.3416 1.54438 11.5833 1.26105 10.6583 2.31105L4.34992 9.47771C3.58326 10.361 3.90826 11.0777 5.07492 11.0777Z"
          stroke="#111320"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);
