import {ServerTimestamp} from '~/shared/utils/format/format-time';
import {apiClient} from './shared/api-client';
import {IAuthorizationResponse} from './user';

export type RewardName =
  | 'twitter'
  | 'discord'
  | 'telegram'
  | 'twitter-post'
  | 'stake'
  | string;

export enum UserRewardStatus {
  NEW = 0,
  IN_PROGRESS = 1,
  COMPLETED = 2,
}

export interface IReward {
  name: RewardName;
  amount: number;
  crystal_amount: number;
  crystal_multiplier: number;
  multiplier: number;
  image?: string;
  description?: string;
  link: string;
  id: number;
  type: string;
}

export interface IUserReward {
  user_id: number;
  reward_id: number;
  redeemed: boolean;
  redeemed_at: ServerTimestamp;
  status: UserRewardStatus;
  id: number;
}

export interface IRewardClaimData {
  reward_id: number;
}

function getAllRewards(token: IAuthorizationResponse) {
  return apiClient.get<IReward[]>('/rewards/', {
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });
}

function getUserRewards(token: IAuthorizationResponse) {
  return apiClient.get<IUserReward[]>('/user-rewards/', {
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });
}

function claimRewards(data: IRewardClaimData, token: IAuthorizationResponse) {
  return apiClient.post<IUserReward>(
    '/user-rewards/',
    {
      reward_id: data.reward_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    },
  );
}

export const RewardsApi = {
  getAllRewards,
  getUserRewards,
  claimRewards,
};
