import {EnvironmentVariables} from '~/app/env';
import {referralLink} from '~/entities/referral-link';
import {TWITTER_POSTS} from './posts';

function randomElement<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}

export function generateTweetLink(refCode: string) {
  const isReply = Math.random() < 0.5;
  const utm = isReply ? 'tr100' : 'tp100';
  let tweet = randomElement(TWITTER_POSTS.posts)
    .text.replace(
      '#REF_LINK',
      `JOIN MY CREW TO GET EXTRA 0.05 BONUS: ${referralLink(refCode, utm)}`,
    )
    .replace('#HASHTAGS', '#Roketo #LunarLoot #Airdrop');

  if (isReply) {
    return `https://x.com/intent/tweet?text=${encodeURIComponent(
      tweet,
    )}&in_reply_to=${EnvironmentVariables.REWARD_TWITTER_POST_ID}`;
  } else {
  }

  return `https://x.com/intent/post?text=${encodeURIComponent(tweet)}`;
}
