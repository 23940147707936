import React, {act, createContext, useContext, useEffect} from 'react';
import {NavigateOptions, useSearchParams} from 'react-router-dom';
import {logger} from '~/shared/debug';

interface IModalContext {
  modal: string | null;
  activeModals: string[];
  activate: (modal: string) => void;
  deactivate: (modal: string) => void;
  close: () => void;
  closeExact: (modal: string) => void;
  open: (modal: string, opts?: NavigateOptions) => void;
  isOpen: (modalName: string) => boolean;
}

const ModalContext = createContext<IModalContext>({
  modal: null,
  activeModals: [],
  activate: () => {},
  deactivate: () => {},
  close: () => {},
  open: () => {},
  isOpen: () => false,
  closeExact: () => {},
});

function useModalControls() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeModals, setActiveModals] = React.useState<string[]>([]);

  const activate = (modal: string) => {
    setActiveModals((modals) => {
      if (modals.includes(modal)) {
        return modals;
      }
      return [...modals, modal];
    });
  };

  const deactivate = (modal: string) => {
    setActiveModals((modals) => {
      return modals.filter((m) => m !== modal);
    });
  };

  const modal = searchParams.get('modal');
  const close = () => {
    setSearchParams(
      (params) => {
        params.delete('modal');
        return params;
      },
      {
        replace: true,
      },
    );
  };

  const closeExact = (modalName: string) => {
    setSearchParams(
      (params) => {
        const isCurrentModalOpen = params.get('modal') === modalName;

        logger.debug(
          '[Modal]: Close Exact',
          modalName,
          isCurrentModalOpen,
          params.toString(),
        );

        if (params.get('modal') === modalName) {
          params.delete('modal');
        }
        return params;
      },
      {
        replace: true,
      },
    );
  };

  const open = (modal: string, opts?: NavigateOptions) => {
    logger.debug('[Modal]: open', modal);
    setSearchParams((params) => {
      params.set('modal', modal);
      return params;
    }, opts);
  };

  const isOpen = (modalName: string) => {
    const res =
      searchParams.has('modal') &&
      searchParams.get('modal') === modalName &&
      activeModals.includes(modalName);
    return res;
  };

  return {
    modal,
    close,
    open,
    isOpen,
    closeExact,
    activate,
    deactivate,
    activeModals,
  };
}

export function ModalRoot({children}: {children: React.ReactNode}) {
  const value = useModalControls();
  const isModalActive =
    value.modal !== null && value.activeModals.includes(value.modal);

  useEffect(() => {
    if (isModalActive) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isModalActive]);

  return (
    <ModalProvider value={value}>
      <React.Fragment>
        <div id="modal-root"></div>
        <div
          css={{
            filter: isModalActive ? 'blur(7.5px)' : 'none',
          }}
        >
          {children}
        </div>
      </React.Fragment>
    </ModalProvider>
  );
}

export const ModalProvider = ({
  children,
  value,
}: {
  children: React.ReactNode;
  value: IModalContext;
}) => {
  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

export const useModals = () => {
  const context = useContext(ModalContext);

  return context;
};
