import {useAppTheme} from '../theme';
import {Typography} from '../typography';

export const ModalHeader = ({
  title,
  description,
  ...rest
}: {
  title: React.ReactNode;
  description: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>) => {
  const theme = useAppTheme();
  return (
    <div
      css={{
        textAlign: 'center',
      }}
      {...rest}
    >
      <Typography.h1
        css={{
          marginBottom: '0.75em',
        }}
      >
        {title}
      </Typography.h1>
      <Typography.body
        css={{
          color: theme.colors.onSurfaceTertiary,
        }}
      >
        {description}
      </Typography.body>
    </div>
  );
};
