import React from 'react';
import {useEffect} from 'react';
import {RewardValue} from '~/components/kit';
import {findOptimalSpeedPrecision, lunar} from '~/entities/lunar-loot';
import {toClientTimestamp} from '~/shared/utils/format/format-time';
import {DockBalanceCalculator} from '../../math';
import {useDockModel} from '~/entities/dock';

export function DockBalance(props: React.HTMLProps<HTMLDivElement>) {
  const dockModel = useDockModel();

  const {
    combined_lunar_loot_speed,
    last_claimed_time,
    dock_size: size,
  } = dockModel.dock;

  const [dockBalance, setDockBalance] = React.useState<number>(0);

  useEffect(() => {
    const cb = () => {
      const calculator = new DockBalanceCalculator({
        // transform to loot per MS
        combinedIdleMiningSpeed: combined_lunar_loot_speed / 1000,
        lastClaimedTime: toClientTimestamp(last_claimed_time),
        dockSize: toClientTimestamp(size),
      });

      setDockBalance(calculator.dockBalance);
      requestAnimationFrame(cb);
    };

    const frame = requestAnimationFrame(() => {
      cb();
    });

    return () => cancelAnimationFrame(frame);
  }, [combined_lunar_loot_speed, last_claimed_time, size]);

  return (
    <RewardValue
      {...props}
      value={lunar(dockBalance, {
        isSteady: true,
        precision: findOptimalSpeedPrecision(combined_lunar_loot_speed),
      })}
      rewardType="lunar"
      splitNumbers
    />
  );
}
