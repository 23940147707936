export const BetIcon = ({
  color,
  isRotated,
}: {
  color: string;
  isRotated: boolean;
}) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={{
        transform: isRotated ? 'rotate(180deg)' : '',
      }}
    >
      <path
        id="Vector 957"
        d="M4.66251 2.92665L1.39427 6.41278C0.296566 7.58366 1.12678 9.5 2.73176 9.5H9.26824C10.8732 9.5 11.7034 7.58366 10.6057 6.41277L7.33749 2.92665C6.61318 2.15406 5.38682 2.15406 4.66251 2.92665Z"
        fill={color}
        stroke="black"
      />
    </svg>
  );
};

export const BetTypeIcon = ({betType}: {betType: 'up' | 'down'}) => {
  const color = betType === 'up' ? '#00B754' : '#F3104B';

  return <BetIcon color={color} isRotated={betType === 'down'} />;
};

export const BetTypeIconAlt = ({betType}: {betType: 'up' | 'down'}) => {
  const color = betType === 'up' ? '#52FFA1' : '#FFA9BF';

  return <BetIcon color={color} isRotated={betType === 'down'} />;
};
