import {css} from '@emotion/react';
import {AestheticsBg} from './aesthetics-bg';
import React from 'react';
import {useAppTheme} from '../theme';

export const PageTitle = {};

export const titleCollapsed = css({
  fontWeight: 700,
  letterSpacing: '-0.03em',
  lineHeight: 1.3,
});

export function AestheticsLine({
  children,
  ...rest
}: React.HTMLProps<HTMLSpanElement>) {
  return (
    <span
      css={{
        position: 'relative',
      }}
      {...rest}
    >
      <AestheticsBg />
      {children}
    </span>
  );
}

const h6 = (props: React.HTMLProps<HTMLHeadingElement>) => {
  const theme = useAppTheme();

  return (
    <h6
      css={{
        fontSize: theme.fontSize.s6,
        fontWeight: 600,
      }}
      {...props}
    />
  );
};

const h7 = (props: React.HTMLProps<HTMLHeadingElement>) => {
  const theme = useAppTheme();

  return (
    <h6
      css={{
        fontSize: theme.fontSize.s7,
        fontWeight: 600,
      }}
      {...props}
    />
  );
};

const h8 = (props: React.HTMLProps<HTMLHeadingElement>) => {
  const theme = useAppTheme();

  return (
    <h6
      css={{
        fontSize: theme.fontSize.odds.s10,
        fontWeight: 600,
      }}
      {...props}
    />
  );
};
const h3 = (props: React.HTMLProps<HTMLHeadingElement>) => {
  const theme = useAppTheme();

  return (
    <h3
      css={{
        fontSize: theme.fontSize.s3,
        fontWeight: 700,
      }}
      {...props}
    />
  );
};

const h4 = (props: React.HTMLProps<HTMLHeadingElement>) => {
  const theme = useAppTheme();

  return (
    <h4
      css={{
        fontSize: theme.fontSize.s4,
        fontWeight: 700,
      }}
      {...props}
    />
  );
};

const h1 = (props: React.HTMLProps<HTMLHeadingElement>) => {
  const theme = useAppTheme();

  return (
    <h1
      css={{
        fontSize: theme.fontSize.s1,
        fontWeight: 800,
      }}
      {...props}
    />
  );
};

const body = (props: React.HTMLProps<HTMLHeadingElement>) => {
  const theme = useAppTheme();

  return (
    <p
      css={{
        fontSize: theme.fontSize.s6,
        fontWeight: 500,
        lineHeight: 1.35,
      }}
      {...props}
    />
  );
};

export const Typography = {
  h8,
  h7,
  h6,
  h3,
  h4,
  h1,
  body,
};
