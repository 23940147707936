import {floatToLunar} from '~/entities/lunar-loot';

const _BlackHoleRanks = [
  {
    name: 'Lunar Kid',
    size: 0,
    color: '#FFFFFF',
    'text-color': '#000000',
  },
  {
    name: 'Quantum Flicker',
    size: 0.5,
    color: '#FFC165',
    'text-color': '#000000',
  },
  {name: 'Cosmic Speck', size: 2, color: '#FFE665', 'text-color': '#000000'},
  {name: 'Void Whisper', size: 7.77, color: '#E0FF65', 'text-color': '#000000'},
  {
    name: 'Stellar Nibbler',
    size: 10,
    color: '#A9FF65',
    'text-color': '#000000',
  },
  {
    name: 'Nebula Muncher',
    size: 25,
    color: '#65FF6B',
    'text-color': '#000000',
  },
  {name: 'Gravity Well', size: 50.5, color: '#65FFAC', 'text-color': '#000000'},
  {
    name: 'Star Swallower',
    size: 111,
    color: '#65FFED',
    'text-color': '#000000',
  },
  {
    name: 'Galaxy Guzzler',
    size: 222,
    color: '#65C8FF',
    'text-color': '#000000',
  },
  {
    name: 'Quasar Quaffer',
    size: 420,
    color: '#8EAEFF',
    'text-color': '#000000',
  },
  {name: 'Supermassive', size: 1337, color: '#B0A3FF', 'text-color': '#000000'},
  {name: 'Time Warper', size: 3000, color: '#D7A3FF', 'text-color': '#000000'},
  {
    name: 'Dimension Eater',
    size: 10000,
    color: '#FFA3E0',
    'text-color': '#000000',
  },
  {
    name: 'Universe Gobbler',
    size: 40404,
    color: '#FFA3A3',
    'text-color': '#000000',
  },
  {
    name: 'Multiverse Maw',
    size: 80808,
    color: 'linear-gradient(90deg, #E0FF65 0%, #5FFFE2 50%, #9C83FF 100%)',
    'text-color': '#000000',
  },
  {
    name: 'Reality Rift',
    size: 177777,
    color: 'linear-gradient(90deg, #FFA3A3 0%, #FFA3E5 46.5%, #D7A3FF 100%)',
    'text-color': '#000000',
  },
  {
    name: 'Cosmic Colossus',
    size: 327680,
    color: 'linear-gradient(90deg, #FF6565 0%, #FFB648 48.5%, #FCFF57 100%)',
    'text-color': '#000000',
  },
  {
    name: 'Omniverse Void',
    size: 655360,
    color: '#FFC165',
    'text-color': '#000000',
  },
  {
    name: 'Singularity Supreme',
    size: 1310720,
    color: '#111320',
    'text-color': '#FFFFFF',
  },
];

export type BlackHoleRank = (typeof _BlackHoleRanks)[number];

const BlackHoleRanks = _BlackHoleRanks.map((b, i) => ({
  ...b,
  size: floatToLunar(b.size + combineSizesBeforeIndex(i)),
}));

export function combineSizesBeforeIndex(index: number): number {
  return _BlackHoleRanks
    .slice(0, index)
    .reduce((acc, curr) => acc + curr.size, 0);
}

export function getBlackHoleRank(size: number) {
  const index = BlackHoleRanks.findIndex((hole) => size < hole.size);

  const currentBlackHole = BlackHoleRanks[index - 1];

  return currentBlackHole || BlackHoleRanks[BlackHoleRanks.length - 1];
}

export function getPreviousBlackHoleRank(size: number) {
  const rank = getBlackHoleRank(size);
  const index = BlackHoleRanks.indexOf(rank);

  return (
    BlackHoleRanks[index - 1] || {
      name: 'Empty',
      size: 0,
      color: '#000000',
      'text-color': '#FFFFFF',
    }
  );
}

export function getNextBlackHoleRank(size: number) {
  const rank = getBlackHoleRank(size);
  const index = BlackHoleRanks.indexOf(rank);

  return (
    BlackHoleRanks[index + 1] || {
      name: 'The Static Noise',
      size: Infinity,
      color: '#000000',
      'text-color': '#FFFFFF',
    }
  );
}

export function isLastBlackHoleRank(size: number) {
  return size >= BlackHoleRanks[BlackHoleRanks.length - 1].size;
}
