import {
  Modal,
  ModalSteps,
  ModalStepsControlButton,
  useModalSteps,
  useModals,
} from '~/components/kit/modal';
import {Typography} from '~/components/kit/typography';
import {saveNewsNotifications} from '~/entities/news-notifications';
import friendsUpdateBgPng from './assets/friends-update-bg.png';
import {rem} from '~/shared/utils/style-helpers';
import {ImgTransition} from '~/components/kit/img-transition';
import {useAppTheme} from '~/components/kit';
import {Markdown} from '~/components/kit/markdown';
import React from 'react';
import {ScrollableContent} from '../ui/scrollable-content';
import step2ImgPng from './assets/step-2-news.png';
import step3ImgPng from './assets/step-3-news.png';
import {AspectRatio} from '~/components/kit/aspect-ratio';

export const FRIENDS_UPDATE_NOTIFICATION_MODAL_NAME =
  'friends-update-notification';

function ContentContainer({children}: {children: React.ReactNode}) {
  return (
    <ScrollableContent
      css={{
        minHeight: rem(480),
        userSelect: 'none',
      }}
    >
      {children}
    </ScrollableContent>
  );
}
function Step1() {
  const theme = useAppTheme();
  return (
    <ContentContainer>
      <div
        css={{
          height: rem(240),
          width: rem(240),
          marginLeft: 'auto',
          marginRight: 'auto',
          position: 'relative',
          color: theme.colors.onSurfaceAlternative,
          textAlign: 'center',
        }}
      >
        <Typography.h3>Friends Update is Live Now!</Typography.h3>
      </div>

      <Markdown>
        <p>
          In space, no one should explore alone. That’s why we added new feature
          - the ability to invite and add your friends to join your Lunar Coin
          Adventure!
        </p>
        <p>
          In this short briefing, we’ll guide you through how to use this
          feature and why having friends can be really fun (irl too though)
        </p>
      </Markdown>
    </ContentContainer>
  );
}

function Step2() {
  const theme = useAppTheme();
  return (
    <ContentContainer>
      <div
        css={{
          width: rem(240),
          marginLeft: 'auto',
          marginRight: 'auto',
          position: 'relative',
          textAlign: 'center',
        }}
      >
        <Typography.h3>Check Out New Friends Screen</Typography.h3>
      </div>
      <AspectRatio
        ratio={114 / 285}
        css={{
          marginTop: rem(34),
          marginBottom: rem(30),
        }}
      >
        <ImgTransition
          src={step2ImgPng}
          css={{
            objectFit: 'contain',
          }}
        />
      </AspectRatio>
      <Markdown>
        <p>
          In the top right corner of the Mining Screen, you’ll now find the
          Friends Icon. Tap on it to open up new features.
        </p>
        <p>In the Friends Screen you can:</p>
        <em>
          <ul>
            <li>Invite your first Friend to join your in your Adventure</li>
            <li>View entire Friends List</li>
            <li>Remove Friends (btw why?)</li>
          </ul>
        </em>
      </Markdown>
    </ContentContainer>
  );
}

function Step3() {
  const theme = useAppTheme();
  return (
    <ContentContainer>
      <div
        css={{
          width: rem(240),
          marginLeft: 'auto',
          marginRight: 'auto',
          position: 'relative',
          textAlign: 'center',
        }}
      >
        <Typography.h3>Gather Your Crew Now!</Typography.h3>
      </div>
      <AspectRatio
        ratio={338 / 591}
        css={{
          marginTop: rem(10),
          marginBottom: rem(5),
        }}
      >
        <ImgTransition
          src={step3ImgPng}
          css={{
            objectFit: 'contain',
          }}
        />
      </AspectRatio>

      <Markdown>
        <p>
          It’s all about the Competition! With Friends, you can now see their
          Stats in the Leaderboard, where every single Astronaut is ranked.
        </p>
        <p>
          We’ve got tons of exciting friend activities in the works,{' '}
          <em>
            so start gathering your Crew now to be ready for what’s coming next!
          </em>
        </p>
      </Markdown>
    </ContentContainer>
  );
}

export function FriendsUpdateNotification({
  onFinalize,
}: {
  onFinalize: () => void;
}) {
  const modals = useModals();

  const theme = useAppTheme();

  const steps = useModalSteps({
    steps: [Step1, Step2, Step3],
  });

  return (
    <Modal
      name={FRIENDS_UPDATE_NOTIFICATION_MODAL_NAME}
      isClosable={true}
      afterClose={() => {
        saveNewsNotifications({
          friendsUpdates: true,
        });
        onFinalize();
      }}
      backgroundColor="#FF9FC3"
      bgImage={
        steps.step === 0 ? (
          <ImgTransition
            src={friendsUpdateBgPng}
            css={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              width: '100%',
              maxHeight: rem(240),
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        ) : null
      }
      controlButton={
        <ModalStepsControlButton
          steps={steps}
          afterLastStep={() => {
            modals.closeExact(FRIENDS_UPDATE_NOTIFICATION_MODAL_NAME);
          }}
        />
      }
    >
      <div
        css={{
          overflow: 'hidden',
        }}
        {...steps.bindDrag()}
      >
        <steps.Step />

        <ModalSteps steps={steps.length} activeStep={steps.step} />
      </div>
    </Modal>
  );
}
