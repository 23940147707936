import {AxiosResponse} from 'axios';
import {IDockInfoResponse} from '~/api/dock';
import {makeAxiosResponse} from '~/api/shared/api-client';
import {ServerTimestamp} from '~/shared/utils/format/format-time';

export function dockInitialData(): AxiosResponse<IDockInfoResponse> {
  return makeAxiosResponse({
    id: 1,
    balance: 0,
    crystal_balance: 0,
    last_claimed_time: 0 as ServerTimestamp,
    last_yield_time: 0 as ServerTimestamp,
    lunar_loot_speed: 0,
    combined_lunar_loot_speed: 0,
    dock_size: 0 as ServerTimestamp,
    yield_percentage: '0',
    lunar_loot_speed_lvl: 0,
    dock_size_lvl: 0,
    yield_percentage_lvl: 0,
    combined_speed: 0,
    min_claim_time: 0 as ServerTimestamp,
    yield_period: 0 as ServerTimestamp,
    referral_bonus: 0,
    boost_multiplier: 0,
    has_boost: true,
    rank: 1,
    black_hole_balance: 0,
    airdrop_balance: 0,
    has_early_adopters_boost: false,
    early_adopters_boost_expires_at: 0 as ServerTimestamp,
    top_percent: 0,
  } as IDockInfoResponse);
}
