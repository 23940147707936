export const DailyCheckedPath = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    height="19"
    width="335"
    fill="none"
    viewBox="0 0 335 19"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    {...props}
  >
    <path
      d="M-34 1.75575C-26.8376 13.9265 -10.8614 18.762 6.92807 17.4617C21.9389 16.3645 24.6745 2.96585 39.6705 1.75575C57.7989 0.292901 63.3937 17.3952 81.6218 17.4617C100.03 17.529 105.677 1.75575 124.085 1.75575C142.493 1.75575 148.14 17.3958 166.548 17.4617C185.136 17.5283 190.934 1.75575 209.522 1.75575C228.11 1.75575 233.908 17.5283 252.497 17.4617C270.904 17.3958 276.551 1.75575 294.959 1.75575C313.367 1.75575 319.059 18.4507 337.422 17.4617C355.785 16.4727 356.449 1.75575 372.211 1.75575C387.973 1.75575 394.722 6.85886 407 17.4617"
      stroke="currentColor"
      strokeDasharray="7 7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);
