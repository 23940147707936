import {useAppTheme} from '~/components/kit';
import {useModals} from '~/components/kit/modal';
import {PageLayout} from '~/components/layouts/page-layout';
import {TraiblazerGameModal} from '~/features/trailblazer/ui/trailblazer-game';
import {
  TrailblazerCardType,
  TrailblazerOpenCard,
} from '~/features/trailblazer/ui/trailblazer-open-card';
import {TrailblazerHistory} from '~/features/trailblazer/ui/trailblazer-history';
import {rem} from '~/shared/utils/style-helpers';
import {useTrailblazerModel} from '~/features/trailblazer/model';

export function TrailblazerPage() {
  const modals = useModals();
  const theme = useAppTheme();

  const model = useTrailblazerModel();

  return (
    <PageLayout>
      <TraiblazerGameModal />
      <TrailblazerOpenCard
        type={
          model.guessHistory.data.length === 0
            ? TrailblazerCardType.full
            : TrailblazerCardType.short
        }
      />

      <TrailblazerHistory
        css={{
          marginTop: rem(16),
        }}
      />
    </PageLayout>
  );
}
