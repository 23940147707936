import {RewardValue, useAppTheme} from '~/components/kit';
import {LightningKchau} from '~/components/kit/lightning-kchau';
import {rem} from '~/shared/utils/style-helpers';
import {makeArray} from '~/shared/utils/arrays';

import {Trans, useTranslation} from 'react-i18next';
import {Button} from '~/components/kit/button/button';
import {Bonus} from '~/components/kit/bonus/bonus';
import {lunar} from '~/entities/lunar-loot';
import {Modal, ModalTitle} from '~/components/kit/modal/modal';
import {UpgradeableAsset} from '../animated-asset';
import {ArrowRightIcon} from '~/components/kit/icons/arrow-right-icon';
import {useUpgradeModel} from '~/features/upgrades/upgrades';
import {UpgradeType} from '~/entities/upgrades';
import {formatNumber} from '~/shared/utils/format/format-numbers';
import {useTrackOpenUpgradeModal} from '~/features/upgrades/analytics';
import {useModals} from '~/components/kit/modal';
import React from 'react';
import {UpgradeValue} from './upgrade-value';
import {useUpgradeUiModel} from './model';

function UpgradeModalActionMaxLvl({type}: {type: UpgradeType}) {
  const theme = useAppTheme();
  const model = useUpgradeModel(type);
  const {texts} = useUpgradeUiModel(type);

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        css={{
          marginBottom: rem(30),
        }}
      >
        <div
          css={{
            color: theme.colors.onSurfaceSecondary,
            fontSize: theme.fontSize.s6,
            marginBottom: rem(12),
          }}
        >
          {texts.upgradeProperty}:
        </div>

        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
            fontWeight: 800,
            fontSize: theme.fontSize.s3,
            whiteSpace: 'nowrap',
            opacity: model.isReady ? '1' : '0',
            transition: 'opacity 0.3s',
          }}
        >
          <UpgradeValue value={model.upgrade.currentValue} type={type} />
        </div>
      </div>

      <Button color="pink" isLoading={true}>
        Max Lvl
      </Button>
    </div>
  );
}

const UpgradeModalAction = ({type}: {type: UpgradeType}) => {
  const theme = useAppTheme();
  const model = useUpgradeModel(type);

  const {t} = useTranslation();

  const {texts, hasEnoughBalance, needToEarn} = useUpgradeUiModel(type);

  const isReady = model.isReady;
  const isMaxLvl = model.isMaxLvl;

  const isLoading = model.buyMutation.isPending;

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        css={{
          marginBottom: rem(30),
        }}
      >
        <div
          css={{
            color: theme.colors.onSurfaceSecondary,
            fontSize: theme.fontSize.s6,
            marginBottom: rem(12),
          }}
        >
          {texts.upgradeProperty}:
        </div>

        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
            fontWeight: 800,
            fontSize: theme.fontSize.s3,
            whiteSpace: 'nowrap',
            opacity: isReady ? '1' : '0',
            transition: 'opacity 0.3s',
          }}
        >
          {!isMaxLvl ? (
            <>
              <UpgradeValue value={model.upgrade.currentValue} type={type} />
              <ArrowRightIcon
                css={{
                  marginLeft: rem(15),
                  marginRight: rem(15),
                  width: rem(16),
                  flexShrink: 0,
                }}
              />
              <UpgradeValue value={model.upgrade.nextValue} type={type} />
            </>
          ) : (
            <UpgradeValue value={model.upgrade.currentValue} type={type} />
          )}
        </div>
      </div>

      <Button
        color="pink"
        isLoading={isLoading || isMaxLvl}
        isDisabled={isLoading || !hasEnoughBalance || !isReady}
        loadingContent={isMaxLvl ? 'Max Lvl' : 'Upgrading...'}
        onClick={() => {
          model.buyMutation.mutate();
        }}
      >
        {isReady ? (
          <>
            {t('upgrades.upgradeButton')}
            <Bonus
              color="alternative"
              css={{
                marginLeft: rem(4),
                padding: `${rem(4)} ${rem(8)}`,
                minWidth: rem(60),
              }}
            >
              <RewardValue
                value={lunar(model.upgrade.cost)}
                css={{
                  img: {
                    marginLeft: rem(6),
                  },
                }}
              ></RewardValue>
            </Bonus>
          </>
        ) : (
          <span>Fetching cosmic data...</span>
        )}
      </Button>
      {!isMaxLvl && !hasEnoughBalance && (
        <div
          css={{
            color: theme.colors.onSurfaceSecondary,
            fontSize: theme.fontSize.s6,
            fontWeight: 500,
            marginTop: rem(10),
            opacity: isReady ? '1' : '0',
            transition: 'opacity 0.3s',
          }}
        >
          <Trans
            t={t}
            i18nKey={'upgrades.notEnoughBalance'}
            components={{
              missingSum: (
                <RewardValue
                  value={formatNumber(lunar(needToEarn))}
                  css={{
                    color: theme.colors.onSurface,
                  }}
                ></RewardValue>
              ),
            }}
          ></Trans>
        </div>
      )}
    </div>
  );
};

function UpgradeModalInner({name, type}: {name: string; type: UpgradeType}) {
  const theme = useAppTheme();
  const modals = useModals();
  const {texts, hasEnoughBalance} = useUpgradeUiModel(type);
  const model = useUpgradeModel(type);
  const colorSet = theme.colors.components.upgrade[type];

  useTrackOpenUpgradeModal(
    {
      upgradeType: type,
      currentLvl: model.upgrade.lvl,
      upgradeButtonStatus: hasEnoughBalance,
    },
    model.isReady && modals.isOpen(name),
  );

  const isMaxLvl = model.isMaxLvl;

  return (
    <React.Fragment>
      <ModalTitle
        css={{
          marginBottom: rem(14),
        }}
      >
        {texts.title}
      </ModalTitle>
      <p
        css={{
          marginBottom: rem(30),
          fontSize: theme.fontSize.s6,
          lineHeight: 1.35,
          color: theme.colors.onSurfaceSecondary,
          fontWeight: 500,
        }}
      >
        {texts.description}
      </p>

      <LightningKchau
        mainColor={colorSet.assetBg}
        raysColor={makeArray(4, colorSet.assetBg, colorSet.assetBgAdjacent)}
        css={{
          justifyContent: 'center',
          marginBottom: rem(30),
        }}
      >
        <UpgradeableAsset
          type={type}
          lvl={model.upgrade.lvl}
          css={{
            marginLeft: rem(30),
            marginRight: rem(30),
          }}
        ></UpgradeableAsset>
      </LightningKchau>

      {isMaxLvl ? (
        <UpgradeModalActionMaxLvl type={type}></UpgradeModalActionMaxLvl>
      ) : (
        <UpgradeModalAction type={type}></UpgradeModalAction>
      )}
    </React.Fragment>
  );
}

export function UpgradeModal({name, type}: {type: UpgradeType; name: string}) {
  const modals = useModals();

  return (
    <Modal
      name={name}
      css={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        maxWidth: '100%',
      }}
    >
      {modals.isOpen(name) ? (
        <UpgradeModalInner name={name} type={type}></UpgradeModalInner>
      ) : null}
    </Modal>
  );
}
