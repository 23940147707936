export const CrossIcon = () => (
  <svg
    height="12"
    width="12"
    fill="none"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      height="10.5"
      width="10.5"
      fill="#FF5481"
      rx="5.25"
      x="0.744141"
      y="0.75"
    />
    <rect
      height="10.5"
      width="10.5"
      rx="5.25"
      stroke="black"
      strokeWidth="0.875"
      x="0.744141"
      y="0.75"
    />
    <g filter="url(#filter0_d_1744_53792)">
      <path
        d="M7.47516 4.60928L5.9951 6.08933M5.9951 6.08933L4.69246 7.39197M5.9951 6.08933L4.51318 4.60742M5.9951 6.08933L7.29589 7.39011"
        stroke="black"
        strokeWidth="1.17944"
      />
    </g>
    <defs>
      <filter
        height="4.10853"
        id="filter0_d_1744_53792"
        width="4.28597"
        x="4.09619"
        y="4.19043"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood result="BackgroundImageFix" floodOpacity="0" />
        <feBlend
          result="effect1_dropShadow_1744_53792"
          in2="BackgroundImageFix"
        />
        <feBlend
          result="shape"
          in="SourceGraphic"
          in2="effect1_dropShadow_1744_53792"
        />
      </filter>
    </defs>
  </svg>
);
