import * as Sentry from '@sentry/react';
import axios, {
  AxiosHeaders,
  AxiosRequestConfig,
  AxiosResponse,
  isAxiosError,
} from 'axios';
import {EnvironmentVariables} from '~/app/env';
import {logger} from '~/shared/debug';

export const apiClient = axios.create({
  baseURL: EnvironmentVariables.API_BASE_URL,
});

interface ConfigWithMetadata extends AxiosRequestConfig {
  metadata?: {
    span?: Sentry.Span;
  };
}

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const IS_SLOW = false;

apiClient.interceptors.request.use(async (config) => {
  const fullPath = (config.baseURL || '') + config.url;
  const span = Sentry.startInactiveSpan({
    op: 'http.client',

    name: `${config.method?.toUpperCase()} ${fullPath}`,
    forceTransaction: true,

    attributes: {
      'http.request_method': config.method?.toUpperCase(),
      'http.url': fullPath,
      description: `${config.method?.toUpperCase()} ${fullPath}`,
      'server.address': new URL(config.baseURL || '').hostname,
      type: 'xhr',
    },
  });

  if (IS_SLOW && EnvironmentVariables.isDevelopment) {
    await delay(2000);
  }

  logger.debug('API Request', fullPath, config.method);

  (config as ConfigWithMetadata)['metadata'] = {
    span,
  };

  return config;
});

apiClient.interceptors.response.use(
  (response) => {
    const config = response.config as ConfigWithMetadata;
    if (config.metadata && config.metadata.span) {
      config.metadata.span.setAttribute(
        'http.response.status_code',
        response.status,
      );

      config.metadata.span.setStatus({
        code: 1,
      });

      config.metadata.span.end();
    }

    return response;
  },
  (error) => {
    const config = error.config as ConfigWithMetadata;
    if (config.metadata && config.metadata.span) {
      if (isAxiosError(error) && error.response) {
        config.metadata.span.setAttribute(
          'http.response.status_code',
          error.response.status,
        );
      }
      config.metadata.span.setStatus({
        code: 2,
      });

      config.metadata.span.end();
    }
    return Promise.reject(error);
  },
);

export const makeAxiosResponse = <T>(data: T): AxiosResponse<T> => {
  return {
    data,
    status: 200,
    statusText: 'OK',
    headers: {},
    config: {
      headers: new AxiosHeaders(),
    },
  };
};

export const getErrorMessage = <T extends object>(
  response: AxiosResponse<T>,
) => {
  if ('detail' in response.data) {
    return new Error(response.data.detail as string);
  }

  return new Error('Unknown error');
};
