import {useEarnModel} from '~/entities/earn';
import {EarnConnectedWallet} from './earn-connected-page';
import {EarnDisconnectedWallet} from './earn-disconnected-page';

export function EarnPage() {
  const earnModel = useEarnModel();

  if (earnModel.isWalletConnected) {
    return <EarnConnectedWallet />;
  }

  return <EarnDisconnectedWallet />;
}

export default EarnPage;
