import {Modal} from '~/components/kit/modal';
import {WalletConnectContent} from './wallet-connect-content';

export const WALLET_CONNECT_MODAL_NAME = 'connect-wallet-modal';

export function WalletConnectModal() {
  return (
    <Modal name={WALLET_CONNECT_MODAL_NAME}>
      <WalletConnectContent />
    </Modal>
  );
}
