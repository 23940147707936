import React from 'react';
import {useAppTheme} from '~/components/kit/theme';
import {ModalTitle} from '~/components/kit/modal';
import {ImgTransition} from '~/components/kit/img-transition';
import {AspectRatio} from '~/components/kit/aspect-ratio';
import {Markdown} from '~/components/kit/markdown';
import {Squircle} from '@squircle-js/react';
import howToBanner4 from '../assets/howto-banner-4.png';

export const Step4 = () => {
  const theme = useAppTheme();

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <ModalTitle
        css={{
          color: theme.colors.onSurfaceAlternative,
          paddingLeft: 0,
          paddingRight: 0,
          textShadow: `0px 0px 10px #0C0748`,
        }}
      >
        New Day
        <br />
        New Challenge
      </ModalTitle>
      <div css={{flexGrow: 1}} />
      <Markdown
        css={{
          fontSize: theme.fontSize.s5,
          textShadow: `0px 0px 10px #FFFFFF`,
        }}
      >
        Every day brings a brand new planetary puzzle, so keep those space
        goggles sharp. Now, strap in and start aligning those planets. Good
        Luck, Astronaut!
      </Markdown>
    </div>
  );
};

export const backgroundColor = '#9BB3DD';

export const bgImage = () => (
  <AspectRatio ratio={(972 - 70) / 670}>
    <div
      css={{
        position: 'absolute',
        top: '70%',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        objectFit: 'cover',
        opacity: 1,
        zIndex: 1,
        background: `linear-gradient(180deg, transparent, ${backgroundColor} 100%)`,
      }}
    ></div>
    <ImgTransition
      src={howToBanner4}
      css={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'bottom center',
      }}
    />
  </AspectRatio>
);
