import {
  Bounce,
  ToastContainer as _ToastContainer,
  toast as _toast,
} from 'react-toastify';
import './toasts.css';
import React from 'react';
import {Icon} from '~/components/kit/icons';
import {PlainCrossIcon} from '~/components/kit/icons/plain-cross-icon';
import {useAppTheme} from '~/components/kit';
import {Typography} from '~/components/kit/typography';

export function ToastContainer() {
  return (
    <_ToastContainer
      draggable={true}
      limit={1}
      hideProgressBar={true}
      autoClose={4000}
      closeOnClick={true}
      transition={Bounce}
      closeButton={({closeToast}) => {
        return (
          <button
            onClick={(e) => {
              closeToast(e);
            }}
            css={{
              marginLeft: 'auto',
              color: 'rgba(255, 255, 255, 0.50)',
            }}
          >
            <Icon size={16}>
              <PlainCrossIcon />
            </Icon>
          </button>
        );
      }}
    />
  );
}

export const ToastContent = ({
  title,
  text,
}: {
  title: React.ReactNode;
  text?: React.ReactNode;
}) => {
  const theme = useAppTheme();

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Typography.h7
        css={{
          fontSize: theme.fontSize.s5,
        }}
      >
        {title}
      </Typography.h7>
      {text && (
        <Typography.body
          css={{
            fontSize: theme.fontSize.s6,
            color: theme.colors.onSurfaceAlternativeSecondary,
            marginTop: '0.25em',
          }}
        >
          {text}
        </Typography.body>
      )}
    </div>
  );
};
export const toast = {
  notify: (content: React.ReactNode) => {
    _toast(
      <div
        css={{
          display: 'flex',
        }}
      >
        <div>{content}</div>
      </div>,
      {
        delay: 200,
      },
    );
  },
};
