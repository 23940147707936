import React from 'react';
import {useAppTheme} from '~/components/kit/theme';
import {ModalTitle} from '~/components/kit/modal';
import {rem} from '~/shared/utils/style-helpers';
import {ImgTransition} from '~/components/kit/img-transition';
import {AspectRatio} from '~/components/kit/aspect-ratio';
import {Markdown} from '~/components/kit/markdown';
import howToBanner2 from '../assets/howto-banner-2.png';
import howToBanner2TopSecret from '../assets/howto-banner-2-topsecret.png';

export const Step2 = () => {
  const theme = useAppTheme();

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <ModalTitle
        css={{
          color: theme.colors.onSurfaceAlternative,
          paddingLeft: 0,
          paddingRight: 0,
          textShadow: `0px 0px 10px #0C0748`,
        }}
      >
        Main Reward is REALLY AWESOME
      </ModalTitle>
      <div css={{flexGrow: 1}} />
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '199px',
          height: '57px',
          background: theme.colors.surfaceAlternative,
          clipPath:
            'polygon(0% 20.18%, 100% 0%, 96% 100%, 2% 98.25%, 0% 20.18%)',
          paddingTop: rem(6),
          marginBottom: rem(15),
        }}
      >
        <ImgTransition src={howToBanner2TopSecret} />
      </div>
      <Markdown
        css={{
          color: theme.colors.onSurfaceAlternative,
          fontSize: theme.fontSize.s5,
        }}
      >
        Guess the entire sequence and unlock the SUPER-MEGA prize that's so
        amazing, it might just blow your space boots off!
      </Markdown>
    </div>
  );
};

export const backgroundColor = '#2D1B5A';

export const bgImage = () => (
  <AspectRatio ratio={(893 - 20) / 670}>
    <div
      css={{
        position: 'absolute',
        top: '60%',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        objectFit: 'cover',
        opacity: 1,
        zIndex: 1,
        background: `linear-gradient(180deg, transparent, ${backgroundColor} 100%)`,
      }}
    ></div>
    <ImgTransition
      src={howToBanner2}
      css={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'bottom center',
      }}
    />
  </AspectRatio>
);
