import {computed, effect} from '@preact/signals-react';
import {userData} from '~/entities/user';
import {
  AIRDROP_DISTRIBUTION_DATE,
  isTestingEnvironment,
} from '~/shared/feature-flags';
import isFeatureEnabled, {getTimeWhenEnabled} from '~/shared/gradual-releaase';

const ONE_HOUR = 60 * 60 * 1000;
const RELEASE_START = new Date(
  AIRDROP_DISTRIBUTION_DATE.getTime() +
    ONE_HOUR * (isTestingEnvironment() ? 1 : 14),
);

const RELEASE_END = new Date(
  AIRDROP_DISTRIBUTION_DATE.getTime() +
    ONE_HOUR * (isTestingEnvironment() ? 2 : 24),
);

export const isAirdropReleasedForUser = computed(() => {
  const telegramId = userData.value?.telegram_id;

  if (!telegramId) {
    return false;
  }

  return isFeatureEnabled(telegramId, RELEASE_START, RELEASE_END);
});

export const airdropReleaseTime = computed(() => {
  const telegramId = userData.value?.telegram_id;

  if (!telegramId) {
    return -1;
  }

  const readyAt = getTimeWhenEnabled(telegramId, RELEASE_START, RELEASE_END);

  // console.debug(
  //   "Calculating 'isAirdropReleasedForUser' at",
  //   telegramId,
  //   new Date(AIRDROP_DISTRIBUTION_DATE.getTime() + ONE_HOUR * 14),
  //   new Date(AIRDROP_DISTRIBUTION_DATE.getTime() + ONE_HOUR * 24),
  //   new Date(readyAt),
  // );
  return readyAt;
});
