import {EnvironmentVariables} from '~/app/env';

function debugLog(...args: any[]) {
  if (EnvironmentVariables.isDevelopment) {
    console.debug(...args);
  }
}

export const logger = {
  debug: debugLog,
};
