import {useAppTheme} from '~/components/kit';
import {Button} from '~/components/kit/button/button';
import {Icon} from '~/components/kit/icons';
import {PlainCheckIcon} from '~/components/kit/icons/plain-check-icon';
import {PlainCrossIcon} from '~/components/kit/icons/plain-cross-icon';
import {useModals, Modal} from '~/components/kit/modal';
import {ModalHeader} from '~/components/kit/modal/header';
import {
  ToastContent,
  ToastIconSomethingWentWrong,
  ToastIconFriendAdded,
  toast,
} from '~/features/toasts';
import {rem} from '~/shared/utils/style-helpers';
import {useFriendRequestModel} from '../../model';
import {FriendCard} from '../../ui/friend-card';
import {useLaunchParams} from '@telegram-apps/sdk-react';
import {parseStartParam} from '~/shared/start-params';
import {useNavigate} from 'react-router-dom';
import {RoutePath} from '~/app/navigation/routes';
import {isAxiosError} from 'axios';
import {trackAddFriend} from '../../analytics';

export const ADD_FRIEND_MODAL_NAME = 'add-friend-modal';
export function AddFriendModal({afterClose}: {afterClose?: () => void}) {
  const theme = useAppTheme();
  const modals = useModals();

  const launchParams = useLaunchParams();
  const params = parseStartParam(launchParams.startParam || '');

  const friendCode = params.friendCode || '';

  const requestModel = useFriendRequestModel({
    friendCode: params.friendCode || '',
  });

  const friendInfo = requestModel.isReady && requestModel.friendInfo.data;
  const navigate = useNavigate();

  return (
    <Modal
      name={ADD_FRIEND_MODAL_NAME}
      isClosable={true}
      afterClose={afterClose}
    >
      <ModalHeader
        title={'Friendship request!'}
        description="By accepting, you will unlock access to each other's stats and be able to compete."
      ></ModalHeader>
      {friendInfo ? (
        <FriendCard
          friend={friendInfo}
          css={{
            marginTop: rem(24),
            marginBottom: rem(36),
            width: rem(240),
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        ></FriendCard>
      ) : (
        <FriendCard
          friend={{
            first_name: '',
            last_name: '',
          }}
          css={{
            marginTop: rem(24),
            marginBottom: rem(36),
            width: rem(240),
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        ></FriendCard>
      )}

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: rem(8),
        }}
      >
        <Button
          color="pink"
          isLoading={requestModel.addMutation.isPending}
          isDisabled={requestModel.addMutation.isPending}
          onClick={async () => {
            try {
              await requestModel.addMutation.mutateAsync(friendCode);

              trackAddFriend();

              toast.notify(
                <div
                  css={{
                    display: 'flex',
                    gap: rem(8),
                  }}
                >
                  <Icon size={40}>
                    <ToastIconFriendAdded></ToastIconFriendAdded>
                  </Icon>

                  <ToastContent
                    title={'Friend added successfully!'}
                  ></ToastContent>
                </div>,
              );

              navigate(RoutePath.Friends);

              setTimeout(() => {
                modals.closeExact(ADD_FRIEND_MODAL_NAME);
              }, 0);
            } catch (error) {
              if (isAxiosError(error) && error.response?.status === 400) {
                setTimeout(() => {
                  modals.closeExact(ADD_FRIEND_MODAL_NAME);
                }, 0);
              } else {
                toast.notify(
                  <div
                    css={{
                      display: 'flex',
                      gap: rem(8),
                    }}
                  >
                    <Icon size={40}>
                      <ToastIconSomethingWentWrong></ToastIconSomethingWentWrong>
                    </Icon>

                    <ToastContent
                      title={'Something went wrong...'}
                      text={'Please try again'}
                    ></ToastContent>
                  </div>,
                );
              }
            }
          }}
        >
          <Icon
            size={25}
            css={{
              marginRight: rem(8),
            }}
          >
            <PlainCheckIcon />
          </Icon>
          Accept
        </Button>
        <Button color="ghost" onClick={() => modals.close()}>
          <Icon
            size={15}
            css={{
              marginRight: rem(8),
            }}
          >
            <PlainCrossIcon />
          </Icon>
          Decline
        </Button>
      </div>
    </Modal>
  );
}
