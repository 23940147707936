import {formatNumber} from '~/shared/utils/format/format-numbers';
import {IPlanetGuess} from './api/trailblazer-api';
import {lunar} from '~/entities/lunar-loot';

export function planetsToIndex(planets: PlanetName[]) {
  return planets.map((planet) => ALL_PLANET_NAMES.indexOf(planet));
}

export function indexToPlanets(index: number[]) {
  return index.map((i) => ALL_PLANET_NAMES[i]);
}

export function planetToEmoji(planets: PlanetName[]) {}
export const ALL_PLANET_NAMES: PlanetName[] = [
  'bubblegum-giant',
  'cryptonova',
  'donutopia',
  'flufforia',
  'goopiter',
  'holeyverse',
  'lavalampia',
  'prickle-prime',
  'zapzilla',
];

export type PlanetName =
  | 'bubblegum-giant'
  | 'cryptonova'
  | 'donutopia'
  | 'flufforia'
  | 'goopiter'
  | 'holeyverse'
  | 'lavalampia'
  | 'prickle-prime'
  | 'zapzilla';

/**
 💧
  👿
  🍩
  ☁️
  🐸
  🧿
  🌋
  👾
  ⚡️
 */
export const PLANET_TO_EMOJI: Record<PlanetName, string> = {
  'bubblegum-giant': '💧',
  cryptonova: '👿',
  donutopia: '🍩',
  flufforia: '☁️',
  goopiter: '🐸',
  holeyverse: '🧿',
  lavalampia: '🌋',
  'prickle-prime': '👾',
  zapzilla: '⚡️',
};

export function planetEmojiGrid(planets: PlanetName[]) {
  if (planets.length !== 9) {
    throw new Error('Invalid number of planets');
  }

  return planets
    .map((planet, i) => {
      return `${i % 3 === 0 && i !== 0 ? '\n' : ''}${PLANET_TO_EMOJI[planet]}`;
    })
    .join('');
}
export function createShareText(guess: IPlanetGuess) {
  return `I've aligned ${
    guess.planets_decoded_count
  }/9 planets today and received **${formatNumber(
    lunar(guess.reward_amount),
  )} Lunar Coins**.\nCheck my combination: \n${planetEmojiGrid(
    indexToPlanets(guess.planet_order_guess),
  )}`;
}
