import {Header} from '~/app/header';
import {PageLayout} from '~/components/layouts/page-layout';
import {Box} from '~/components/kit/box';
import lunarLootAbsorptionTextSvg from './assets/lunar-loot-absorption.svg';
import {rem} from '~/shared/utils/style-helpers';
import {Typography} from '~/components/kit/typography';
import {ButtonLike} from '~/components/kit/button/button';
import {AIRDROP_DISTRIBUTION_DATE} from '~/shared/feature-flags';
import {formatCountdownWords} from '~/shared/utils/format/format-time';
import {useAppTheme} from '~/components/kit';
import {ImgTransition} from '~/components/kit/img-transition';
import {AspectRatio} from '~/components/kit/aspect-ratio';
import starsBgJpg from './assets/stars-bg.jpg';
import {BlackholeEventExplainer} from './blackhole-event-explainer';

export function EarnDisconnectedWallet() {
  const theme = useAppTheme();

  return (
    <PageLayout>
      <Header></Header>

      <Box
        backgroundColor="#100522"
        css={{
          padding: rem(20),
          marginTop: rem(20),
          marginLeft: rem(-20),
          marginRight: rem(-20),
          color: theme.colors.onSurfaceAlternative,
        }}
        bgImage={<ImgTransition src={starsBgJpg} css={{width: '100%'}} />}
      >
        <AspectRatio
          ratio={263 / 317}
          css={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            maxWidth: rem(300),
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <ImgTransition
            src={lunarLootAbsorptionTextSvg}
            css={{
              width: '100%',
            }}
          />
        </AspectRatio>
        <div
          css={{
            marginTop: rem(20),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography.h4>Airdrop incoming in</Typography.h4>
          <ButtonLike
            color="pink"
            orientation="left"
            css={{
              padding: `${rem(15)} ${rem(32)}`,
              marginTop: rem(12),
              background: '#F6CBFF',
              fontWeight: 900,
            }}
          >
            {' '}
            {formatCountdownWords(AIRDROP_DISTRIBUTION_DATE)}
          </ButtonLike>
        </div>

        <BlackholeEventExplainer />
      </Box>
    </PageLayout>
  );
}
