import React from 'react';
import {useAppTheme} from '~/components/kit';
import {Typography} from '~/components/kit/typography';
import {useEarnModel} from '~/entities/earn';
import {WalletConnectForm} from '~/features/connect-wallet';
import {rem} from '~/shared/utils/style-helpers';

export function WalletConnectContent(props: React.HTMLProps<HTMLDivElement>) {
  const theme = useAppTheme();
  const earnModel = useEarnModel();

  const isWalletConnected = earnModel.isWalletConnected;

  if (isWalletConnected) {
    return (
      <div {...props}>
        <header
          css={{
            margin: 'auto',
            marginBottom: rem(24),
            textAlign: 'center',
            borderRadius: 30,
          }}
        >
          <Typography.h3
            css={{
              fontWeight: 800,
            }}
          >
            Wallet Connected!
          </Typography.h3>
          <p
            css={{
              fontWeight: 500,
              marginTop: rem(10),
              fontSize: theme.fontSize.odds.s15,
              color: theme.colors.onSurface,
            }}
          >
            Your wallet is now connected with Roketo. Only 1 wallet can be
            connected to account. <br /> But you can disconnect and connect with
            a new one if you wish to do so! <br /> Why would you want that? We
            don't know...
          </p>
        </header>
      </div>
    );
  }

  return (
    <div {...props}>
      <header
        css={{
          margin: 'auto',
          marginBottom: rem(24),
          textAlign: 'center',
          borderRadius: 30,
        }}
      >
        <Typography.h3
          css={{
            fontWeight: 800,
          }}
        >
          Connect Your Wallet
        </Typography.h3>
        <p
          css={{
            fontWeight: 500,
            marginTop: rem(10),
            fontSize: theme.fontSize.odds.s15,
            color: theme.colors.onSurface,
          }}
        >
          Link up to claim your cosmic rewards! If you score any tradable
          treasures, we'll beam them straight to your connected wallet. No
          worries, it's a breeze to set up!
        </p>
      </header>

      <WalletConnectForm />
    </div>
  );
}
