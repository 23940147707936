import {IUserFriend} from '~/api/friends/interfaces';
import {useAppTheme} from '~/components/kit';
import {Avatar} from '~/components/kit/avatar';
import {Box} from '~/components/kit/box';
import {Typography} from '~/components/kit/typography';
import {fullName} from '~/entities/referral-link/utilts';
import {rem} from '~/shared/utils/style-helpers';

export function FriendCard({
  friend,
  ...rest
}: {
  friend: {
    first_name: string;
    last_name: string | undefined;
  };
} & React.HTMLAttributes<HTMLDivElement>) {
  const theme = useAppTheme();

  return (
    <Box
      backgroundColor={theme.colors.surfaceSecondary}
      borderWidth={2}
      shadowColor={theme.colors.surfaceSecondaryBorder}
      shadowOffsetX={2}
      shadowOffsetY={2}
      radius={30}
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        alignItems: 'center',
        justifyContent: 'center',
        padding: rem(26),
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
      }}
      {...rest}
    >
      <Avatar id={fullName(friend)} size="large"></Avatar>
      <Typography.h3>{fullName(friend)}</Typography.h3>
    </Box>
  );
}
