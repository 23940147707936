import React from 'react';

import {useSignals} from '@preact/signals-react/runtime';
import invariant from 'tiny-invariant';
import {NEWS_NOTIFICATION_ACTION} from '~/shared/onlaunch-modal/actions';
import {useOnlaunchAction} from '~/shared/onlaunch-modal/use-onlaunch-action';
import {useCurrentUser, isCreatedAtLeastDaysAgo, isBeforeDate} from '../user';
import {notifications, isReady} from './model';
import {useModals} from '~/components/kit/modal';
import {useDockModel} from '../dock';
import {isQueryReady} from '~/shared/utils/query';
import {toClientTimestamp} from '~/shared/utils/format/format-time';
import {LEADERBOARD_RELEASE_DATE} from '~/shared/feature-flags';

export function NewsNotificationProcess({
  modalName,
  renderNotification,
}: {
  modalName: string;
  renderNotification: (finalize: () => void) => JSX.Element;
}) {
  useSignals();
  const modals = useModals();

  const dock = useDockModel();

  const user = useCurrentUser();

  const finalize = useOnlaunchAction({
    ...NEWS_NOTIFICATION_ACTION,
    willRun: true,
    isReady: isReady.value && isQueryReady(dock.dockQuery),
    executeAction: () => {
      invariant(notifications.value !== null, 'Notifications should be ready');

      const lastClaimBeforeRelease = isBeforeDate(
        new Date(toClientTimestamp(dock.dock.last_claimed_time)),
        LEADERBOARD_RELEASE_DATE,
      );

      // Do not show notifications for new users or users who have already seen the notification
      if (
        isCreatedAtLeastDaysAgo(user, 2) &&
        lastClaimBeforeRelease &&
        !notifications.value.friendsUpdates
      ) {
        modals.open(modalName);
        return false;
      }

      return true;
    },
  });

  return <React.Fragment>{renderNotification(finalize)}</React.Fragment>;
}
