import {useAppTheme} from '../theme';

export function Markdown({
  children,
  ...rest
}: {children: React.ReactNode} & React.HTMLProps<HTMLDivElement>) {
  const theme = useAppTheme();

  return (
    <div
      css={{
        fontSize: theme.colors.onSurface,
        lineHeight: 1.3,
        p: {
          fontWeight: 400,
          marginBottom: '1em',
        },
        em: {
          fontWeight: 800,
        },
        h1: {
          fontSize: '1.5em',
          fontWeight: 800,
          marginBottom: '1em',
        },
        ul: {
          paddingLeft: '1.25em',
          listStyleType: 'disc',
          li: {
            marginBottom: '0.5em',
          },
        },
        a: {
          textDecoration: 'underline',
        },
      }}
      {...rest}
    >
      {children}
    </div>
  );
}
