import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {FriendsApi} from '~/api/friends/friends-api';
import {IUserFriend} from '~/api/friends/interfaces';
import {makeAxiosResponse} from '~/api/shared/api-client';
import {useAuthorizationToken} from '~/entities/user';
import {isQueryReady} from '~/shared/utils/query';

export function useFriendRequestModel({friendCode}: {friendCode: string}) {
  const token = useAuthorizationToken();
  const client = useQueryClient();

  const friendInfoQuery = useQuery({
    queryKey: ['friend-info', friendCode],
    enabled: !!friendCode,
    queryFn: () => {
      return FriendsApi.getFriendInfoByFriendCode({friendCode}, token);
    },
  });

  const addFriendMutation = useMutation({
    mutationKey: ['add-friend'],
    mutationFn: (friendCode: string) => {
      return FriendsApi.addFriend({friendCode}, token);
    },
    onSuccess: (data) => {
      client.setQueryData(['friends'], data);
    },
  });

  const data = friendInfoQuery.data?.data || {
    id: '',
    first_name: '',
    last_name: '',
  };

  return {
    isReady: isQueryReady(friendInfoQuery),
    addMutation: addFriendMutation,
    friendInfo: {
      query: friendInfoQuery,
      data,
    },
  };
}

export function useFriendsModel() {
  const client = useQueryClient();

  const token = useAuthorizationToken();

  const friendsQuery = useQuery({
    queryKey: ['friends'],
    queryFn: () => {
      return FriendsApi.getFriendsList(token);
    },
  });

  const deleteFriendMutation = useMutation({
    mutationKey: ['delete-friend'],
    mutationFn: (friendId: string) => {
      return FriendsApi.deleteFriend({friendId}, token);
    },
    onSuccess: (data, friendId) => {
      client.setQueryData<AxiosResponse<IUserFriend[]>>(['friends'], data);
    },
  });

  const friendsData = (
    friendsQuery.data || makeAxiosResponse<IUserFriend[]>([])
  ).data;

  return {
    friends: {
      query: friendsQuery,
      data: friendsData,
    },
    deleteMutation: deleteFriendMutation,
    isReady: isQueryReady(friendsQuery),
  };
}
