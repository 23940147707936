import {AxiosResponse} from 'axios';
import {
  IMultipleUpgradesResponse,
  IUpgradeCostResponse,
  UpgradeName,
} from '~/api/dock';
import {makeAxiosResponse} from '~/api/shared/api-client';

export function upgradeCostInitialData<T extends UpgradeName>(): AxiosResponse<
  IUpgradeCostResponse<T>
> {
  return makeAxiosResponse({
    cost: 0,
    prev_value: 0,
    new_value: 0,
  } as IUpgradeCostResponse<T>);
}

export function multipleUpgradeCostInitialData(): AxiosResponse<IMultipleUpgradesResponse> {
  return makeAxiosResponse({
    lunar_loot_speed_upgrades: [],
    dock_size_upgrades: [],
    yield_percentage_upgrades: [],
  } as IMultipleUpgradesResponse);
}
