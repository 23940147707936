import {useMutation} from '@tanstack/react-query';
import {DockApi} from '~/api/dock';
import {useAuthorizationToken} from '~/entities/user';
import {trackClaimedMoons} from './analytics';
import {useDockModel} from '~/entities/dock';
import {lunarToFloat} from '~/entities/lunar-loot';

export function useClaimMoons() {
  const dockModel = useDockModel();

  const token = useAuthorizationToken();

  const mutation = useMutation({
    mutationKey: ['claim-moons'],
    mutationFn: async () => {
      const balanceToClaim = dockModel.dockCalculator.dockBalance;

      const data = await DockApi.claimMoons(token);
      trackClaimedMoons({
        amount: lunarToFloat(balanceToClaim),
      });

      return data;
    },
  });

  return mutation;
}
