import './translations/en/translation.json';

// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import {initReactI18next} from 'react-i18next';

// eslint-disable-next-line import/no-named-as-default-member

export function initI18n() {
  i18n
    .use(
      resourcesToBackend((language, namespace, callback) => {
        import(`./translations/${language}/${namespace}.json`)
          .then((resources) => {
            callback(null, resources);
          })
          .catch((error) => {
            callback(error, null);
          });
      }),
    )
    .use(initReactI18next)
    .init({
      lng: 'en',
      supportedLngs: ['en', 'pt', 'es'],
      fallbackLng: 'en',
    });
}
