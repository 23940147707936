import React from 'react';
import {rem} from '~/shared/utils/style-helpers';
import {useAppTheme} from '../theme';

import {Box, ISquircleBox} from '../box';
import {SquircleProps} from '@squircle-js/react';

export interface ICardProps
  extends React.HTMLAttributes<HTMLDivElement>,
    Pick<ISquircleBox, 'bgImage'> {
  isAlternative?: boolean;
  rounding: 'small' | 'medium' | 'large' | number;
  borderWidth?: number;
  shadowOffsetX?: number;
  shadowOffsetY?: number;
  shadowColor?: string;
}

export const CARD_ROUNDING = {
  SMALL: 20,
  MEDIUM: 30,
  BIG: 40,
};

export function Card({
  children,
  isAlternative,
  rounding,
  borderWidth = 0,
  shadowOffsetX = 0,
  shadowOffsetY = 0,
  shadowColor,
  ...rest
}: ICardProps) {
  const theme = useAppTheme();

  const radius =
    rounding === 'small'
      ? CARD_ROUNDING.SMALL
      : rounding === 'medium'
      ? CARD_ROUNDING.MEDIUM
      : rounding === 'large'
      ? CARD_ROUNDING.BIG
      : rounding;

  return (
    <Box
      backgroundColor={
        isAlternative
          ? theme.colors.surfaceAlternativeCard
          : theme.colors.surface
      }
      shadowColor={
        shadowColor ||
        (isAlternative
          ? theme.colors.onSurfaceAlternative
          : theme.colors.onSurface)
      }
      borderWidth={borderWidth}
      shadowOffsetX={shadowOffsetX}
      shadowOffsetY={shadowOffsetY}
      radius={radius}
      css={{
        padding: rem(20),
        position: 'relative',
        zIndex: 1,
        color: isAlternative
          ? theme.colors.onSurfaceAlternative
          : theme.colors.onSurface,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}

export function CardHeader({
  title,
  children,
  ...rest
}: {
  title: React.ReactNode;
  children?: React.ReactNode;
} & React.HTMLProps<HTMLDivElement>) {
  const theme = useAppTheme();

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: rem(12),
      }}
      {...rest}
    >
      <div
        css={{
          fontSize: theme.fontSize.odds.s15,
          fontWeight: 700,
        }}
      >
        {title}
      </div>
      {children}
    </div>
  );
}
