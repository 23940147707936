import {getElementCircularly} from '~/shared/utils/arrays';

export const LightningRight = ({
  mainColor,
  raysColor,
}: {
  mainColor: string;
  raysColor: string | string[];
}) => (
  <svg
    width="41"
    height="58"
    viewBox="0 0 41 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.68393 14.0006L10.1939 1.68632L1.20312 6.25074L2.4551 0.781006M4.71381 57.219L3.68393 51.9287L14.9172 55.2418L13.2949 47.3007M5.49005 13.6359L23.6202 9.97478L15.3927 20.8529L39.6241 25.2551L14.0412 33.9499L19.6932 42.0565L12.6616 40.3664L11.8379 40.1685L12.8194 44.9727"
      stroke={mainColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.6151 14.9801L32.0902 12.8374L28.6864 11.8633L32.8154 9.14734"
      stroke={
        typeof raysColor === 'object'
          ? getElementCircularly(raysColor, 0)
          : raysColor
      }
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.5977 48.9424L22.6544 52.1796L19.6125 52.5225L22.4225 54.7819"
      stroke={
        typeof raysColor === 'object'
          ? getElementCircularly(raysColor, 1)
          : raysColor
      }
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.4284 37.573L24.177 36.3698"
      stroke={
        typeof raysColor === 'object'
          ? getElementCircularly(raysColor, 2)
          : raysColor
      }
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.4133 2.34761L14.5547 4.15259"
      stroke={
        typeof raysColor === 'object'
          ? getElementCircularly(raysColor, 3)
          : raysColor
      }
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
