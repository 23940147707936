import {useQuery} from '@tanstack/react-query';
import {useAuthorizationToken} from '../user';
import {DockApi, IDockInfoResponse} from '~/api/dock';
import {dockInitialData} from './initial-data';
import {YieldCalculator} from '~/features/yield';
import {
  DockBalanceCalculator,
  DockProgressCalculator,
} from '~/features/idle-mining/math';
import {toClientTimestamp} from '~/shared/utils/format/format-time';
import {signal} from '@preact/signals-react';

export const dockData = signal<IDockInfoResponse | null>(null);

export function useDockModel() {
  const token = useAuthorizationToken();
  const dockQuery = useQuery({
    queryKey: ['dock'],
    queryFn: async () => {
      const dock = await DockApi.getDockInfo(token);

      dockData.value = dock.data;

      return dock;
    },
    // Refetch every 5 minutes
    refetchInterval: 60_000 * 5,
    refetchOnMount: false,
  });

  const dock = (dockQuery.data || dockInitialData()).data;

  const yieldCalculator = new YieldCalculator({
    yield_percentage: dock.yield_percentage,
    yield_period: toClientTimestamp(dock.yield_period),
    last_yield_time: toClientTimestamp(dock.last_yield_time),
  });

  const dockProgressCalculator = new DockProgressCalculator({
    minClaimTime: toClientTimestamp(dock.min_claim_time),
    lastClaimedTime: toClientTimestamp(dock.last_claimed_time),
  });

  const dockCalculator = new DockBalanceCalculator({
    // transform to loot per MS
    combinedIdleMiningSpeed: dock.combined_lunar_loot_speed / 1000,
    lastClaimedTime: toClientTimestamp(dock.last_claimed_time),
    dockSize: toClientTimestamp(dock.dock_size),
  });

  return {
    dockQuery,
    dock: dock,

    yieldCalculator,
    dockProgressCalculator,
    dockCalculator,
    isReady: dockQuery.isSuccess && dockQuery.isFetched,
  };
}
