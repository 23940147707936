import React from 'react';
import ReactDOM from 'react-dom/client';
import {initI18n} from './shared/i18n/i18n';
import {Root} from '~/components/Root';
import './index.css';
import './normalize.css';
import {miniapp} from './shared/telegram-mini-app';
import {IS_ERUDA_DEBUG_ENABLED} from './shared/feature-flags';

if (IS_ERUDA_DEBUG_ENABLED) {
  // @ts-ignore
  import('eruda').then((eruda) => eruda.default.init());
}

initI18n();

miniapp.setBgColor('#FFFFFF');
miniapp.setHeaderColor('#FFFFFF');

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
);
