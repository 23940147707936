import {keyframes} from '@emotion/react';
import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {useAppTheme} from '../theme';
import {Card} from '../card';
import {rem} from '~/shared/utils/style-helpers';
import {useModals} from './context';
import {logger} from '~/shared/debug';
import {usePrevious} from '@uidotdev/usehooks';
import {Box, ISquircleBox} from '../box';
import {useDrag} from '@use-gesture/react';
import {ArrowRightIcon} from '../icons/arrow-right-icon';

const opacityKeyframes = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const modalAppearKeyframes = keyframes`
  from {
    transform: translateY(1rem);
  }

  to {
    transform: translateY(0);
`;

export const ModalTitle = ({
  children,
  ...rest
}: React.HTMLProps<HTMLDivElement>) => {
  const theme = useAppTheme();

  return (
    <div
      css={{
        textAlign: 'center',
        paddingLeft: rem(40),
        paddingRight: rem(40),
        fontSize: theme.fontSize.s1,
        fontWeight: 800,
        color: theme.colors.onSurface,
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export function ModalControlButton({
  children,
  ...rest
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const theme = useAppTheme();

  return (
    <button
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        backgroundColor: theme.colors.onSurface,
        color: theme.colors.surface,
        borderRadius: 999999,
        cursor: 'pointer',
        padding: `${rem(10)} ${rem(20)}`,
        fontSize: theme.fontSize.s4,
        fontWeight: 900,
        letterSpacing: '0.03em',
      }}
      {...rest}
    >
      {children}
    </button>
  );
}

function ModalClose({
  children,
  ...rest
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const modals = useModals();
  const theme = useAppTheme();

  return (
    <ModalControlButton
      css={{
        width: rem(40),
        height: rem(40),
        padding: 0,
      }}
      {...rest}
      onClick={() => {
        modals.close();
      }}
    >
      <svg
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Vector"
          d="M13.1032 2.39661L1.89685 13.603M13.1032 13.6029L1.89685 2.39655"
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </ModalControlButton>
  );
}

export const MODAL_PADDING = 20;
export const HEADER_MENU_MODAL_WIDTH = 220;

export function Modal({
  children,
  isClosable = true,
  name,
  controlButton,
  afterClose,
  backgroundColor,
  shadowColor,
  borderWidth,
  hideCloseButton,
  ...rest
}: {
  children: React.ReactNode;
  controlButton?: React.ReactNode;
  isClosable?: boolean;
  name: string;
  afterClose?: () => void;
  hideCloseButton?: boolean;
} & React.HTMLProps<HTMLDivElement> &
  Partial<ISquircleBox>) {
  const {isOpen, close, closeExact, activate, deactivate} = useModals();
  const element = document.getElementById('modal-root');
  const overlay = useRef<HTMLDivElement>(null);
  const theme = useAppTheme();

  const prevOpen = usePrevious(isOpen(name));

  useEffect(() => {
    if (prevOpen && !isOpen(name)) {
      afterClose && afterClose();
    }
  }, [isOpen(name)]);

  // make sure to close the modal when the component is unmounted
  // to avoid stuck blur on the body
  useEffect(() => {
    activate(name);
    return () => {
      if (isOpen(name)) {
        closeExact(name);
      }
      deactivate(name);
    };
  }, []);

  if (isOpen(name) && element) {
    return ReactDOM.createPortal(
      <div
        css={{
          position: 'fixed',
          maxHeight: '100vh',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          display: 'flex',
          background: `rgba(240, 239, 252, 0.43)`,
          zIndex: 100,
          animation: `${opacityKeyframes} 0.3s ease-in-out`,
        }}
      >
        <div
          ref={overlay}
          onClick={(e) => {
            const shouldClose = e.target === overlay.current && isClosable;
            logger.debug('[Modal]: OverlayClick', shouldClose);
            //only if the click is on the overlay
            if (shouldClose) {
              close();
            }
          }}
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'auto',
            width: '100%',
            height: '100%',
            padding: rem(20),
            animation: `${modalAppearKeyframes} 0.3s ease-in-out`,
          }}
        >
          <Box
            radius={30}
            borderWidth={borderWidth || 2}
            shadowOffsetX={1}
            shadowOffsetY={2}
            backgroundColor={backgroundColor || theme.colors.surface}
            shadowColor={shadowColor || theme.colors.onSurface}
            css={{
              width: '100%',
              padding: rem(MODAL_PADDING),
              position: 'relative',
              zIndex: 1,
            }}
            {...rest}
          >
            {children}
          </Box>

          {!hideCloseButton &&
            (controlButton ? (
              controlButton
            ) : (
            <ModalClose
              css={{
                marginTop: rem(25),
                marginBottom: rem(20),
              }}
            />
          ))}
        </div>
      </div>,
      element,
    );
  } else return null;
}

export function ModalSteps({
  steps,
  activeStep,
  isAlternative,
}: {
  steps: number;
  activeStep: number;
  isAlternative?: boolean;
}) {
  const theme = useAppTheme();
  const colors = {
    active: isAlternative
      ? theme.colors.onSurfaceAlternative
      : theme.colors.onSurface,
    inactive: isAlternative
      ? theme.colors.onSurfaceAlternativeSecondary
      : theme.colors.onSurfaceSecondary,
  };
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: rem(20),
      }}
    >
      {Array.from({length: steps}).map((_, i) => (
        <div
          key={i}
          css={{
            width: i === activeStep ? rem(27) : rem(7),
            height: rem(7),
            borderRadius: 999999,
            backgroundColor: i === activeStep ? colors.active : colors.inactive,
            marginRight: rem(5),
            transition: 'all 0.3s ease-in-out',
          }}
        ></div>
      ))}
    </div>
  );
}

export function useModalSteps({steps}: {steps: (() => React.ReactNode)[]}) {
  const [step, setStep] = useState(0);
  const Step = steps[step];

  const isLastStep = step === steps.length - 1;

  const goToNextStep = () => {
    if (isLastStep) {
      return false;
    }

    setStep(step + 1);

    return true;
  };

  const goToPrevStep = () => {
    if (step === 0) {
      return false;
    }

    setStep(step - 1);
    return true;
  };

  const bindDrag = useDrag(
    ({swipe: [swipeX]}) => {
      if (swipeX === -1) {
        goToNextStep();
      } else if (swipeX === 1) {
        goToPrevStep();
      }
    },
    {
      axis: 'x',
      swipe: {
        distance: 10,
      },
    },
  );

  return {
    Step,
    step,
    length: steps.length,
    setStep,
    isLastStep,
    goToNextStep,
    goToPrevStep,
    bindDrag,
  };
}

export function ModalStepsControlButton({
  steps,
  afterLastStep,
}: {
  steps: ReturnType<typeof useModalSteps>;
  afterLastStep: () => void;
}) {
  return (
    <ModalControlButton
      color="pink"
      onClick={() => {
        const didGoToNextStep = steps.goToNextStep();
        if (!didGoToNextStep) {
          afterLastStep();
          return;
        }
      }}
      css={{
        marginTop: rem(20),
      }}
    >
      {steps.isLastStep ? (
        'Got it!'
      ) : (
        <React.Fragment>
          <span>Next</span>
          <ArrowRightIcon css={{marginLeft: rem(4), width: rem(16)}} />
        </React.Fragment>
      )}
    </ModalControlButton>
  );
}
