import {useSignals} from '@preact/signals-react/runtime';
import {useEffect, useRef} from 'react';
import {launchActions} from './datastore';
import {logger} from '../debug';

interface IOnlaunchAction {
  priority: number;
  name: string;
  willRun: boolean;
  isReady: boolean;
  executeAction: () => boolean;
}
export function useOnlaunchAction({
  priority,
  name,
  willRun,
  isReady,
  executeAction,
}: IOnlaunchAction) {
  useSignals();

  const didOnLaunchAction = useRef(false);
  const nextActionsInQueue = launchActions.next.value;

  const debugMsg = (...msg: any[]) => {
    logger.debug(`[OnLaunchAction]: ${name}`, ...msg);
  };

  useEffect(() => {
    if (didOnLaunchAction.current) return;

    // should run?
    if (willRun) {
      launchActions.add({
        name,
        priority,
      });
    }
  }, [willRun]);

  useEffect(() => {
    if (nextActionsInQueue?.name === name && isReady) {
      debugMsg('Running action');
      const isFinished = executeAction();

      if (isFinished) {
        debugMsg('Finished action');
        launchActions.remove(name);
      }

      didOnLaunchAction.current = true;
    }
  }, [nextActionsInQueue, isReady]);

  useEffect(() => {
    return () => {
      launchActions.remove(name);
    };
  }, []);

  return () => {
    debugMsg('Finalizing action');
    launchActions.remove(name);
  };
}
