import {useSignals} from '@preact/signals-react/runtime';
import {useCurrentUser} from '~/entities/user';
import {Onboarding} from './onboarding';
import {useModals} from '~/components/kit/modal';
import {logger} from '~/shared/debug';
import {useOnlaunchAction} from '~/shared/onlaunch-modal/use-onlaunch-action';
import {ONBOARDING_ACTION} from '~/shared/onlaunch-modal/actions';

const debugMsg = (...msg: any[]) => {
  logger.debug(`[OnboardingProcess]`, ...msg);
};

export function OnboardingProcess() {
  useSignals();

  const user = useCurrentUser();
  const modals = useModals();

  const completeAction = useOnlaunchAction({
    ...ONBOARDING_ACTION,
    willRun: !user.is_onboarding_completed,
    // onboarding is immediately ready to be run, no need to wait for anything
    isReady: true,
    executeAction: () => {
      modals.open('onboarding', {replace: true});
      return false;
    },
  });

  return (
    <Onboarding
      afterClose={() => {
        completeAction();
      }}
    />
  );
}
