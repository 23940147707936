import {ImgTransition} from '~/components/kit/img-transition';
import astronautsHuggingPng from './assets/astronauts-hugging.png';
import {Icon} from '~/components/kit/icons';
import {Typography} from '~/components/kit/typography';
import {CheckIcon} from '~/components/kit/icons/check';
import {Chip} from '~/components/kit/chip';
import {useAppTheme} from '~/components/kit';
import {rem} from '~/shared/utils/style-helpers';
import {CrossIcon} from '~/components/kit/icons/cross-icon';
import {Button} from '~/components/kit/button/button';
import referralsNavPng from '~/app/navigation/navigation-menu/assets/referrals-nav.png';
import {Link} from '~/components/Link/Link';
import {RoutePath} from '~/app/navigation/routes';
const blackHoleColors = {
  textSecondary: '#6C6A85',
};

export function ReferralBooster({refCount}: {refCount: number}) {
  const isActive = refCount >= 5;
  const theme = useAppTheme();

  if (isActive) {
    return (
      <div css={{display: 'flex'}}>
        <Icon
          size={40}
          css={{
            marginRight: rem(15),
          }}
        >
          <ImgTransition src={astronautsHuggingPng}></ImgTransition>
        </Icon>
        <div>
          <Typography.h6
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Referral Booster Activated
            <Icon
              size={14}
              css={{
                marginLeft: rem(4),
              }}
            >
              <CheckIcon />
            </Icon>
          </Typography.h6>
          <Chip
            css={{
              background: '#D8D5FF',
              fontWeight: 700,
              marginTop: rem(10),
            }}
          >
            Black Hole Fuelness x2
          </Chip>
        </div>
      </div>
    );
  }

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        css={{
          display: 'flex',
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginRight: rem(20),
          }}
        >
          <Icon size={40}>
            <ImgTransition src={astronautsHuggingPng}></ImgTransition>
          </Icon>
          <div>
            <Typography.h7
              css={{
                marginTop: rem(5),
              }}
            >
              Referrals
            </Typography.h7>
            <div
              css={{
                fontWeight: 700,
                fontSize: theme.fontSize.s4,
                textAlign: 'center',
                marginTop: rem(2),
              }}
            >
              <span css={{}}>{refCount}</span>
              <span
                css={{
                  color: '#6C6A85',
                }}
              >
                /5
              </span>
            </div>
          </div>
        </div>
        <div>
          <Typography.h6
            css={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: rem(6),
              fontWeight: 800,
            }}
          >
            Referral Booster Inactive
            <Icon
              size={14}
              css={{
                marginLeft: rem(4),
              }}
            >
              <CrossIcon />
            </Icon>
          </Typography.h6>

          <div
            css={{
              color: '#6C6A85',
            }}
          >
            <Typography.h6
              css={{
                display: 'inline',
                fontWeight: 800,
              }}
            >
              Invite 3 more astronauts and get Referral Booster:
            </Typography.h6>
            <Typography.body
              css={{
                display: 'inline',
              }}
            >
              &nbsp;it will DOUBLE your Lunar Coin thrown in Black Hole
            </Typography.body>
          </div>
        </div>
      </div>

      <Link
        to={RoutePath.Referrals}
        onClick={() => {
          window.scrollTo(0, 0);
        }}
        css={{
          paddingLeft: rem(20),
          paddingRight: rem(20),
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: rem(16),
        }}
      >
        <Button
          color="white"
          css={{
            flexGrow: 1,
            textTransform: 'uppercase',
            fontWeight: 900,
            padding: `${rem(4)} ${rem(12)}`,
            height: rem(40),
            fontSize: theme.fontSize.odds.s15,
          }}
        >
          referrals page
          <Icon size={30}>
            <img
              src={referralsNavPng}
              css={{
                objectFit: 'contain',
              }}
            ></img>
          </Icon>
        </Button>
      </Link>
    </div>
  );
}
