import React from 'react';
import {Reward} from '../reward/reward';
import {rem} from '~/shared/utils/style-helpers';
import {Icon} from '~/components/kit/icons';
import {CheckIcon} from '~/components/kit/icons/check';
import {SocialMedia, SocialMediaLink} from '~/entities/social-media';

interface SocialIconProps extends React.HTMLProps<HTMLDivElement> {
  social: SocialMedia;
  isChecked: boolean;
  doBeforeOpen: () => void;
  reward: number;
}

export function SocialIcon({
  social,
  isChecked,
  reward,
  doBeforeOpen,
  ...rest
}: SocialIconProps) {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      {...rest}
    >
      <div
        css={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: rem(8),
        }}
      >
        <SocialMediaLink social={social} onClick={doBeforeOpen} />

        <Icon
          size={20}
          css={{
            display: isChecked ? 'block' : 'none',
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        >
          <CheckIcon />
        </Icon>
      </div>

      <Reward isAlternativeColor={isChecked} value={reward} />
    </div>
  );
}
