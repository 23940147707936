import {Link} from 'react-router-dom';
import {RoutePath} from '~/app/navigation/routes';
import friendsFaceSvg from '../assets/friends-icon.svg';
import {FriendsIcon} from '../assets/friends-icon';
import {Icon} from '~/components/kit/icons';

export function FriendsNavigationLink() {
  return (
    <Link to={RoutePath.Friends}>
      <Icon size={40}>
        <FriendsIcon></FriendsIcon>
      </Icon>
    </Link>
  );
}
