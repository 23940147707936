import {useRef, useEffect} from 'react';
import {useModals} from '~/components/kit/modal';
import {useFriendsModel, useFriendRequestModel} from './model';
import {ADD_FRIEND_MODAL_NAME, AddFriendModal} from './ui/add-friend-modal';
import {logger} from '~/shared/debug';
import {useSignals} from '@preact/signals-react/runtime';
import {launchActions} from '~/shared/onlaunch-modal';
import {ADD_FRIEND_ACTION} from '~/shared/onlaunch-modal/actions';
import {useOnlaunchAction} from '~/shared/onlaunch-modal/use-onlaunch-action';

const debugMsg = (...msg: any[]) => {
  logger.debug(`[AddFriendProcess]`, ...msg);
};

export function AddFriendProcess({friendCode}: {friendCode: string}) {
  useSignals();

  const modals = useModals();

  const friendsModel = useFriendsModel();
  const requestModel = useFriendRequestModel({
    friendCode: friendCode,
  });

  const isReady = requestModel.isReady && friendsModel.isReady;

  const finalizeAction = useOnlaunchAction({
    ...ADD_FRIEND_ACTION,
    willRun: !!friendCode,
    isReady,
    executeAction: () => {
      if (
        friendsModel.friends.data.some(
          (f) => f.friend_id === requestModel.friendInfo.data.id,
        )
      ) {
        debugMsg(
          'Friend is already in friends list ' +
            requestModel.friendInfo.data.id,
        );
        launchActions.remove(ADD_FRIEND_ACTION.name);
        return true;
      } else {
        debugMsg('Friend is not in friends list, will open modal');
        modals.open(ADD_FRIEND_MODAL_NAME);
        return false;
      }
    },
  });

  return (
    <AddFriendModal
      afterClose={() => {
        finalizeAction();
      }}
    />
  );
}
