import {Announcements, DndContext, DragOverlay} from '@dnd-kit/core';
import {Modal, MODAL_PADDING} from '~/components/kit/modal';
import {ExoPlanet} from './exo-planet';
import {keyframes} from '@emotion/react';
import {rem} from '~/shared/utils/style-helpers';
import {Fragment, useLayoutEffect, useRef, useState} from 'react';
import {SortableContext, arrayMove} from '@dnd-kit/sortable';
import {RewardValue, useAppTheme} from '~/components/kit';
import {Typography} from '~/components/kit/typography';
import {opacity} from '~/shared/utils/colors';
import {Button} from '~/components/kit/button/button';
import {useTrailblazerModel} from '../model';
import {AspectRatio} from '~/components/kit/aspect-ratio';
import {ImgTransition} from '~/components/kit/img-transition';
import congratulationsTextSvg from './assets/congratulations-text.svg';
import crystalsBoxSvg from './assets/crystals-box.svg';
import {Box} from '~/components/kit/box';
import openCardBg from './assets/open-card-bg.png';
import {Bonus} from '~/components/kit/bonus';
import {lunar} from '~/entities/lunar-loot';
import {formatNumber} from '~/shared/utils/format/format-numbers';
import {
  ALL_PLANET_NAMES,
  PlanetName,
  planetsToIndex,
  indexToPlanets,
  createShareText,
} from '../lib';
import {useUtils} from '@telegram-apps/sdk-react';
import {referralLink} from '~/entities/referral-link';
import {useCurrentUser} from '~/entities/user';
import {
  formatWeekDayUTC,
  toClientTimestamp,
} from '~/shared/utils/format/format-time';
import {TooltipHint} from '~/components/kit/tooltip';
import {Chip} from '~/components/kit/chip';

export const TRAILBLAZER_GAME_MODAL_NAME = 'traiblazer-game';
export const TRAILBLAZER_GAME_RESULT_MODAL_NAME = 'traiblazer-game-result';

const playfulBounceAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(-15deg);
  }

  100% {
    transform: rotate(0deg);
  }
`;

function GameScreen() {
  const model = useTrailblazerModel();
  const theme = useAppTheme();

  const [planets, setPlanets] = useState(ALL_PLANET_NAMES);
  const [activeId, setActiveId] = useState<PlanetName | null>(null);

  const didWelcomeAnimationPlayed = useRef(false);

  const getIndex = (id: PlanetName) => planets.indexOf(id) || 0;
  const activeIndex = activeId ? getIndex(activeId) : -1;

  useLayoutEffect(() => {
    didWelcomeAnimationPlayed.current = true;
  }, []);

  return (
    <DndContext
      onDragStart={({active}) => {
        if (!active) {
          return;
        }

        setActiveId(active.id as PlanetName);
      }}
      onDragEnd={({over}) => {
        setActiveId(null);

        if (over) {
          const overIndex = getIndex(over.id as PlanetName);
          if (activeIndex !== overIndex) {
            setPlanets((items) => arrayMove(items, activeIndex, overIndex));
          }
        }
      }}
      onDragCancel={() => setActiveId(null)}
    >
      <div
        css={{
          textAlign: 'center',
          marginBottom: rem(28),
        }}
      >
        <Typography.h1
          css={{
            color: theme.colors.onSurfaceAlternative,
            padding: 0,
          }}
        >
          Drag Planets and Decode Combination
        </Typography.h1>
        <Typography.body
          css={{
            color: opacity(theme.colors.onSurfaceAlternative, 0.7),
            marginTop: rem(10),
            fontWeight: 500,
          }}
        >
          Drag Planets to where you think they belong. Try to decode correct
          combination, and we'll show you how many you've got right!
        </Typography.body>
      </div>
      <SortableContext items={planets}>
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gridTemplateRows: '1fr 1fr 1fr',
            gap: rem(10),
          }}
        >
          {planets.map((planetName, i) => (
            <div
              css={{
                borderRadius: rem(20),
              }}
            >
              <ExoPlanet
                id={planetName}
                key={planetName}
                name={planetName}
                css={{
                  animation:
                    i === 0 && !didWelcomeAnimationPlayed.current
                      ? `${playfulBounceAnimation} 1.2s ease`
                      : 'none',
                  animationIterationCount: '2',
                }}
              />
            </div>
          ))}

          <DragOverlay>
            {activeId && (
              <ExoPlanet
                name={activeId}
                css={{
                  animation: `${playfulBounceAnimation} 1.2s ease`,
                  animationIterationCount: '2',
                  boxShadow: '2px 3px 0px 0px #000',
                }}
              />
            )}
          </DragOverlay>
        </div>

        <Button
          color="red"
          css={{
            marginTop: rem(24),
            width: '100%',
          }}
          onClick={() => {
            model.makeGuess.mutate(planetsToIndex(planets));
          }}
          isDisabled={model.makeGuess.isPending}
          isLoading={model.makeGuess.isPending}
        >
          Confirm
        </Button>
      </SortableContext>
    </DndContext>
  );
}

function ResultScreen() {
  const model = useTrailblazerModel();
  const theme = useAppTheme();
  const utils = useUtils();
  const user = useCurrentUser();

  const lastGuess = model.guessHistory.data?.[0];

  const planets = indexToPlanets(lastGuess.planet_order_guess);

  const isZeroDecoded = lastGuess.planets_decoded_count === 0;
  const isPartiallyDecoded = lastGuess.planets_decoded_count > 0 && lastGuess.planets_decoded_count < 9;
  const isFullDecoded = lastGuess.planets_decoded_count === 9;

  return (
    <Box
      backgroundColor="#0A0126"
      bgImage={
        <ImgTransition
          src={openCardBg}
          css={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      }
      css={{
        textAlign: 'center',
        marginLeft: rem(MODAL_PADDING * -1),
        marginRight: rem(MODAL_PADDING * -1),
        marginBottom: rem(MODAL_PADDING * -1),
        marginTop: rem(MODAL_PADDING * -1),
        padding: rem(MODAL_PADDING),
      }}
    >
      <AspectRatio
        ratio={28 / 293}
        css={{
          marginBottom: rem(10),
          maxWidth: rem(300),
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <ImgTransition src={congratulationsTextSvg} />
      </AspectRatio>

      <Typography.body
        css={{
          color: opacity(theme.colors.onSurfaceAlternative, 0.7),
          margin: 'auto',
          marginBottom: rem(20),
          maxWidth: rem(230),
        }}
      >
        You did a good job
        {isZeroDecoded ? (
          <span
            css={{
              color: theme.colors.onSurfaceAlternative,
              fontWeight: 700,
              fontStyle: 'italic',
            }}
          >
            {' '}
            trying
          </span>
        ) : (
          ''
        )}
        ! Tell&nbsp;your friends about your achievement.
      </Typography.body>

      {isPartiallyDecoded && (
        <div
          css={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gridTemplateRows: '1fr 1fr 1fr',
          gap: rem(10),
          maxWidth: rem(200),
          margin: 'auto',
        }}
      >
        {planets.map((planetName, i) => (
          <div
            css={{
              borderRadius: rem(20),
            }}
          >
            <ExoPlanet id={planetName} key={planetName} name={planetName} />
          </div>
          ))}
        </div>
      )}

      {isZeroDecoded && (
        <Typography.body
          css={{
            color: opacity(theme.colors.onSurfaceAlternative, 0.7),
            marginTop: rem(12),
          }}
        >
          All planets are in wrong positions!
          <br /> Failed experiment is still a progress.
        </Typography.body>
      )}

      {isPartiallyDecoded && (
        <Fragment>
          <Typography.body
            css={{
              color: opacity(theme.colors.onSurfaceAlternative, 0.7),
              marginTop: rem(12),
            }}
          >
            You've aligned{' '}
            <span
              css={{
                color: theme.colors.onSurfaceAlternative,
              }}
            >
              {model.lastGuess.planets_decoded_count}/9 planets
            </span>{' '}
            perfectly.
          </Typography.body>

          <Bonus
            color="primary"
            css={{
              marginTop: rem(8),
            }}
          >
            <RewardValue
              value={'+' + formatNumber(lunar(model.lastGuess.reward_amount))}
            />
          </Bonus>
          </Fragment>
      )}

      {isFullDecoded && (
        <Fragment>
          <div css={{
            marginTop: rem(-120),
            marginBottom: rem(-80),
          }}>
            <ImgTransition src={crystalsBoxSvg} />
          </div>

          <TooltipHint
            isVisible={true}
            tailPosition="bottom-center"
            backgroundColor="#FFFFFF"
            css={{
              top: rem(120),
              right: rem(-10),
              transform: 'rotate(10deg)',
            }}
          >
            <Typography.h4 css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color: theme.colors.onSurface,
              gap: rem(8),
            }}>
              <span>It’s your prize!</span>

              <Chip variant="large" css={{
                backgroundColor: theme.colors.onSurface,
                color: theme.colors.onSurfaceAlternative,
              }}>
                <RewardValue
                  value={'+' + formatNumber(lunar(model.lastGuess.crystal_amount))}
                  rewardType="crystal"
                />
              </Chip>
            </Typography.h4>
          </TooltipHint>
        </Fragment>
      )}

      <Button
        color="red"
        onClick={() => {
          utils.shareURL(
            referralLink(user.referral_code),
            createShareText(model.lastGuess),
          );
        }}
        css={{
          marginTop: rem(20),
          width: '100%',
        }}
      >
        Share Combination
      </Button>

      <div
        css={{
          color: theme.colors.onSurfaceAlternative,
          fontSize: theme.fontSize.s6,
          fontWeight: 600,
          marginTop: rem(12),
        }}
      >
        {formatWeekDayUTC(toClientTimestamp(model.lastGuess.created_at))}
      </div>
    </Box>
  );
}

export function TrailblazerGame() {
  const model = useTrailblazerModel();

  console.debug('IS PLAYED', model.hasPlayed);
  if (!model.hasPlayed) {
    return <GameScreen />;
  }

  return <ResultScreen />;
}

export function TraiblazerGameModal() {
  const theme = useAppTheme();

  return (
    <Modal name={TRAILBLAZER_GAME_MODAL_NAME} backgroundColor="#0A0126">
      <TrailblazerGame />
    </Modal>
  );
}
