import {analyticsAdapter} from '~/shared/analytics';

export function trackAirdropClaimed({
  airdropName,
  amount,
}: {
  airdropName: string;
  amount: number;
}) {
  analyticsAdapter.track('Airdrop: claim', {
    airdrop_name: airdropName,
    amount: amount,
  });
}
