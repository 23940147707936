import {useState} from 'react';

const cache = new Set<string>();

export function ImgTransition({
  src,
  appearSpeed = 1000,
  ...rest
}: {
  src: string;
  appearSpeed?: number;
} & React.ImgHTMLAttributes<HTMLImageElement>) {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <img
      src={src}
      onLoad={() => {
        setIsLoaded(true);
        cache.add(src);
      }}
      css={{
        opacity: isLoaded ? 1 : 0,
        transition: `all ${appearSpeed}ms ease`,
      }}
      {...rest}
    />
  );
}
