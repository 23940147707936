import {CheckIcon} from '~/components/kit/icons/check';
import {DailyCheckedPath} from './assets/daily-checked-path';
import {Icon} from '~/components/kit/icons';
import {rem} from '~/shared/utils/style-helpers';
import {Chip} from '~/components/kit/chip';
import {RewardValue, useAppTheme} from '~/components/kit';
import {shortNumber, toFloatCrystals} from '~/shared/utils/format/format-numbers';
import {lunar} from '~/entities/lunar-loot';
import {RewardType} from '~/entities/rewards';
import {DAYLY_CRYSTAL_REWARD_VALUES, DAYLY_REWARD_TYPES} from '../../model';

const DailyMark = ({
  reward,
  isChecked,
  i,
  rewardType = 'lunar',
  ...rest
}: {
  reward: number;
  isChecked: boolean;
  i: number;
  rewardType?: RewardType;
}) => {
  const theme = useAppTheme();

  const formatedValue = (value: number) => rewardType === 'lunar'
    ? shortNumber(Number(lunar(value, {precision: 2})))
    : '+' + toFloatCrystals(reward);

  return (
    <div
      css={{
        position: 'relative',
        display: 'flex',
        flexDirection: i % 2 === 0 ? 'column' : 'column-reverse',
        alignSelf: i % 2 === 0 ? 'flex-start' : 'flex-end',
      }}
    >
      <Icon size={24}>
        {isChecked ? (
          <CheckIcon />
        ) : (
          <div
            css={{
              backgroundColor: '#BB68D7',
              width: '100%',
              height: '100%',
              borderRadius: '50%',
            }}
          ></div>
        )}
      </Icon>

      <Chip
        css={{
          paddingLeft: rem(2),
          paddingRight: rem(2),
          minWidth: rem(54),
          position: 'absolute',
          transform:
            i % 2 === 0
              ? 'translateY(-120%) translateX(-50%)'
              : 'translateY(120%) translateX(-50%)',
          left: '50%',

          background: isChecked ? '#000000' : '#A242C4',
          color: theme.colors.onSurfaceAlternative,
          border: '1px solid #000000',
        }}
      >
        <RewardValue
          value={formatedValue(reward)}
          rewardType={rewardType}
        />
      </Chip>
    </div>
  );
};

export function DailyMissionMarks({
  dailyRewardsArray,
  checkedMarks,
  ...rest
}: {
  dailyRewardsArray: number[];
  checkedMarks: number;
} & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...rest}>
      <div
        css={{
          position: 'relative',
          height: rem(40),
        }}
      >
        <DailyCheckedPath
          css={{
            position: 'absolute',
            color: '#BB68D7',
            width: '100%',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        />
        <DailyCheckedPath
          css={{
            position: 'absolute',
            color: '#000000',
            width: '100%',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        />
        <div
          css={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-around',
            marginLeft: '5%',
            marginRight: '5%',
            height: rem(40),
          }}
        >
          {dailyRewardsArray.map((reward, i) => (
            <DailyMark
              key={i}
              reward={DAYLY_REWARD_TYPES[i] === 'lunar' ? reward : DAYLY_CRYSTAL_REWARD_VALUES[i]}
              isChecked={i < checkedMarks}
              i={i}
              rewardType={DAYLY_REWARD_TYPES[i]}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
