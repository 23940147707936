import {EnvironmentVariables} from '~/app/env';

export function referralCopyMessageText(refCode: string, utm?: string) {
  return `Join the space mission and receive airdrops for Lunar Coin!
I give you your first shiny💸 0.05 Lunar Coin and 1 week +300% speed boost for your succesfull start 🚀

My ref-code: ${refCode}

${referralLink(refCode, utm)}
  `;
}

export function referralLink(refCode: string, utm?: string) {
  return `https://${EnvironmentVariables.BOT_URL}?start=ref_${refCode}${
    utm ? `_r_${utm}` : ''
  }`;
}

export function referralCopyTelegramLink(refCode: string, utm?: string) {
  const text = `Join the space mission and receive airdrops for Lunar Coin!
  I give you your first shiny💸 0.05 Lunar Coin for your succesfull start 🚀`;
  const url = referralLink(refCode, utm);

  return {text, url};
}

/**
 /share/url?url=https://t.me/mtkbossbot?start=ref7027088735&text=Play%20with%20me,%20become%20the%20boss%20of%20a%20casino,%20and%20earn%20$MTKBOSS!%0A%F0%9F%92%B8%20+5k%20$MTKBOSS%20as%20a%20first%20gift%0A%F0%9F%94%A5%20+25k%20$MTKBOSS%20if%20you%20have%20Telegram%20Premium
 */
export function telegramShareLink(url: string, text: string) {
  const tgLink = `/share/url=${encodeURIComponent(
    url,
  )}&text=${encodeURIComponent(text)}`;
  return tgLink;
}
