import {computed, effect, signal} from '@preact/signals-react';
import {IAuthorizationResponse, User, UserApi} from '~/api';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {retrieveLaunchParams} from '@telegram-apps/sdk';
import invariant from 'tiny-invariant';
import {useSignals} from '@preact/signals-react/runtime';
import {createContext, useContext, useEffect} from 'react';
import {analyticsAdapter, isAnalyticsEnabledForUser} from '~/shared/analytics';
import {useLaunchParams, useViewport} from '@telegram-apps/sdk-react';
import {cloudStorage} from '~/shared/cloud-storage';
import {logger} from '~/shared/debug';
import {parseStartParam} from '~/shared/start-params';
import {isAxiosError} from 'axios';
import {manualSentryClient} from '~/shared/sentry';
import {shouldSendAnalyticsEvents} from '~/shared/analytics/model';

const safeRetrieveLaunchParams = () => {
  try {
    return retrieveLaunchParams();
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      return {
        initDataRaw: 'fakeParams',
        initData: {},
      };
    }

    throw error;
  }
};

const {initData, initDataRaw} = safeRetrieveLaunchParams();

declare global {
  interface Window {
    Telegram: any;
  }
}

window.Telegram = {
  WebApp: {
    initData: initDataRaw,
    initDataUnsafe: initData,
  },
};

const token = signal<IAuthorizationResponse | null>(null);
export const userData = signal<User | null>(null);

effect(() => {
  if (userData.value) {
    // Apply analytics to only every 3rd user
    shouldSendAnalyticsEvents.value = isAnalyticsEnabledForUser(userData.value);
    analyticsAdapter.identify(userData.value);
  } else if (!userData.value) {
    shouldSendAnalyticsEvents.value = true;
  }
});

const AuthenticationContext = createContext<{
  user: User | null;
  token: IAuthorizationResponse | null;
}>({user: null, token: null});

const isAccessToken = (token: object): token is IAuthorizationResponse => {
  return 'access_token' in token && 'token_type' in token;
};

async function getCloudToken(): Promise<IAuthorizationResponse | null> {
  const savedToken = await cloudStorage.get('token');

  logger.debug('GetCloudToken', savedToken);
  if (!savedToken) {
    return null;
  }

  try {
    const parsed = JSON.parse(savedToken);

    if (isAccessToken(parsed)) {
      return parsed;
    }

    return null;
  } catch (error) {
    return null;
  }
}

async function saveCloudToken(token: IAuthorizationResponse) {
  await cloudStorage.set('token', JSON.stringify(token));
}

async function clearCloudToken() {
  await cloudStorage.delete('token');
}

export function useUserQuery() {
  const token = useAuthorizationToken();

  const query = useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      const userResponse = await UserApi.getUser(token);
      return userResponse.data;
    },
    refetchInterval: 0,
  });

  // TODO: check it works as expected
  useEffect(() => {
    userData.value = query.data || null;
  }, [query.data]);

  return query;
}

export function AuthenticationHandler({
  children,
  renderPending,
  renderError,
}: {
  children: React.ReactNode;
  renderPending: () => React.ReactNode;
  renderError: (error: Error) => React.ReactNode;
}) {
  useSignals();
  const viewport = useViewport();

  const queryClient = useQueryClient();
  const params = useLaunchParams();

  const data = useQuery({
    queryKey: ['auth-user'],
    queryFn: async () => {
      invariant(initDataRaw, 'No init data');

      let _token: IAuthorizationResponse | null = null;

      try {
        // Authorize with Local Token
        logger.debug('[Auth: parseParams]:', params.startParam || '');
        const parsedParams = parseStartParam(params.startParam || '');
        logger.debug('[Auth: parseParams]: done', parsedParams);
        const tokenResponse = await UserApi.authorizeUser({
          initDataRaw,
          refCode: parsedParams.refCode,
          utmCode: parsedParams.utmCode,
        });

        await saveCloudToken(tokenResponse.data);
        logger.debug('[Auth]: Token received from InitParams');
        _token = tokenResponse.data;
      } catch (error) {
        if (isAxiosError(error) && error.response) {
          {
            if (error.response.status >= 500) {
              manualSentryClient.captureEvent(
                {
                  message: 'Token responded with 500+ status',
                },
                {
                  originalException: error,
                },
              );
            }
          }
        }
        logger.debug('[Auth]: Fallback to cloud token', error);

        // Authorize with Cloud Token
        // retry with cloud token if it exists
        const cloudToken = await getCloudToken();

        if (!cloudToken) {
          logger.debug('[Auth]: Cloud token is empty. Failed authentication');
        }

        _token = cloudToken;
      }

      if (!_token) {
        throw new Error('Could not authenticate user');
      }

      try {
        const userResponse = await UserApi.getUser(_token);
        token.value = _token;

        userData.value = userResponse.data;
        queryClient.setQueryData(['user'], userResponse.data);

        return token.value.access_token;
      } catch (error) {
        await clearCloudToken();
        throw error;
      }
    },
    enabled: !!initDataRaw && !token.value,
    refetchInterval: 0,
    refetchOnMount: false,
    retry: false,
  });

  useEffect(() => {
    if (userData.value && viewport) {
      viewport.expand();
    }
  }, [userData.value]);

  if (data.isError) {
    return renderError(data.error);
  }

  if (!userData.value) {
    return renderPending();
  }

  return (
    <AuthenticationContext.Provider
      value={{user: userData.value, token: token.value}}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}

export function useCurrentUser(): User {
  const contextValue = useContext(AuthenticationContext);
  const user = contextValue.user;

  invariant(user, 'User Should be Defined');
  return user;
}

export function useAuthorizationToken(): IAuthorizationResponse {
  const contextValue = useContext(AuthenticationContext);
  const token = contextValue.token;

  invariant(token, 'Token Should be Defined');
  return token;
}
