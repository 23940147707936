import {rem} from '~/shared/utils/style-helpers';
import helpIcon from './assets/help-icon.png';
import helpIconAlternative from './assets/help-icon-alternative.png';
import {useTranslation} from 'react-i18next';
import {useAppTheme} from '~/components/kit';
import React from 'react';

export function HowItWorks({
  onClick,
  isAlternative,
  ...rest
}: {
  onClick?: () => void;
  isAlternative?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const {t} = useTranslation();
  const theme = useAppTheme();

  return (
    <button
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontWeight: 800,
        textTransform: 'uppercase',
        fontSize: theme.fontSize.odds.s13,
        color: isAlternative
          ? theme.colors.accents.yellow.surface
          : theme.colors.onSurface,
        cursor: 'pointer',
      }}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      {...rest}
    >
      <img
        src={isAlternative ? helpIconAlternative : helpIcon}
        alt="help icon"
        css={{
          width: rem(24),
          height: rem(24),
          marginRight: rem(8),
          // compensate for the icon's shadow
          marginTop: rem(-2),
        }}
      />
      <span>{t('onboarding.howItWorks')}</span>
    </button>
  );
}
