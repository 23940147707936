import {useAppTheme} from '~/components/kit';
import {Modal} from '~/components/kit/modal';
import {rem} from '~/shared/utils/style-helpers';
import {useNavigate} from 'react-router-dom';
import {Typography} from '~/components/kit/typography';
import {formatWithCommas, toFloatCrystals} from '~/shared/utils/format/format-numbers';
import {Button} from '~/components/kit/button/button';
import {RoutePath} from '~/app/navigation/routes';
import {BalanceCrystalsIcon} from '../assets/balance-crystals-icon';
import {useDockModel} from '~/entities/dock';

export const CRYSTALS_MENU_MODAL_NAME = 'crystals-menu-modal';

export function CrystalsMenuModal({afterClose, top, floatingMenuIcon}: {afterClose?: () => void, top: number, floatingMenuIcon?: React.ReactNode}) {
  const theme = useAppTheme();
  const dockModel = useDockModel();

  const navigate = useNavigate();
  const balance = formatWithCommas(toFloatCrystals(dockModel.dock.crystal_balance));

  const modalStyles = {
    top: rem(top),
    marginLeft: 'auto',
    width: rem(220),
    padding: rem(8),
  }

  return (
    <Modal
      name={CRYSTALS_MENU_MODAL_NAME}
      isClosable={true}
      afterClose={afterClose}
      radius={16}
      borderWidth={1}
      hideCloseButton={true}
      css={modalStyles}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: rem(8),
        }}
      >
        {floatingMenuIcon}
        <p
          css={{
            fontWeight: 500,
            marginTop: rem(8),
            fontSize: theme.fontSize.odds.s13,
            color: theme.colors.onSurfaceTertiary,
            paddingLeft: rem(8),
          }}
        >
            Moon Crystals Balance
        </p>

        <Typography.h4 css={{display: 'flex', alignItems: 'center', gap: rem(4), paddingLeft: rem(8)}}>
          <BalanceCrystalsIcon /> {' '}
          {balance}
        </Typography.h4>

        <section css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: rem(8),
          backgroundColor: '#EBE2FF',
          padding: rem(16),
          paddingTop: rem(8),
          marginTop: rem(8),
          borderRadius: rem(8),
        }}>
          <Typography.h6 css={{marginBottom: rem(0)}}>Boost your Balance now!</Typography.h6>

          <Button onClick={() => {
            navigate(RoutePath.Earn);
          }} color={'white'} css={{width: '100%', height: rem(40)}}>
            Earn for free
          </Button>
        </section>
      </div>
    </Modal>
  );
}
