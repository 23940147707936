import {apiClient} from '../shared/api-client';
import {IAuthorizationResponse} from '../user';
import {IUserFriend, IUserFriendRequestInfo} from './interfaces';

function getFriendsList(token: IAuthorizationResponse) {
  return apiClient.get<IUserFriend[]>('/users/friends/', {
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });
}

function getFriendInfoByFriendCode(
  {
    friendCode,
  }: {
    friendCode: string;
  },
  token: IAuthorizationResponse,
) {
  return apiClient.get<IUserFriendRequestInfo>(
    `/users/friends/info/${friendCode}/`,
    {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    },
  );
}

function deleteFriend(
  {friendId}: {friendId: string},
  token: IAuthorizationResponse,
) {
  return apiClient.delete(`/users/friends/${friendId}/`, {
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });
}

function addFriend(
  {
    friendCode,
  }: {
    friendCode: string;
  },
  token: IAuthorizationResponse,
) {
  return apiClient.post(
    `/users/friends/`,
    {
      friend_code: friendCode,
    },
    {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    },
  );
}

export const FriendsApi = {
  getFriendsList,
  getFriendInfoByFriendCode,
  deleteFriend,
  addFriend,
};
