import {apiClient} from '../shared/api-client';
import {IAuthorizationResponse} from '../user';
import {UserAirdrop} from './interfaces';

function getUserClaimedAirdrops(token: IAuthorizationResponse) {
  return apiClient.get<UserAirdrop[]>('/airdrops/', {
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });
}

function getAirdrop(name: string, token: IAuthorizationResponse) {
  return apiClient.get<UserAirdrop | null>(`/airdrops/${name}/`, {
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });
}

function claimAirdrop(name: string, token: IAuthorizationResponse) {
  return apiClient.post<UserAirdrop>(`/airdrops/${name}/claim/`, null, {
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });
}

export const AirdropApi = {
  getUserClaimedAirdrops,
  getAirdrop,
  claimAirdrop,
};
