import {IAuthorizationResponse} from '~/api';
import {apiClient} from '~/api/shared/api-client';
import {ServerTimestamp} from '~/shared/utils/format/format-time';

export interface IDailyMissionStats {
  claimed_at: ServerTimestamp;
  streak_days: number;
  streak_top_percent: number;
  can_claim_at: ServerTimestamp;
}

function getDailyMissionStats(token: IAuthorizationResponse) {
  return apiClient.get<IDailyMissionStats>('/daily-reward/', {
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });
}

function claimDailyMission(token: IAuthorizationResponse) {
  return apiClient.post<IDailyMissionStats>(
    '/daily-reward/',
    {},
    {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    },
  );
}

export const DailyMissionApi = {
  getDailyMissionStats,
  claimDailyMission,
};
