import {Card, CardHeader, useAppTheme} from '~/components/kit';
import {useTrailblazerModel} from '../../model';
import {Typography} from '~/components/kit/typography';
import {rem} from '~/shared/utils/style-helpers';
import {Box} from '~/components/kit/box';
import {IPlanetGuess} from '../../api/trailblazer-api';
import {
  formatTimestamp,
  formatWeekDayUTC,
  ServerTimestamp,
  toClientTimestamp,
} from '~/shared/utils/format/format-time';
import {opacity} from '~/shared/utils/colors';
import {ImgTransition} from '~/components/kit/img-transition';
import starImgSvg from './assets/traiblazer-daily-star.svg';

const TOMORROW = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

function getDateTextNode(date: Date) {
  const dateSplitted = formatWeekDayUTC(date.getTime()).split(' ');

  return (
    <span>
      {dateSplitted[0]}&nbsp;{dateSplitted[1]}
      <br />
      {dateSplitted[2]}
    </span>
  );
}
function PastDayItem({date}: {date: Date}) {
  return (
    <div css={{}}>
      <Box
        backgroundColor={'#D8D8EE'}
        css={{
          width: rem(38),
          height: rem(38),
        }}
        radius={8}
      ></Box>

      <Typography.h8
        css={{
          textAlign: 'center',
          color: '#C2C0DC',
          marginTop: rem(4),
        }}
      >
        {getDateTextNode(date)}
      </Typography.h8>
    </div>
  );
}

function FutureDayItem({date}: {date: Date}) {
  return (
    <div>
      <Box
        backgroundColor={opacity('#D8D8EE', 0.31)}
        css={{
          width: rem(38),
          height: rem(38),
        }}
        radius={8}
      ></Box>

      <Typography.h8
        css={{
          textAlign: 'center',
          color: '#C2C0DC',
          marginTop: rem(4),
        }}
      >
        {getDateTextNode(date)}
      </Typography.h8>
    </div>
  );
}

function TodayDayItem({date}: {date: Date}) {
  const theme = useAppTheme();

  return (
    <div>
      <Box
        borderWidth={1}
        shadowOffsetY={0}
        shadowColor={theme.colors.onSurface}
        backgroundColor={opacity('#D8D8EE', 1)}
        css={{
          width: rem(38),
          height: rem(38),
          color: theme.colors.onSurface,
        }}
        radius={8}
      ></Box>

      <Typography.h8
        css={{
          textAlign: 'center',
          color: theme.colors.onSurface,
          marginTop: rem(4),
        }}
      >
        {getDateTextNode(date)}
      </Typography.h8>
    </div>
  );
}

function HistoryDayItem({item, date}: {item: IPlanetGuess | null; date: Date}) {
  const theme = useAppTheme();

  const isFuture = date.getTime() > new Date().getTime();
  const isPast = date.getTime() < new Date().getTime();
  const isToday = date.getUTCDate() === new Date().getUTCDate();
  if (isFuture && !item) {
    return <FutureDayItem date={date} />;
  } else if (isPast && !item) {
    return <PastDayItem date={date} />;
  } else if (isToday && !item) {
    return <TodayDayItem date={date} />;
  }

  const isFullyDecoded = item?.planets_decoded_count === 9;

  return (
    <div css={{}}>
      {isFullyDecoded ? (
        <Box
          backgroundColor="#DCF9FE"
          css={{
            width: rem(38),
            height: rem(38),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          radius={8}
          bgImage={
            <ImgTransition
              src={starImgSvg}
              css={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '110%',
                height: '110%',
              }}
            />
          }
        ></Box>
      ) : (
        <Box
          backgroundColor={'#DCF9FE'}
          css={{
            width: rem(38),
            height: rem(38),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          radius={8}
        >
          <Typography.h4
            css={{
              color: '#00B754',
            }}
          >
            {item?.planets_decoded_count}
          </Typography.h4>
        </Box>
      )}

      <Typography.h8
        css={{
          marginTop: rem(4),
          textAlign: 'center',
          color: '#C2C0DC',
        }}
      >
        {getDateTextNode(date)}
      </Typography.h8>
    </div>
  );
}

export function TrailblazerHistory({
  ...rest
}: {} & React.HTMLProps<HTMLDivElement>) {
  const model = useTrailblazerModel();
  const theme = useAppTheme();

  const findItemForDateByDay = (date: Date, items: IPlanetGuess[]) => {
    const item = items.find((item) => {
      const itemDate = new Date(
        toClientTimestamp(item.created_at),
      ).getUTCDate();
      return itemDate === date.getUTCDate();
    });

    return item;
  };

  const startDate = new Date(TOMORROW.getTime() + 24 * 60 * 60 * 1000 * 2);

  if (model.guessHistory.data.length === 0) {
    return null;
  }

  return (
    <Card rounding={'small'} {...rest}>
      <CardHeader title="Decode History"></CardHeader>
      <div
        css={{
          display: 'flex',
        }}
      >
        <div>
          <Typography.h7
            css={{
              color: theme.colors.onSurfaceTertiary,
            }}
          >
            Planets decoded:
          </Typography.h7>
          <Typography.h6
            css={{
              fontSize: theme.fontSize.odds.s15,
              color: theme.colors.success.onSurface,
              fontWeight: 800,
              marginTop: rem(4),
            }}
          >
            {model.guessHistory.data.reduce(
              (a, b) => a + b.planets_decoded_count,
              0,
            )}
          </Typography.h6>
        </div>
      </div>
      <div
        css={{
          display: 'flex',
          gap: rem(4),
          marginTop: rem(16),
          marginLeft: rem(-20),
          marginRight: rem(-20),
          position: 'relative',
          paddingTop: rem(4),
          paddingBottom: rem(4),
          overflow: 'hidden',
          // Two linear gradients from transparent to white to hide ends of the items
          '&::before, &::after': {
            content: "''",
            position: 'absolute',
            top: 0,
            width: rem(32),
            bottom: 0,
            height: '100%',
            zIndex: 2,
          },

          '&::before': {
            left: 0,
            backgroundImage: `linear-gradient(90deg, ${theme.colors.surface} 0%, rgba(255, 255, 255, 0) 100%)`,
          },

          '&::after': {
            right: 0,
            backgroundImage: `linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, ${theme.colors.surface} 100%)`,
          },
        }}
      >
        {Array.from({length: 9}, (item: number, index: number) => (
          <HistoryDayItem
            date={
              new Date(startDate.getTime() - (9 - index) * 24 * 60 * 60 * 1000)
            }
            item={
              findItemForDateByDay(
                new Date(
                  startDate.getTime() - (9 - index) * 24 * 60 * 60 * 1000,
                ),
                model.guessHistory.data,
              ) || null
            }
          />
        ))}
      </div>
    </Card>
  );
}
