import React from 'react';

import {useRewards} from '~/entities/rewards';
import {
  RewardCard,
  RewardsCardDescription,
  RewardsCardTitle,
} from './reward-card';
import {Button} from '~/components/kit/button/button';
import {Reward} from '../reward/reward';
import {Link, useNavigate} from 'react-router-dom';
import {RoutePath} from '~/app/navigation/routes';
import {rem} from '~/shared/utils/style-helpers';
import planetBlueSvg from './assets/planet-blue.svg?url';
import planetDustySvg from './assets/planet-dusty.svg?url';
import planetPurpleSvg from './assets/planet-purple.svg?url';
import {ImgTransition} from '~/components/kit/img-transition';

export function StakesReward() {
  const rewards = useRewards();

  const reward = rewards.model.getRewardByName('stake');
  const userReward = rewards.model.userReward('stake');

  const isReady = rewards.isReady;
  let isClaimed = rewards.model.isClaimed('stake') && isReady;

  const navigate = useNavigate();

  return (
    <RewardCard
      css={{
        textAlign: 'center',
        position: 'relative',
      }}
      isDone={isClaimed}
    >
      <ImgTransition
        src={planetDustySvg}
        css={{
          position: 'absolute',
          zIndex: 2,
          top: rem(0),
          left: rem(-5),
          width: rem(30),
        }}
      />
      <ImgTransition
        src={planetPurpleSvg}
        css={{
          position: 'absolute',
          zIndex: 2,
          top: '50%',
          right: rem(-5),
          width: rem(30),
        }}
      />
      <ImgTransition
        src={planetBlueSvg}
        css={{
          position: 'absolute',
          zIndex: 2,
          bottom: rem(40),
          left: rem(-40),
          width: rem(80),
        }}
      />

      <RewardsCardTitle
        css={{
          marginBottom: rem(8),
        }}
      >
        Make your first trade!
      </RewardsCardTitle>
      <RewardsCardDescription
        css={{
          marginBottom: rem(18),
          padding: `0 ${rem(25)}`,
        }}
      >
        Take the plunge! Will you go all-in or hit the jackpot? 🤑
      </RewardsCardDescription>

      {isClaimed ? (
        <Reward value={reward?.amount || 0} isAlternativeColor={true} />
      ) : (
        <Button
          color="pink"
          onClick={async () => {
            if (
              reward &&
              !userReward &&
              !rewards.claimRewardsMutation.isPending
            ) {
              const result = await rewards.claimRewardsMutation.mutateAsync({
                reward_id: reward.id,
              });

              if (result.data.redeemed === true) {
                return;
              }
            }
            navigate(RoutePath.Stakes);
          }}
          css={{
            width: '100%',
          }}
        >
          Let's go trade
          <Reward
            value={reward?.amount || 0}
            isAlternativeColor={true}
            css={{
              marginLeft: rem(8),
            }}
          />
        </Button>
      )}
    </RewardCard>
  );
}
