import {rem} from '~/shared/utils/style-helpers';
import {LightningLeft} from './assets/lightning-left';
import {LightningRight} from './assets/lightning-right';
import React from 'react';

export function LightningKchau({
  children,
  mainColor,
  raysColor,
  ...rest
}: {
  children?: React.ReactNode;
  mainColor: string;
  raysColor: string[] | string;
} & React.HTMLProps<HTMLDivElement>) {
  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
      }}
      {...rest}
    >
      <div
        css={{
          width: rem(36),
        }}
      >
        <LightningLeft mainColor={mainColor} raysColor={raysColor} />
      </div>
      {children}
      <div
        css={{
          width: rem(36),
        }}
      >
        <LightningRight mainColor={mainColor} raysColor={raysColor} />
      </div>
    </div>
  );
}
