import React from 'react';

interface IStarPosition {
  x: number;
  y: number;
}

interface IStarColor {
  rayColor: string;
  coreColor: string;
}

interface IStarProps extends IStarPosition, IStarColor {
  isOn?: boolean;
  reappearDuration?: number;
  rotation: number;
  starSize: number;
}

export function Star({
  rayColor,
  coreColor,
  isOn = true,
  className,
  starSize,
  reappearDuration = 300,
  rotation,
  x,
  y,
}: IStarProps & React.HTMLProps<HTMLDivElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={{
        top: `calc(${y * 100}%)`,
        left: `calc(${x * 100}%)`,
        width: '4vw',
        height: '4vw',
        transitionDuration: `${reappearDuration}ms`,
        transform: `rotate(${rotation}deg) scale(${starSize}) translate(-50%, -50%)`,
        transformOrigin: 'center',
        opacity: isOn ? 1 : 0,
        position: 'absolute',
      }}
      className={className}
    >
      <rect
        width="9.35317"
        height="9.35316"
        transform="translate(6.50098 1.4834) rotate(30)"
        fill={coreColor}
      />
      <path
        d="M6.50085 1.48315L7.49906 5.2085M9.92435 14.2598L8.92615 10.5345"
        stroke={rayColor}
        strokeWidth="2.06471"
        strokeLinecap="round"
      />
      <path
        d="M14.6011 6.16016L10.8757 7.15836M1.82441 9.58365L5.54976 8.58545"
        stroke={rayColor}
        strokeWidth="2.06471"
        strokeLinecap="round"
      />
    </svg>
  );
}

export function StarShine({
  coreColor,
  isOn = true,
  className,
  starSize,
  reappearDuration = 300,
  rotation,
  x,
  y,
}: Omit<IStarProps, 'rayColor'> & React.HTMLProps<HTMLDivElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      css={{
        top: `calc(${y * 100}%)`,
        left: `calc(${x * 100}%)`,
        width: '4vw',
        height: '4vw',
        transitionDuration: `${reappearDuration}ms`,
        transform: `rotate(${rotation}deg) scale(${starSize}) translate(-50%, -50%)`,
        transformOrigin: 'center',
        opacity: isOn ? 1 : 0,
        position: 'absolute',
      }}
      className={className}
    >
      <path
        d="M6.28259 14.2921C5.41207 10.93 3.51108 8.61388 0.0498047 7.54989C3.55263 6.45288 5.43181 4.03765 6.34699 0.682556C7.2497 4.02464 9.09669 6.4669 12.6525 7.5509C9.05929 8.5258 7.12297 10.8419 6.28155 14.2921H6.28259Z"
        fill={coreColor}
      />
    </svg>
  );
}

export function StarField({
  stars,
  children,
  starsZIndex = 1,
  ...rest
}: {
  stars: React.ReactNode;
  children: React.ReactNode;
  starsZIndex?: number;
} & React.HTMLProps<HTMLDivElement>) {
  return (
    <div
      css={{
        position: 'relative',
      }}
      {...rest}
    >
      {children}
      <div
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          pointerEvents: 'none',
          zIndex: starsZIndex,
        }}
      >
        {stars}
      </div>
    </div>
  );
}
