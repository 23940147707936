export const RokeTokenLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    height="24"
    width="24"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12" cy="12" fill="#FDF278" r="12" />
    <path
      d="M15.0508 6.07363C14.7223 5.72796 14.2666 5.53223 13.7898 5.53223H9.08119C8.66681 5.53223 8.30946 5.82484 8.22854 6.2314L8.07919 6.97685C8.02163 7.26425 8.09712 7.51944 8.24918 7.7149C8.33662 7.82711 8.45066 7.91596 8.51475 7.96277L6.95826 15.9896C6.85398 16.5263 7.2651 17.0249 7.81199 17.0249H10.5709C11.0287 17.0249 11.4078 16.6699 11.4387 16.213L11.6951 12.3657H12.3316L12.8019 12.8606L13.0257 16.213C13.0561 16.6699 13.4357 17.0249 13.8935 17.0249H16.6519C17.1988 17.0249 17.6099 16.5263 17.5056 15.9896L16.6855 11.7596C16.5997 11.3177 16.3896 10.9095 16.0794 10.5831L15.9784 10.4771C16.1881 10.0275 16.2516 9.52144 16.156 9.03086L15.816 7.27598C15.7525 6.9504 15.5977 6.64958 15.3696 6.40915L15.0508 6.07363ZM12.4994 9.13536H11.9101L11.9851 8.01713H12.45L12.4815 8.05026L12.5499 9.08213L12.4994 9.13536Z"
      fill="white"
      stroke="#111320"
      strokeWidth="1.24244"
    />
    <path
      d="M7.41108 18.6235L6.38627 17.6744C6.15926 17.4643 6.06096 17.1495 6.12776 16.8476L6.39224 15.6578L7.01354 16.7138L11.1758 16.9624L11.9834 12.8623L12.729 12.9865L13.4122 16.7138L16.8288 16.6828L18.0714 15.6578L18.3011 16.8981C18.3511 17.1673 18.249 17.4422 18.0361 17.6141L16.7294 18.6679C16.5524 18.8104 16.3324 18.8882 16.1054 18.8882H8.08669C7.83632 18.8882 7.59519 18.7936 7.41108 18.6235Z"
      fill="#111320"
    />
  </svg>
);
