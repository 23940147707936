import React, {useId} from 'react';
import './rocket.css';
import classNames from 'classnames';

export function Rocket({className, ...rest}: React.HTMLProps<HTMLDivElement>) {
  const maskId = useId();

  return (
    <div className={classNames('rocket-container', className)} {...rest}>
      <svg
        className="eg8cSNMUa6w1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 90 41"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <g>
          <g>
            <g transform="translate(-.13916 0)">
              <g
                className="eg8cSNMUa6w5_to"
                style={{
                  offsetPath: 'path("M41.00541,6.80861L4.723772,6.80861")',
                  offsetRotate: '0deg',
                }}
              >
                <g className="eg8cSNMUa6w5_ts" transform="scale(0.6,0.6)">
                  <circle
                    className="eg8cSNMUa6w5"
                    r="5.09091"
                    transform="translate(-0.000003,0)"
                    opacity="0"
                    fill="#f0effc"
                  />
                </g>
              </g>
              <g
                className="eg8cSNMUa6w6_to"
                style={{
                  offsetPath:
                    'path("M43.138867,13.253688Q43.91477,13.253688,3.1875,10.97062")',
                  offsetRotate: '0deg',
                }}
              >
                <g className="eg8cSNMUa6w6_ts" transform="scale(0.6,0.6)">
                  <circle
                    className="eg8cSNMUa6w6"
                    r="6.54545"
                    transform="translate(0,0)"
                    opacity="0"
                    fill="#f0effc"
                  />
                </g>
              </g>
              <g
                className="eg8cSNMUa6w7_to"
                style={{
                  offsetPath:
                    'path("M44.429296,30.227801Q44.330034,30.227801,3.830396,29.731482")',
                  offsetRotate: '0deg',
                }}
              >
                <g className="eg8cSNMUa6w7_ts" transform="scale(0.6,0.6)">
                  <circle
                    className="eg8cSNMUa6w7"
                    r="8"
                    transform="translate(0.000001,0)"
                    opacity="0"
                    fill="#f0effc"
                  />
                </g>
              </g>
              <g
                className="eg8cSNMUa6w8_to"
                style={{
                  offsetPath: 'path("M44.330034,17.6021L7.403895,16.112984")',
                  offsetRotate: '0deg',
                }}
              >
                <g className="eg8cSNMUa6w8_ts" transform="scale(0.6,0.6)">
                  <circle
                    className="eg8cSNMUa6w8"
                    r="8"
                    transform="translate(0,0)"
                    opacity="0"
                    fill="#f0effc"
                  />
                </g>
              </g>
              <g
                className="eg8cSNMUa6w9_to"
                style={{
                  offsetPath: 'path("M45.139161,23.477862L4.723772,20.515201")',
                  offsetRotate: '0deg',
                }}
              >
                <g className="eg8cSNMUa6w9_ts" transform="scale(0.6,0.6)">
                  <circle
                    className="eg8cSNMUa6w9"
                    r="6.54545"
                    transform="translate(0,-0.000001)"
                    opacity="0"
                    fill="#f0effc"
                  />
                </g>
              </g>
              <g
                className="eg8cSNMUa6w10_to"
                style={{
                  offsetPath: 'path("M43.914771,27.0566L2.639232,26.157984")',
                  offsetRotate: '0deg',
                }}
              >
                <g className="eg8cSNMUa6w10_ts" transform="scale(0.6,0.6)">
                  <circle
                    className="eg8cSNMUa6w10"
                    r="5.09091"
                    transform="translate(0.000002,0.000001)"
                    opacity="0"
                    fill="#f0effc"
                  />
                </g>
              </g>
              <g
                className="eg8cSNMUa6w11_to"
                style={{
                  offsetPath:
                    'path("M45.13916,32.8755Q6.411256,35.0572,6.212728,35.0572")',
                  offsetRotate: '0deg',
                }}
              >
                <g className="eg8cSNMUa6w11_ts" transform="scale(0.6,0.6)">
                  <circle
                    className="eg8cSNMUa6w11"
                    r="3.63636"
                    transform="translate(0,0.000001)"
                    opacity="0"
                    fill="#f0effc"
                  />
                </g>
              </g>
              <g
                className="eg8cSNMUa6w12_to"
                style={{
                  offsetPath: 'path("M36.286806,9.26918L4.227452,9.6021")',
                  offsetRotate: '0deg',
                }}
              >
                <g className="eg8cSNMUa6w12_ts" transform="scale(0.6,0.6)">
                  <circle
                    className="eg8cSNMUa6w12"
                    r="3.63636"
                    transform="translate(0.000001,0)"
                    opacity="0"
                    fill="#f0effc"
                  />
                </g>
              </g>
              <circle
                r="3.63636"
                transform="translate(6.6847 3.78392)"
                opacity="0"
                fill="#f0effc"
              />
            </g>
          </g>
          <g>
            <path
              d="M86.9429,21.9253c.6386-.796.6402-1.9283.0038-2.726-2.6901-3.3722-10.5623-9.65493-21.8698-9.93012-1.7408-1.24919-3.441-2.17641-5.591-2.77686-2.3309-.65096-5.0647-.88194-8.763-.88708-.8264-.00114-1.5826.46472-1.9532,1.20337s-.2921,1.6233.2029,2.28511c2.5358,3.39098,4.0991,6.57958,4.0923,11.42148s-1.5788,8.0262-4.124,11.4101c-.4968.6604-.5778,1.5449-.2092,2.2845s1.1233,1.2077,1.9498,1.2088c3.6983.0052,6.4327-.2182,8.7654-.8627c2.1517-.5945,3.8544-1.517,5.5987-2.7613c11.3082-.2438,19.1979-6.5046,21.8973-9.8693Z"
              fill="#cacde2"
              stroke="#f0eeff"
              strokeWidth="4.36364"
              strokeLinejoin="round"
            />
            <g mask={`url(#${maskId})`}>
              <g>
                <path
                  d="M85.5936,20.5118c6.1706,9.0601-6.2287,16.1121-21.2704,12.3637-3.6319-2.7318-10.0034,2.1918-17.2747,2.1817c2.7217,3.6394,5.0984-24.3625,5.0908-18.909-.0075,5.4535,1.2771,5.8178-1.4547,9.4497c7.2714.0101,9.9994-.8951,13.6388-3.6168c10.9071.0152,21.2702-1.4693,21.2702-1.4693Z"
                  fill="#111320"
                />
              </g>
              <mask
                id={maskId}
                mask-type="alpha"
                x="-150%"
                y="-150%"
                height="400%"
                width="400%"
              >
                <path
                  d="M85.2411,20.5599c-2.4196-3.0331-9.9855-9.1031-20.8925-9.1182-3.6319-2.73183-6.3574-3.64454-13.6288-3.65464c2.7217,3.63944,4.5345,7.27764,4.527,12.73124-.0076,5.4535-1.8305,9.0866-4.5623,12.7185c7.2713.0101,9.9994-.895,13.6388-3.6167c10.9071.0151,18.4898-6.0338,20.9178-9.0602Z"
                  fill="#fff"
                />
              </mask>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}
