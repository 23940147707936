import {useEffect} from 'react';
import lazyWithPreload from '~/shared/react-extensions/lazy-component';

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const LazyMiningPage = lazyWithPreload(
  () => import('~/pages/mining-page'),
);
export const LazyReferralsPage = lazyWithPreload(
  () => import('~/pages/referrals-page'),
);
export const LazyTradingPage = lazyWithPreload(
  () => import('~/pages/trading-page'),
);
export const LazyRewardsPage = lazyWithPreload(async () => {
  return import('~/pages/rewards-page');
});

export const FriendsLazyPage = lazyWithPreload(async () => {
  return import('~/pages/friends-page');
});

export const LeaderboardLazyPage = lazyWithPreload(async () => {
  return import('~/pages/leaderboards-page');
});

export const LeaderboardMiningLazyPage = lazyWithPreload(async () => {
  return import(
    '~/pages/leaderboards-page/mining-leaderboard/mining-leaderboard'
  );
});

export const LeaderboardStakesLazyPage = lazyWithPreload(async () => {
  return import(
    '~/pages/leaderboards-page/stakes-leaderboard/stakes-leaderboard'
  );
});

export const EarnLazyPage = lazyWithPreload(async () => {
  return import('~/pages/earn-page/earn-page');
});

export function usePreloadPages() {
  useEffect(() => {
    LazyMiningPage.preload();
    LazyReferralsPage.preload();
    LazyTradingPage.preload();
    LazyRewardsPage.preload();
    FriendsLazyPage.preload();
    LeaderboardLazyPage.preload();
  }, []);
}
