// Incoming startParam format example:
// ref_RXAV_utm_Uwqem_open_stakes
// valid values for open:
// referrals | stakes | rewards

const parseKey = (key: string, startParams: string) => {
  const values = startParams.split('_');
  const index = values.indexOf(key);
  if (index !== -1) {
    const value = values[index + 1];
    if (value) {
      return value;
    }
  }

  return null;
};

export const parseStartParam = (
  startParam: string,
): {
  refCode?: string;
  utmCode?: string;
  friendCode?: string;
  open?: string;
} => {
  const params: {
    refCode?: string;
    utmCode?: string;
    open?: string;
    friendCode?: string;
  } = {};

  const ref = parseKey('ref', startParam);
  if (ref) {
    params['refCode'] = ref;
  }

  const utm = parseKey('utm', startParam) || parseKey('r', startParam);
  if (utm) {
    params['utmCode'] = utm;
  }

  const open = parseKey('open', startParam);

  if (open) {
    params['open'] = open;
  }

  const friend = parseKey('fr', startParam);

  if (friend) {
    params['friendCode'] = friend;
  }

  return params;
};

export const isValidOpen = (open: string) => {
  return ['referrals', 'stakes', 'rewards'].includes(open);
};
