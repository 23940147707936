import {apiClient} from '../shared/api-client';
import {IAuthorizationResponse, User} from '../user';

export function connectWallet(
  {
    address,
  }: {
    address: string;
  },
  token: IAuthorizationResponse,
) {
  return apiClient.post<User>(
    '/users/wallet-address/',
    {
      wallet_address: address,
    },
    {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    },
  );
}

export function disconnectWallet(token: IAuthorizationResponse) {
  return apiClient.delete('/users/wallet-address/', {
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });
}

export const EarnApi = {
  connectWallet,
  disconnectWallet,
};
