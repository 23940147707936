import React from 'react';
import {useAppTheme} from '~/components/kit';
import {Box} from '~/components/kit/box';
import {Delimiter} from '~/components/kit/delimiter';
import {Typography} from '~/components/kit/typography';
import {FallingImages} from '~/features/ads/animation';
import {
  AIRDROP_DISTRIBUTION_DATE,
  AIRDROP_START_DATE,
} from '~/shared/feature-flags';
import {formatCountdownWords} from '~/shared/utils/format/format-time';
import {rem} from '~/shared/utils/style-helpers';

export function AirdropDistributionCounter(
  props: React.HTMLProps<HTMLDivElement>,
) {
  const theme = useAppTheme();

  return (
    <Box
      backgroundColor="#282142"
      radius={30}
      css={{
        padding: rem(16),
        color: theme.colors.onSurfaceAlternative,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: rem(120),
      }}
      {...props}
    >
      <Typography.h1
        css={{
          lineHeight: 1,
          marginBottom: rem(8),
        }}
      >
        Airdrop Incoming
      </Typography.h1>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: rem(8),
          marginTop: rem(8),
        }}
      >
        <Delimiter color={theme.colors.surface}></Delimiter>
        <span
          css={{
            marginLeft: rem(10),
            marginRight: rem(10),
          }}
        >
          in
        </span>
        <Delimiter color={theme.colors.surface}></Delimiter>
      </div>
      <Typography.h4
        css={{
          lineHeight: 1,
          fontSize: rem(22),
        }}
      >
        {formatCountdownWords(AIRDROP_DISTRIBUTION_DATE)}
      </Typography.h4>
    </Box>
  );
}
