import {rem} from '~/shared/utils/style-helpers';
import {DoneRibbonIcon} from './done-ribbon-icon';
import {useAppTheme} from '../theme';

export function DoneRibbon({
  isDone,
  isSoon,
  ...props
}: {
  isDone: boolean;
  isSoon: boolean;
} & React.HTMLProps<HTMLDivElement>) {
  const isRibbonShown = isDone || isSoon;
  const theme = useAppTheme();

  return (
    <div
      css={{
        transform: 'rotate(15deg)',
        transformOrigin: '50%',
        position: 'absolute',
        top: rem(10),
        right: rem(-10),
        visibility: isRibbonShown ? 'visible' : 'hidden',
        padding: rem(5),
        minWidth: rem(65),
        paddingRight: rem(10),
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        fontWeight: 700,

        filter: isSoon ? 'grayscale(1)' : 'none',
      }}
      {...props}
    >
      <DoneRibbonIcon
        isDone={isDone}
        isSoon={isSoon}
        css={{
          position: 'absolute',
          top: rem(2),
          left: rem(-1),
          width: '100%',
          height: '100%',
        }}
      />
      <span
        css={{
          position: 'relative',
          color: theme.colors.onSurface,
        }}
      >
        {isDone ? 'Done!' : 'Soon...'}
      </span>
    </div>
  );
}
