import {RewardValue} from '~/components/kit';
import {useDockModel} from '~/entities/dock';
import {
  UpgradeType,
  hourlySpeed,
  formatDockSizeMoons,
} from '~/entities/upgrades';
import {formatNumber} from '~/shared/utils/format/format-numbers';

export function UpgradeValue({
  value,
  type,
}: {
  value: number;
  type: UpgradeType;
}) {
  const dockModel = useDockModel();

  if (type === 'rocket') {
    return (
      <span>
        <RewardValue value={hourlySpeed(value)} iconSize="1em" />
        &nbsp;/h
      </span>
    );
  } else if (type === 'dock') {
    return (
      <RewardValue
        value={formatDockSizeMoons(
          value * dockModel.dock.combined_lunar_loot_speed,
        )}
      ></RewardValue>
    );
    // return (
    //   <span>{msToHours(toClientTimestamp(value as ServerTimestamp))}h</span>
    // );
  } else if (type === 'staking') {
    return (
      <span>
        <RewardValue value={formatNumber(Number(value) * 100) + '%'} />
      </span>
    );
  }
}
