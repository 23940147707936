import {IAuthorizationResponse} from '../user';
import {apiClient} from '../shared/api-client';
import {
  IDockInfoResponse,
  IUpgradeCostResponse,
  UpgradeName,
} from './interfaces';

function getDockInfo(token: IAuthorizationResponse) {
  return apiClient.get<IDockInfoResponse>('/dock/', {
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });
}

function getUpgradeCost<T extends UpgradeName>(
  upgradeName: T,
  token: IAuthorizationResponse,
) {
  return apiClient.get<IUpgradeCostResponse<T>>(
    `/dock/upgrades/${upgradeName}`,
    {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    },
  );
}

export interface IMultipleUpgradesResponse {
  lunar_loot_speed_upgrades: {value: number; cost: number}[];
  dock_size_upgrades: {value: number; cost: number}[];
  yield_percentage_upgrades: {value: number; cost: number}[];
}

function getUpgrades(token: IAuthorizationResponse) {
  return apiClient.get<IMultipleUpgradesResponse>('/dock/upgrades/', {
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });
}

function buyUpgrade<T extends UpgradeName>(
  upgradeName: T,
  token: IAuthorizationResponse,
) {
  return apiClient.post<IDockInfoResponse>(
    `/dock/upgrades/${upgradeName}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    },
  );
}

function getSpeedUpgradeCost(token: IAuthorizationResponse) {
  return getUpgradeCost('lunar_loot_speed', token);
}

function getDockUpgradeCost(token: IAuthorizationResponse) {
  return getUpgradeCost('dock_size', token);
}

function getStakingUpgradeCost(token: IAuthorizationResponse) {
  return getUpgradeCost('yield_percentage', token);
}

function buySpeedUpgrade(token: IAuthorizationResponse) {
  return buyUpgrade('lunar_loot_speed', token);
}

function buyDockUpgrade(token: IAuthorizationResponse) {
  return buyUpgrade('dock_size', token);
}

function buyStakingUpgrade(token: IAuthorizationResponse) {
  return buyUpgrade('yield_percentage', token);
}

async function claimYield(token: IAuthorizationResponse) {
  return apiClient.post(
    '/dock/yield-claim/',
    {},
    {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    },
  );
}

function claimMoons(token: IAuthorizationResponse) {
  return apiClient.post(
    '/dock/idle-mining/',
    {},
    {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    },
  );
}

function throwInBlackHole(
  data: {
    amount: number;
  },
  token: IAuthorizationResponse,
) {
  return apiClient.post<IDockInfoResponse>(
    '/dock/throw-in-black-hole/',
    {
      amount: data.amount,
    },
    {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    },
  );
}

export const DockApi = {
  getDockInfo,
  getUpgradeCost,
  buyUpgrade,
  claimYield,
  claimMoons,
  getUpgrades,
  throwInBlackHole,
  speed: {
    getUpgradeCost: getSpeedUpgradeCost,
    buyUpgrade: buySpeedUpgrade,
  },
  size: {
    getUpgradeCost: getDockUpgradeCost,
    buyUpgrade: buyDockUpgrade,
  },
  staking: {
    getUpgradeCost: getStakingUpgradeCost,
    buyUpgrade: buyStakingUpgrade,
  },
};
