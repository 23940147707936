import {computed, effect, signal} from '@preact/signals-react';
import {logger} from '../debug';

const debugMsg = (...msg: any[]) => {
  logger.debug(`[LaunchActions]`, ...msg);
};

const launchActionsUnsorted = signal<{name: string; priority: number}[]>([]);
const launchActionsSorted = computed(() => {
  return launchActionsUnsorted.value.sort((a, b) =>
    a.priority > b.priority ? -1 : 1,
  );
});

// This is a computed signal that returns the action with the highest priority
const nextLaunchAction = computed(() => {
  return launchActionsSorted.value[0];
});

effect(() => {
  debugMsg(
    '[LaunchActions]:',
    launchActionsSorted.value.map((a) => a.name).join(' -> '),
  );
});

effect(() => {
  debugMsg('NextAction:', nextLaunchAction.value?.name);
});

function addLaunchAction({name, priority}: {name: string; priority: number}) {
  debugMsg('Adding action', {name, priority});
  // If the action is already in the list, don't add it again
  if (launchActionsUnsorted.value.some((action) => action.name === name)) {
    return;
  }

  launchActionsUnsorted.value = [
    ...launchActionsUnsorted.value,
    {name, priority},
  ];
}

function removeLaunchAction(name: string) {
  debugMsg('Removing action', name);
  launchActionsUnsorted.value = launchActionsUnsorted.value.filter(
    (action) => action.name !== name,
  );
}

export const launchActions = {
  add: addLaunchAction,
  remove: removeLaunchAction,
  next: nextLaunchAction,
};
