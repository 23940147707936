import {useTranslation} from 'react-i18next';
import {useDockModel} from '~/entities/dock';
import {UpgradeType} from '~/entities/upgrades';
import {useUpgradeModel} from '~/features/upgrades/upgrades';

export function useUpgradeUiModel(type: UpgradeType) {
  const dock = useDockModel();
  const model = useUpgradeModel(type);

  const {t} = useTranslation();

  const texts = {
    title:
      type === 'dock'
        ? t('upgrades.dock.title')
        : type === 'rocket'
        ? t('upgrades.rocket.title')
        : t('upgrades.staking.title'),
    description:
      type === 'dock'
        ? t('upgrades.dock.description')
        : type === 'rocket'
        ? t('upgrades.rocket.description')
        : t('upgrades.staking.description'),
    upgradeProperty:
      type === 'dock'
        ? t('upgrades.dock.upgrade')
        : type === 'rocket'
        ? t('upgrades.rocket.upgrade')
        : t('upgrades.staking.upgrade'),
  };

  const hasEnoughBalance = model.upgrade.cost <= dock.dock.balance;

  const needToEarn = model.upgrade.cost - dock.dock.balance;

  return {
    model,
    texts,
    hasEnoughBalance,
    needToEarn,
  };
}
