import React from 'react';

export function Delimiter({
  color,
  isVertical,
  ...rest
}: React.HTMLProps<HTMLDivElement> & {
  color?: string;
  isVertical?: boolean;
}) {
  if (isVertical) {
    return (
      <div
        css={{
          width: '1px',
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'stretch',
        }}
        {...rest}
      >
        <svg
          viewBox="0 0 1 100"
          preserveAspectRatio="none"
          css={{
            height: 0,
            width: '100%',
            flexGrow: 1,
          }}
        >
          <line
            stroke={color}
            x1={0}
            y1={0}
            x2={1}
            y2={100}
            vectorEffect="non-scaling-stroke"
            strokeDasharray={'2,4'}
            strokeWidth={2}
          />
        </svg>
      </div>
    );
  }

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
      }}
      {...rest}
    >
      <svg
        viewBox="0 0 100 1"
        height="1"
        width="100%"
        preserveAspectRatio="none"
      >
        <line
          stroke={color}
          x1={0}
          y1={0}
          x2={100}
          y2={1}
          vectorEffect="non-scaling-stroke"
          strokeDasharray={'2,7'}
        />
      </svg>
    </div>
  );
}
