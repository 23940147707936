import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {useAuthorizationToken} from '~/entities/user';
import {DailyMissionApi, IDailyMissionStats} from './api/daily-mission-api';
import {
  ServerTimestamp,
  toClientTimestamp,
} from '~/shared/utils/format/format-time';
import {isQueryReady} from '~/shared/utils/query';
import {makeAxiosResponse} from '~/api/shared/api-client';
import {getDailyRewardsArray} from './lib';
import {useDockModel} from '~/entities/dock';
import {IDockInfoResponse} from '~/api/dock';
import {trackDailyMissionClaim} from './analytics';
import {RewardType} from '~/entities/rewards';

const EMPTY_DAILY_STATS: IDailyMissionStats = {
  claimed_at: 0 as ServerTimestamp,
  streak_days: 0,
  streak_top_percent: 0,
  can_claim_at: 0 as ServerTimestamp,
};

export const DAYLY_REWARD_TYPES: RewardType[] = ['lunar', 'crystal', 'lunar', 'crystal', 'lunar', 'lunar', 'crystal'];
export const DAYLY_CRYSTAL_REWARD_VALUES = [0, 100, 0, 200, 0, 0, 200];

class DailyMissionCalculator {
  statsData: IDailyMissionStats;
  dockData: IDockInfoResponse;

  constructor(statsData: IDailyMissionStats, dockData: IDockInfoResponse) {
    this.statsData = statsData;
    this.dockData = dockData;
  }

  canClaim() {
    return toClientTimestamp(this.statsData.can_claim_at) < Date.now();
  }

  get biggestReward() {
    const arr = getDailyRewardsArray(this.dockData.combined_lunar_loot_speed);

    return arr[arr.length - 1];
  }

  get MAX_DISPLAYED_CHECKS() {
    return 5;
  }

  private get _rewardsArray() {
    const rewardsArray = getDailyRewardsArray(
      this.dockData.combined_lunar_loot_speed,
    );

    return rewardsArray;
  }

  get dailyRewardsArray() {
    const overflowingPart = Math.max(
      0,
      this.statsData.streak_days - this.MAX_DISPLAYED_CHECKS,
    );

    const startingPart = this._rewardsArray.slice(overflowingPart);

    const endingPart = Array(7 - startingPart.length).fill(this.biggestReward);

    return startingPart.concat(endingPart);
  }

  get lastClaimReward() {
    if (this.statsData.streak_days === 0) {
      return 0;
    } else if (this.statsData.streak_days <= 7) {
      return this._rewardsArray[this.statsData.streak_days - 1];
    }

    return this.biggestReward;
  }

  get claimReward() {
    if (this.statsData.streak_days === 0) {
      return this.dailyRewardsArray[0];
    }

    const reward =
      this.dailyRewardsArray[Math.min(this.statsData.streak_days - 1, 6)] ||
      this.biggestReward;

    return reward;
  }

  get checkedMarks() {
    // do not show more than 5 checked marks
    // to enable user to see next rewards
    return Math.min(this.statsData.streak_days, this.MAX_DISPLAYED_CHECKS);
  }
}

export function useDailyMission() {
  const token = useAuthorizationToken();
  const dock = useDockModel();
  const queryClient = useQueryClient();

  const statsQuery = useQuery({
    queryKey: ['daily-mission-stats'],
    queryFn: () => DailyMissionApi.getDailyMissionStats(token),
  });

  const statsData =
    statsQuery.data?.data || makeAxiosResponse(EMPTY_DAILY_STATS).data;
  const claimQuery = useMutation({
    mutationKey: ['daily-mission-claim'],
    mutationFn: () => DailyMissionApi.claimDailyMission(token),
    onSuccess: (data) => {
      queryClient.setQueryData(['daily-mission-stats'], data);
      trackDailyMissionClaim(data.data);
    },
  });

  const dailyRewardsArray = getDailyRewardsArray(
    dock.dock.combined_lunar_loot_speed,
  );

  const model = new DailyMissionCalculator(statsData, dock.dock);

  return {
    isReady: isQueryReady(statsQuery),
    statsQuery,
    statsData,
    claimQuery,
    dailyRewardsArray,
    model,
  };
}
