import onboarding1Astronaut from '../assets/onboarding-1-astronaut.png';
import onboarding2Astronaut from '../assets/onboarding-2-astronaut.png';
import onboarding3Astronaut from '../assets/onboarding-3-astronaut.png';

import onboarding1Hello from '../assets/onboarding-1-hello.png';
import {Markdown} from '~/components/kit/markdown';
import {ModalTitle} from '~/components/kit/modal';
import {rem} from '~/shared/utils/style-helpers';

export function Step1() {
  return (
    <div>
      <div
        css={{
          display: 'flex',
          alignItems: 'flex-start',
          overflow: 'hidden',
          height: rem(210),
        }}
      >
        <img
          src={onboarding1Astronaut}
          alt="Astronaut"
          css={{
            width: '70%',
            position: 'relative',
            transform: 'translateX(-15%)',
          }}
        />
        <img
          src={onboarding1Hello}
          alt="Hello"
          css={{
            width: '65%',
            transform: 'translateX(-55%)',
            position: 'relative',
            zIndex: -1,
          }}
        />
      </div>
      <Markdown>
        <p>
          Claim <em>Lunar Coin</em> every 2 hours to increase your chances of
          joining exciting partnerships and receiving cosmic <em>airdrops</em>.
        </p>
        <p>
          Become an early adopter of a groundbreaking <em>L2 blockchain</em> for
          consumer applications!
        </p>
      </Markdown>
    </div>
  );
}

export function Step2() {
  return (
    <div>
      <ModalTitle>How to speed up <em>Lunar Coin</em> mining?</ModalTitle>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: rem(160),
        }}
      >
        <img
          src={onboarding2Astronaut}
          alt="Astronaut"
          css={{
            height: '100%',
            position: 'relative',
          }}
        />
      </div>
      <Markdown>
        <p>
          Use your earned coins to buy <em>speed upgrades</em> which significantly accelerate <em>mining</em>. Upgrade your storage to check in less frequently, or increase your accumulation rates.
        </p>
        <p>
          Or take a risk and try to profitably trade Lunar Coins in the <em>trading section</em>.
        </p>
      </Markdown>
    </div>
  );
}

export function Step3() {
  return (
    <div>
      <ModalTitle>Referrals is your key to success</ModalTitle>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: rem(160),
        }}
      >
        <img
          src={onboarding3Astronaut}
          alt="Astronaut"
          css={{
            height: rem(120),
            maxWidth: '100%',
            position: 'relative',
          }}
        />
      </div>
      <Markdown>
        <p>
          Bring friends to join your crew and earn a 10% of their mining speed. 
        </p>
      </Markdown>
    </div>
  );
}
