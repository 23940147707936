import {rem} from '~/shared/utils/style-helpers';
import {createContext, useContext, useEffect, useState} from 'react';
import {Star, StarField} from '../star';
import React from 'react';
import {useAppTheme} from '../theme';
import {titleCollapsed} from '../typography';
import {Bonus} from '../bonus/bonus';
import {useDvdScreensaver} from 'react-dvd-screensaver';
import {
  useTransition,
  animated,
  useSpringRef,
  useSpring,
} from '@react-spring/web';

import planetSvg from './assets/planet-loader.svg';
import astronautPng from './assets/astronaut-loader.png';

const DEFAULT_DELAY = 500;

const FullscreenLoaderContext = createContext({
  isVisible: false,
  setVisible: (isVisible: boolean) => {},
  setDelay(ms: number) {},

  setChildren: (children: React.ReactNode) => {},
});

export function FullscreenLoaderRoot() {
  return <div id="loading-root"></div>;
}

function FullscreenLoaderScene({
  children,
  isDone,
}: {
  children?: React.ReactNode;
  isDone: boolean;
}) {
  const theme = useAppTheme();
  const {containerRef, elementRef, impactCount} = useDvdScreensaver({
    speed: 1.5,
  });

  const [loadProps, loadApi] = useSpring(
    () => ({
      from: {x: isDone ? 0 : 100},
      to: {x: 100},
      config: {
        mass: 1,
        tension: 1000,
        friction: 1,
      },
    }),
    [isDone],
  );

  useEffect(() => {
    loadApi.start();
  }, []);

  const [springProps, springApi] = useSpring(
    () => ({
      from: {
        transform: `scale(1) rotate(${Math.random() * 80}deg)`,
      },
      to: {
        transform: `scale(0.8) rotate(${Math.random() * -80}deg)`,
      },
      reverse: true,
      loop: true,
      config: {
        mass: 4,
        friction: 1,
        tension: 110,
      },
    }),
    [impactCount],
  );

  return (
    <div
      ref={containerRef}
      css={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        zIndex: 100,
        overflow: 'hidden',
        background: theme.colors.surface,
      }}
    >
      <StarField
        starsZIndex={-1}
        css={{
          flexGrow: 1,
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        stars={
          <React.Fragment>
            <Star
              starSize={1.2}
              x={0.4}
              y={0.17}
              rayColor="rgba(0, 0, 0, 1)"
              coreColor="rgba(255, 184, 239, 1)"
              rotation={40}
            ></Star>
            <Star
              starSize={1.2}
              x={0.59}
              y={0.12}
              rayColor="rgba(0, 0, 0, 1)"
              coreColor="#FFE76A"
              rotation={40}
            ></Star>
            <Star
              starSize={1.2}
              x={0.7}
              y={0.5}
              rayColor="rgba(0, 0, 0, 1)"
              coreColor="#C2B8FF"
              rotation={40}
            ></Star>
          </React.Fragment>
        }
      >
        {typeof children === 'undefined' ? (
          <div>
            <h2
              css={[
                titleCollapsed,
                {
                  fontSize: theme.fontSize.s3,
                  lineHeight: 1,
                  marginBottom: rem(25),
                },
              ]}
            >
              Entering <br /> Your Account
            </h2>
            <Bonus
              color="alternative"
              css={{
                fontWeight: 700,
                fontSize: rem(42),
                padding: `${rem(6)} ${rem(21)}`,
              }}
            >
              {isDone ? 100 : Math.round(loadProps.x.get())}%
            </Bonus>
          </div>
        ) : (
          children
        )}
      </StarField>

      <div
        ref={elementRef}
        css={{
          position: 'absolute',
          width: '30vw',
          zIndex: -1,
        }}
      >
        <animated.img
          src={astronautPng}
          css={{
            width: '100%',
          }}
          style={springProps}
        />
      </div>
      <img
        src={planetSvg}
        css={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          zIndex: -2,
        }}
      />
    </div>
  );
}

function LoaderAnimatedScene({
  children,
  isVisible,
  delay,
}: {
  children?: React.ReactNode;
  isVisible: boolean;
  delay: number;
}) {
  const transRef = useSpringRef();

  const transition = useTransition(isVisible, {
    ref: transRef,
    delay: delay,
    initial: {
      opacity: '100%',
    },
    from: {
      opacity: '100%',
      display: 'flex',
    },
    enter: {
      opacity: '100%',
      display: 'flex',
    },
    leave: {
      opacity: '0%',
    },
    config: {},
  });

  useEffect(() => {
    transRef.start();
  }, [isVisible]);

  return transition((style, item) => {
    if (!item) return null;
    return (
      <animated.div
        style={{...style}}
        css={{
          minHeight: '100vh',
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          zIndex: 100,
        }}
      >
        <FullscreenLoaderScene isDone={!isVisible}>
          {children}
        </FullscreenLoaderScene>
        ;
      </animated.div>
    );
  });
}

export function FullscreenLoaderProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isVisible, setVisible] = useState(false);
  const [delay, setDelay] = useState(DEFAULT_DELAY);

  const [loaderChildren, setChildren] = useState<React.ReactNode>(null);

  return (
    <FullscreenLoaderContext.Provider
      value={{
        isVisible,
        setVisible,
        setDelay,
        setChildren,
      }}
    >
      <LoaderAnimatedScene isVisible={isVisible} delay={delay}>
        {loaderChildren}
      </LoaderAnimatedScene>
      {children}
    </FullscreenLoaderContext.Provider>
  );
}

export function FullscreenLoader({
  delay,
  children,
}: {
  children?: React.ReactNode;
  delay?: number;
}) {
  const context = useContext(FullscreenLoaderContext);

  useEffect(() => {
    context.setVisible(true);
    context.setDelay(typeof delay === 'undefined' ? DEFAULT_DELAY : delay);
    context.setChildren(children);

    return () => {
      context.setVisible(false);
    };
  }, []);

  return null;
}
