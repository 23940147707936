export const GlobeIcon = () => (
  <svg
    height="12"
    width="11"
    fill="none"
    viewBox="0 0 11 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1372_35221)">
      <path
        d="M1.40979 3.92946L3.37514 5.33326C3.4774 5.40631 3.52854 5.44283 3.58205 5.45125C3.62914 5.45865 3.67737 5.45119 3.72001 5.4299C3.76848 5.4057 3.80619 5.35543 3.88159 5.25489L4.29689 4.70116C4.31821 4.67273 4.32887 4.65851 4.3414 4.64625C4.35253 4.63536 4.36474 4.62563 4.37784 4.61722C4.39259 4.60774 4.40883 4.60053 4.4413 4.58609L6.21441 3.79804C6.28791 3.76538 6.32466 3.74904 6.35237 3.72354C6.37688 3.70097 6.39618 3.67334 6.40892 3.64256C6.42332 3.60775 6.426 3.56763 6.43135 3.48737L6.56117 1.53998M6.18746 6.68734L7.38647 7.2012C7.52558 7.26082 7.59513 7.29063 7.63198 7.34014C7.6643 7.38358 7.6802 7.43707 7.67686 7.49111C7.67304 7.55271 7.63107 7.61568 7.54712 7.74161L6.98381 8.58657C6.94401 8.64626 6.92411 8.67611 6.89782 8.69772C6.87455 8.71685 6.84773 8.7312 6.81891 8.73996C6.78634 8.74985 6.75047 8.74985 6.67872 8.74985H5.76424C5.6692 8.74985 5.62169 8.74985 5.58126 8.73394C5.54555 8.7199 5.51395 8.69712 5.48934 8.66769C5.46147 8.63436 5.44644 8.58929 5.41639 8.49913L5.08965 7.51892C5.07193 7.46575 5.06307 7.43916 5.06073 7.41214C5.05866 7.38818 5.06038 7.36404 5.06582 7.34062C5.07195 7.3142 5.08448 7.28913 5.10955 7.23899L5.35774 6.74261C5.40817 6.64175 5.43338 6.59132 5.47222 6.56002C5.50644 6.53243 5.54788 6.51526 5.59159 6.51057C5.64119 6.50525 5.69468 6.52308 5.80165 6.55873L6.18746 6.68734ZM10.0833 5.99984C10.0833 8.53114 8.03126 10.5832 5.49996 10.5832C2.96865 10.5832 0.916626 8.53114 0.916626 5.99984C0.916626 3.46853 2.96865 1.4165 5.49996 1.4165C8.03126 1.4165 10.0833 3.46853 10.0833 5.99984Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.916667"
      />
    </g>
    <defs>
      <clipPath id="clip0_1372_35221">
        <rect
          height="11"
          width="11"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
