export const ToastIconFriendAdded = () => (
  <svg
    height="46"
    width="46"
    fill="none"
    viewBox="0 0 46 46"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      height="42.5"
      width="42.5"
      fill="#9EDDBB"
      rx="21.25"
      x="1.75"
      y="1.75"
    />
    <rect
      height="42.5"
      width="42.5"
      rx="21.25"
      stroke="#111320"
      strokeWidth="2.5"
      x="1.75"
      y="1.75"
    />
    <path
      d="M25.44 30.05L26.96 31.57L30 28.53"
      stroke="#111320"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />
    <path
      d="M23.16 21.87C23.06 21.86 22.94 21.86 22.83 21.87C20.45 21.79 18.56 19.84 18.56 17.44C18.55 14.99 20.54 13 22.99 13C25.44 13 27.43 14.99 27.43 17.44C27.43 19.84 25.53 21.79 23.16 21.87Z"
      stroke="#111320"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />
    <path
      d="M22.99 32.8101C21.17 32.8101 19.36 32.3501 17.98 31.4301C15.56 29.8101 15.56 27.1701 17.98 25.5601C20.73 23.7201 25.24 23.7201 27.99 25.5601"
      stroke="#111320"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />
  </svg>
);
