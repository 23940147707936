import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useAuthorizationToken, useCurrentUser} from '../user';
import {EarnApi} from '~/api/earn';
import {BlackHoleApi} from '~/api/black-hole/black-hole';
import {delay} from '~/shared/utils/utils';
import {trackConnectAddress, trackDisconnectAddress} from './analytics';
import {getErrorMessage} from '~/api/shared/api-client';
import {isAxiosError} from 'axios';

export function useEarnModel() {
  const user = useCurrentUser();
  const token = useAuthorizationToken();

  const queryClient = useQueryClient();

  const connectWalletMutation = useMutation({
    mutationFn: async (address: string) => {
      try {
        return await EarnApi.connectWallet(
          {
            address,
          },
          token,
        );
      } catch (error) {
        if (isAxiosError(error) && error.response) {
          throw getErrorMessage(error.response);
        }

        throw error;
      }
    },

    onSuccess: (data) => {
      trackConnectAddress();
      queryClient.setQueryData(['user'], data.data);
    },
  });

  const disconnectWalletMutation = useMutation({
    mutationFn: () => {
      return EarnApi.disconnectWallet(token);
    },
    onSuccess: (data) => {
      trackDisconnectAddress();
      queryClient.setQueryData(['user'], data.data);
    },
  });

  const throwInBlackHoleMutation = useMutation({
    mutationFn: async (data: {amount: number}) => {
      await delay(800);

      return BlackHoleApi.throwInBlackHole(data, token);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['dock'], data);
    },
  });

  return {
    isWalletConnected: !!user.wallet_address,
    throwInBlackHoleMutation,
    disconnectWalletMutation,
    connectWalletMutation,
  };
}
