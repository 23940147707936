import {ServerTimestamp} from '~/shared/utils/format/format-time';
import {apiClient} from './shared/api-client';

export interface User {
  first_name: string;
  allows_write_to_pm: boolean;
  language_code: 'ru' | 'en';
  id: number;
  telegram_id: number;
  last_name: string;
  created_at: ServerTimestamp;
  friend_code: string;
  referral_code: string;
  referred_by: {
    id: number;
    telegram_id: number;
  } | null;

  is_onboarding_completed: boolean;
  wallet_address: string;
}

export interface IAuthorizationResponse {
  access_token: string;
  token_type: string;
}

export function authorizeUser({
  initDataRaw,
  refCode,
  utmCode,
}: {
  initDataRaw: string;
  refCode?: string;
  utmCode?: string;
}) {
  return apiClient.post<IAuthorizationResponse>('/token/', {
    referrer_code: refCode,
    utm: utmCode,
    init_data: initDataRaw,
  });
}

export async function getUser(token: IAuthorizationResponse) {
  const userResponse = await apiClient.get<User>('/users/me/', {
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });
  return userResponse;
}

export async function completeOnboarding(token: IAuthorizationResponse) {
  const userResponse = await apiClient.post<User>(
    '/users/onboarding/',
    {},
    {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    },
  );

  return userResponse;
}

export const UserApi = {
  authorizeUser,
  getUser,
  completeOnboarding,
};
