export function DockUpgradeIcon() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="luggage-01" clipPath="url(#clip0_229_7594)">
        <path
          id="Icon"
          d="M7.16659 18.3444V16.6777M8.41659 12.511V5.84438M13.8333 18.3444V16.6777M12.5833 12.511V5.84438M7.83325 16.6777H13.1666C14.5667 16.6777 15.2668 16.6777 15.8016 16.4052C16.272 16.1655 16.6544 15.7831 16.8941 15.3127C17.1666 14.7779 17.1666 14.0778 17.1666 12.6777V5.67772C17.1666 4.27758 17.1666 3.57752 16.8941 3.04274C16.6544 2.57233 16.272 2.18988 15.8016 1.9502C15.2668 1.67772 14.5667 1.67772 13.1666 1.67772H7.83325C6.43312 1.67772 5.73306 1.67772 5.19828 1.9502C4.72787 2.18988 4.34542 2.57233 4.10574 3.04274C3.83325 3.57752 3.83325 4.27758 3.83325 5.67771V12.6777C3.83325 14.0778 3.83325 14.7779 4.10574 15.3127C4.34542 15.7831 4.72787 16.1655 5.19828 16.4052C5.73306 16.6777 6.43312 16.6777 7.83325 16.6777Z"
          stroke="#111320"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_229_7594">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5 0.0110474)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
