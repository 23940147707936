import React from 'react';
import cloudTailSvg from './assets/cloud-tail.svg';
import {useAppTheme} from '../theme';
import {rem} from '~/shared/utils/style-helpers';
import {Box} from '../box';
import {TooltipTail} from './assets/cloud-tail';

type TooltipTailPosition =
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right'
  | 'bottom-center'
  | 'top-center';

export interface ITooltipProps extends React.HTMLProps<HTMLDivElement> {
  isVisible?: boolean;
  renderTail?: boolean;
  tailPosition?: TooltipTailPosition;
  tailSize?: number;
  backgroundColor?: string;
}

function useTooltip({isVisible}: ITooltipProps) {
  const visibilityStyles:
    | {}
    | {
        visibility: 'visible' | 'hidden';
      } =
    isVisible === undefined
      ? {}
      : {visibility: isVisible ? 'visible' : 'hidden'};

  const positionStyles: Record<
    TooltipTailPosition,
    {
      left?: string;
      right?: string;
      top?: string;
      bottom?: string;
      transform?: string;
    }
  > = {
    'bottom-left': {
      top: `calc(100% - 10px)`,
      left: '20px',
      transform: 'translateX(-50%)',
    },
    'bottom-right': {
      top: `calc(100% - 10px)`,
      right: '20px',
      transform: 'translateX(-50%) scaleX(-1)',
    },
    'top-left': {
      bottom: `calc(100% - 10px)`,
      left: '20px',
      transform: 'translateX(-50%) rotate(180deg) scaleX(-1)',
    },
    'top-right': {
      bottom: `calc(100% - 10px)`,
      right: '20px',
      transform: 'translateX(-50%) rotate(180deg)',
    },
    'bottom-center': {
      top: `calc(100% - 10px)`,
      left: '50%',
      transform: 'translateX(-50%)',
    },
    'top-center': {
      bottom: `calc(100% - 10px)`,
      left: '50%',
      transform: 'translateX(-50%) rotate(180deg)',
    },
  };

  return {
    visibilityStyles,
    positionStyles,
  };
}

export function Tooltip({
  children,
  isVisible,
  renderTail = true,
  tailPosition = 'bottom-left',
  tailSize = 20,
  ...rest
}: ITooltipProps) {
  const theme = useAppTheme();

  const {visibilityStyles, positionStyles} = useTooltip({isVisible});

  return (
    <div
      data-tooltip=""
      css={{
        position: 'absolute',
        padding: rem(12),
        backgroundColor: theme.colors.surfaceAlternative,
        color: theme.colors.onSurfaceAlternative,
        borderRadius: 9999999,
        fontSize: theme.fontSize.s7,
        whiteSpace: 'initial',
        fontWeight: 'initial',
        ...visibilityStyles,
      }}
      {...rest}
    >
      {children}
      {renderTail && (
        <img
          src={cloudTailSvg}
          alt="cloud-tail"
          css={{
            position: 'absolute',
            transform: 'translateX(-50%)',
            zIndex: 1,
            width: tailSize,
            height: tailSize,
            ...positionStyles[tailPosition || 'bottom-left'],
          }}
        />
      )}
    </div>
  );
}

export function TooltipHint({
  children,
  isVisible,
  renderTail = true,
  tailPosition = 'bottom-left',
  tailSize = 20,
  backgroundColor = '#A5A2CC',
  ...rest
}: ITooltipProps) {
  const theme = useAppTheme();
  const {visibilityStyles, positionStyles} = useTooltip({isVisible});

  return (
    <Box
      data-tooltip=""
      backgroundColor={backgroundColor}
      radius={20}
      css={{
        position: 'absolute',
        padding: rem(14),
        color: theme.colors.onSurfaceAlternative,
        whiteSpace: 'initial',
        fontWeight: 'initial',
        ...visibilityStyles,
      }}
      {...rest}
    >
      {children}
      {renderTail && (
        <TooltipTail
          css={{
            position: 'absolute',
            transform: 'translateX(-50%)',
            color: backgroundColor,
            zIndex: -1,
            width: tailSize,
            height: tailSize,
            ...positionStyles[tailPosition || 'bottom-left'],
          }}
        />
      )}
    </Box>
  );
}
