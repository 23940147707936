import {AspectRatio} from '~/components/kit/aspect-ratio';
import {Box} from '~/components/kit/box';
import {Button} from '~/components/kit/button/button';
import {ImgTransition} from '~/components/kit/img-transition';
import {Typography} from '~/components/kit/typography';
import {rem} from '~/shared/utils/style-helpers';
import {TRAILBLAZER_GAME_MODAL_NAME} from './trailblazer-game';
import {useModals} from '~/components/kit/modal';
import cardBg from './assets/open-card-bg.png';
import cardBgFull from './assets/open-card-bg-full.png';
import galacticTrailblazerTextSvg from './assets/galactic-trailblazer.svg';
import galacticTrailblazerTextPng from './assets/galactic-trailblazer.png';
import eventChevronPng from './assets/event-chevron.png';
import {useAppTheme} from '~/components/kit';
import {HowItWorks} from '~/components/kit/how-it-works';
import {
  TrailblazerHowToModal,
  TRAILBLAZER_HOWTO_MODAL_NAME,
} from './howto-modal';

export enum TrailblazerCardType {
  full = 'full',
  short = 'short',
}

const BgImageShort = () => (
  <AspectRatio ratio={670 / 680}>
    <ImgTransition
      src={cardBg}
      css={{
        mixBlendMode: 'exclusion',
      }}
    />
  </AspectRatio>
);

const BgImageFull = () => (
  <ImgTransition
    src={cardBgFull}
    css={{
      width: '100%',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      mixBlendMode: 'exclusion',
      objectFit: 'cover',
      objectPosition: 'bottom center',
    }}
  />
);

export function TrailblazerOpenCard({
  type = TrailblazerCardType.full,
}: {
  type?: TrailblazerCardType;
}) {
  const modals = useModals();
  const theme = useAppTheme();
  const bgImage =
    type === TrailblazerCardType.full ? <BgImageFull /> : <BgImageShort />;

  return (
    <Box
      backgroundColor="#100522"
      bgImage={bgImage}
      css={{
        padding: rem(20),
        textAlign: 'center',
      }}
    >
      <div
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          pointerEvents: 'none',
          zIndex: 1,
        }}
      >
        <ImgTransition
          src={eventChevronPng}
          css={{
            position: 'absolute',
            top: '-35px',
            left: '0.5%',
            transform: 'scale(0.5)',
          }}
        />
      </div>
      <AspectRatio
        ratio={74 / 276}
        css={{
          marginBottom: rem(16),
        }}
      >
        <ImgTransition src={galacticTrailblazerTextPng} />
      </AspectRatio>
      <Typography.body
        css={{
          color: theme.colors.onSurfaceAlternative,
        }}
      >
        Forge your path through the cosmos! Match the mystic planetary sequence
        and reap stellar rewards. Every correct guess counts.
      </Typography.body>
      <Typography.h4
        css={{
          color: theme.colors.onSurfaceAlternative,
          marginTop: rem(20),
        }}
      >
        Decode the daily galactic trail!
      </Typography.h4>
      <Button
        color="red"
        css={{
          width: '100%',
          marginTop: rem(20),
        }}
        onClick={() => {
          modals.open(TRAILBLAZER_GAME_MODAL_NAME);
        }}
      >
        Start Playing
      </Button>

      <TrailblazerHowToModal />
      <HowItWorks
        isAlternative={true}
        css={{
          marginTop: rem(20),
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
        onClick={() => {
          modals.open(TRAILBLAZER_HOWTO_MODAL_NAME);
        }}
      />
      {type === TrailblazerCardType.full && (
        <div
          css={{
            height: rem(170),
          }}
        />
      )}
    </Box>
  );
}
