export const ToastIconSomethingWentWrong = () => (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.75"
      y="1.75"
      width="42.5"
      height="42.5"
      rx="21.25"
      fill="#FFA0C2"
    />
    <rect
      x="1.75"
      y="1.75"
      width="42.5"
      height="42.5"
      rx="21.25"
      stroke="#111320"
      stroke-width="2.5"
    />
    <path
      d="M21.75 13.4499C22.45 12.8599 23.58 12.8599 24.26 13.4499L25.84 14.7999C26.14 15.0499 26.71 15.2599 27.11 15.2599H28.81C29.87 15.2599 30.74 16.1299 30.74 17.1899V18.8899C30.74 19.2899 30.95 19.8499 31.2 20.1499L32.55 21.7299C33.14 22.4299 33.14 23.5599 32.55 24.2399L31.2 25.8199C30.95 26.1199 30.74 26.6799 30.74 27.0799V28.7799C30.74 29.8399 29.87 30.7099 28.81 30.7099H27.11C26.71 30.7099 26.15 30.9199 25.85 31.1699L24.27 32.5199C23.57 33.1099 22.44 33.1099 21.76 32.5199L20.18 31.1699C19.88 30.9199 19.31 30.7099 18.92 30.7099H17.17C16.11 30.7099 15.24 29.8399 15.24 28.7799V27.0699C15.24 26.6799 15.04 26.1099 14.79 25.8199L13.44 24.2299C12.86 23.5399 12.86 22.4199 13.44 21.7299L14.79 20.1399C15.04 19.8399 15.24 19.2799 15.24 18.8899V17.1999C15.24 16.1399 16.11 15.2699 17.17 15.2699H18.9C19.3 15.2699 19.86 15.0599 20.16 14.8099L21.75 13.4499Z"
      stroke="#111320"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23 19.13V23.96"
      stroke="#111320"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.9945 27H23.0035"
      stroke="#111320"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
