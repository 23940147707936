import {css} from '@emotion/react';
import {AppTheme} from '../theme';

type VolumetricBoxShadowOptions = {
  shadowColor?: string;
  orientation?: 'left' | 'right';
};

export const volumetricBoxShadow = (
  theme: AppTheme,
  options: VolumetricBoxShadowOptions = {},
) => {
  const {shadowColor, orientation = 'right'} = options;

  return css`
    ${shadowColor ? `--box-shadow-color: ${shadowColor};` : ''}
    box-shadow: ${2 *
    (orientation === 'right' ? 1 : -1)}px 3px 0 0 var(--box-shadow-color);
  `;
};
