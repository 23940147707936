import {ServerTimestamp} from '~/shared/utils/format/format-time';
import {apiClient} from './shared/api-client';
import {IAuthorizationResponse, User} from './user';

interface IReferralShort {
  first_name: string;
  last_name?: string;
  created_at: ServerTimestamp;
}
export interface IReferralInfoResponse {
  referral_bonus: number;
  total_referrals: number;
  total_active_referrals: number;
  total_referrals_by_week: number[];
  n_last_referrals: IReferralShort[];
  earned_on_referral_program: string;
}

function getReferralInfo(token: IAuthorizationResponse) {
  return apiClient.get<IReferralInfoResponse>('/referral-stats/', {
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });
}

function restoreReferrer(refCode: string, token: IAuthorizationResponse) {
  return apiClient.post<User>(
    '/users/restore-referrer-code/',
    {
      referrer_code: refCode,
    },
    {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    },
  );
}

export const ReferralsApi = {
  getReferralInfo,
  restoreReferrer,
};
