export const ArrowRightIcon = (props: React.HTMLProps<HTMLDivElement>) => (
  <div
    css={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    {...props}
  >
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="vuesax/linear/arrow-right">
        <g id="vuesax/linear/arrow-right_2">
          <g id="arrow-right">
            <path
              id="Vector"
              d="M8.48169 3.95333L12.2754 8L8.48169 12.0467"
              stroke="currentColor"
              strokeWidth="2"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_2"
              d="M2.84546 8L12.2205 8"
              stroke="currentColor"
              strokeWidth="2"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
);
