import React, {FC} from 'react';
import {Card, ICardProps, useAppTheme} from '~/components/kit';
import doneRibbonSvg from './assets/done-ribbon.svg?url';
import {rem} from '~/shared/utils/style-helpers';
import {RewardsRibbon} from './assets/done-ribbon';
import {DoneRibbon} from '~/components/kit/done-ribbon/done-ribbon';

export const RewardsCardTitle: FC<
  {
    children: React.ReactNode;
  } & React.HTMLProps<HTMLHeadingElement>
> = ({children, ...rest}) => {
  const theme = useAppTheme();

  return (
    <h4
      css={{
        fontSize: 24,
        fontWeight: 800,
        lineHeight: 1,
        color: theme.colors.onSurface,
      }}
      {...rest}
    >
      {children}
    </h4>
  );
};

export const RewardsCardDescription: FC<
  {
    children: React.ReactNode;
  } & React.HTMLProps<HTMLParagraphElement>
> = ({children, ...rest}) => {
  const theme = useAppTheme();

  return (
    <p
      css={{
        fontSize: theme.fontSize.s6,
        color: theme.colors.onSurfaceSecondary,
        fontWeight: 500,
        lineHeight: 1.35,
      }}
      {...rest}
    >
      {children}
    </p>
  );
};

export function RewardCard({
  isDone,
  isSoon = false,
  children,
  isAlternative = false,
  ...rest
}: {
  isDone: boolean;
  isSoon?: boolean;
  isAlternative?: boolean;
} & Omit<ICardProps, 'rounding'>) {
  const isRibbonShown = isDone || isSoon;

  const theme = useAppTheme();

  return (
    <Card
      rounding="medium"
      shadowOffsetX={2}
      shadowOffsetY={2}
      borderWidth={isDone ? 2 : 0}
      shadowColor="#5DCB90"
      isAlternative={isAlternative}
      {...rest}
    >
      <DoneRibbon
        isSoon={isSoon}
        isDone={isDone}
        css={{
          position: 'absolute',
          top: rem(10),
          right: rem(-10),
        }}
      />

      {children}
    </Card>
  );
}
