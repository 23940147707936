import {Header} from '~/app/header';
import {PageLayout} from '~/components/layouts/page-layout';
import {Icon} from '~/components/kit/icons';
import React from 'react';
import {Card, RewardValue, useAppTheme} from '~/components/kit';
import {Delimiter} from '~/components/kit/delimiter';
import {rem} from '~/shared/utils/style-helpers';
import {Button, ButtonLike} from '~/components/kit/button/button';
import {useEarnModel} from '~/entities/earn';
import {Typography} from '~/components/kit/typography';
import {useDockModel} from '~/entities/dock';
import {
  formatNumber,
  formatWithCommas,
} from '~/shared/utils/format/format-numbers';
import {lunar} from '~/entities/lunar-loot';
import {ImgTransition} from '~/components/kit/img-transition';
import blackHolePng from './assets/black-hole.png';
import blackHoleEventEndedTextSvg from './assets/black-hole-ended.svg';

import {AspectRatio} from '~/components/kit/aspect-ratio';
import {BlackHoleProgressBar} from '~/entities/earn/black-hole/ui/black-hole-progress-bar';
import {
  getBlackHoleRank,
  getNextBlackHoleRank,
  isLastBlackHoleRank,
} from '~/entities/earn/black-hole/black-hole-ranks';
import {ReferralBooster} from './referral-booster';
import {useReferralInfo} from '~/entities/referral-link/model';
import {AirdropDistributionCounter} from './aidrop-distribution-counter';
import bgPng from './assets/bg.png';
import {BellIcon} from '~/components/kit/icons/bell-icon';
import {BlackholeSpeech} from './blackhole-speech/blackhole-speech';
import {BLACK_HOLE_HOW_IT_WORKS_MODAL, BlackholeModal} from './blackhole-modal';
import {useModals} from '~/components/kit/modal';
import {IS_FIRST_SEASON_AIRDROP_ENDED} from '~/shared/feature-flags';
import {RokeTokenBalanceCard} from '~/features/airdrops/balance-card';
import {useSignals} from '@preact/signals-react/runtime';
import {WalletOpenButton} from '~/features/connect-wallet';

export function EarnConnectedWallet() {
  useSignals();

  const theme = useAppTheme();
  const dockModel = useDockModel();
  const earnModel = useEarnModel();
  const refModel = useReferralInfo();
  const modals = useModals();

  const currentBlackHoleRank = getBlackHoleRank(
    dockModel.dock.black_hole_balance,
  );

  const nextBlackHoleRank = getNextBlackHoleRank(
    dockModel.dock.black_hole_balance,
  );

  const stepGap = nextBlackHoleRank.size - currentBlackHoleRank.size;

  let depositAmount = Math.round(stepGap / 10 + 1);

  if (isLastBlackHoleRank(dockModel.dock.black_hole_balance)) {
    depositAmount = Math.round(currentBlackHoleRank.size / 50);
  }

  const notEnoughBalance = dockModel.dock.balance < depositAmount;

  const isEventEnded = IS_FIRST_SEASON_AIRDROP_ENDED();

  return (
    <PageLayout>
      <BlackholeModal />
      <Header />

      <Card
        rounding={'small'}
        css={{
          marginTop: rem(16),
        }}
        bgImage={
          !isEventEnded && (
            <ImgTransition
              src={bgPng}
              css={{
                position: 'absolute',
                bottom: 0,
                height: rem(320),
                minWidth: '100%',
                objectFit: 'cover',
              }}
            ></ImgTransition>
          )
        }
      >
        <div
          css={{
            textAlign: 'center',
          }}
        >
          <Typography.h1>Fuel Black Hole</Typography.h1>
          <p
            css={{
              fontSize: theme.fontSize.s4,
              marginTop: rem(5),
            }}
          >
            with Your Lunar Coin
          </p>
        </div>
        <div
          css={{
            textAlign: 'center',
          }}
        >
          <ButtonLike
            color="white"
            css={{
              padding: `${rem(4)} ${rem(12)}`,
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'inline-flex',
              marginTop: rem(20),
              fontSize: theme.fontSize.s7,
            }}
            orientation="right"
          >
            <span
              css={{
                fontWeight: 400,
              }}
            >
              In Storage:
            </span>
            &nbsp;
            <RewardValue
              value={formatWithCommas(lunar(dockModel.dock.balance))}
            />
          </ButtonLike>
        </div>
        <div
          css={{
            position: 'relative',
          }}
        >
          <BlackholeSpeech
            size={dockModel.dock.black_hole_balance}
            css={{
              position: 'absolute',
              zIndex: 2,
              left: '50%',
              top: '35%',
            }}
          />
          <AspectRatio
            ratio={155 / 303}
            css={{
              marginTop: rem(20),
              marginBottom: rem(20),
            }}
          >
            <ImgTransition src={blackHolePng} />
            {isEventEnded && (
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ImgTransition
                  src={blackHoleEventEndedTextSvg}
                  css={{
                    width: '75%',
                  }}
                />
              </div>
            )}
          </AspectRatio>
        </div>

        <BlackHoleProgressBar amount={dockModel.dock.black_hole_balance} />

        {!isEventEnded && (
          <React.Fragment>
            <Button
              color="red"
              css={{
                width: '100%',
                marginTop: rem(10),
                padding: `${rem(16)} ${rem(12)}`,
              }}
              isDisabled={
                earnModel.throwInBlackHoleMutation.isPending || notEnoughBalance
              }
              isLoading={earnModel.throwInBlackHoleMutation.isPending}
              onClick={() => {
                earnModel.throwInBlackHoleMutation.mutate({
                  amount: depositAmount,
                });
              }}
            >
              Throw&nbsp;
              <RewardValue value={formatNumber(lunar(depositAmount))} />
              &nbsp;In!
            </Button>
            <a
              onClick={() => {
                modals.open(BLACK_HOLE_HOW_IT_WORKS_MODAL);
              }}
              css={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                marginTop: rem(14),
              }}
            >
              <Typography.h6>How it works?</Typography.h6>
            </a>
          </React.Fragment>
        )}
        {isEventEnded && (
          <RokeTokenBalanceCard
            blackHoleSize={dockModel.dock.black_hole_balance}
            css={{
              marginTop: rem(30),
            }}
          />
        )}

        <Delimiter
          color={theme.colors.onSurfaceTertiary}
          css={{
            marginTop: rem(24),
            marginBottom: rem(24),
          }}
        />

        <div
          css={{
            maxWidth: rem(280),
            margin: 'auto',
          }}
        >
          <ReferralBooster refCount={refModel.data.total_referrals} />
        </div>

        {!isEventEnded && (
          <React.Fragment>
            <AirdropDistributionCounter
              css={{
                marginTop: rem(36),
              }}
            />
            <div
              css={{
                height: rem(320),
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Icon
                size={60}
                css={{
                  marginRight: 'auto',
                  marginBottom: rem(16),
                }}
              >
                <BellIcon />
              </Icon>
              <Typography.h4
                css={{
                  color: theme.colors.onSurfaceAlternative,
                  textAlign: 'center',
                  maxWidth: rem(280),
                }}
              >
                We'll beam you an alert when this Airdrop will Income!
              </Typography.h4>
            </div>
          </React.Fragment>
        )}
      </Card>
    </PageLayout>
  );
}
