import {analyticsAdapter} from '~/shared/analytics';

export function trackOnboardingStep({
  step,
  source,
}: {
  step: number;
  source: 'registration' | 'button';
}) {
  analyticsAdapter.track('Onboarding: click button', {
    step,
    source,
  });
}
