export function RocketUpgradeIcon() {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Icon"
        d="M11.2436 14.3445L8.16667 11.2676M11.2436 14.3445C12.6763 13.7996 14.0507 13.1124 15.3462 12.2932M11.2436 14.3445V19.4726C11.2436 19.4726 14.3513 18.9085 15.3462 17.4213C16.4538 15.7598 15.3462 12.2932 15.3462 12.2932M8.16667 11.2676C8.71246 9.85163 9.3997 8.49435 10.2179 7.21635C11.413 5.30559 13.077 3.73234 15.0518 2.64625C17.0265 1.56015 19.2463 0.997323 21.5 1.0113C21.5 3.80101 20.7 8.70351 15.3462 12.2932M8.16667 11.2676H3.03846C3.03846 11.2676 3.60256 8.15993 5.08974 7.16507C6.75128 6.05739 10.2179 7.16507 10.2179 7.16507M3.55128 15.8829C2.01282 17.1752 1.5 21.011 1.5 21.011C1.5 21.011 5.3359 20.4982 6.62821 18.9598C7.35641 18.0983 7.34615 16.7752 6.5359 15.9752C6.13724 15.5947 5.6121 15.3748 5.06126 15.3578C4.51042 15.3408 3.97269 15.5278 3.55128 15.8829Z"
        stroke="#111320"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
