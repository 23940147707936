import * as Sentry from '@sentry/react';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import {EnvironmentVariables} from '~/app/env';
import {FullscreenError} from '~/components/kit/full-screen-error';

export const manualSentryClient = new Sentry.Scope();

const manualClient = new Sentry.BrowserClient({
  dsn: EnvironmentVariables.SENTRY_DSN,
  integrations: [Sentry.extraErrorDataIntegration()],
  transport: Sentry.makeFetchTransport,
  stackParser: Sentry.defaultStackParser,
  environment: EnvironmentVariables.APP_ENV,
  ignoreTransactions: ['mixpanel'],
  ignoreErrors: [
    'Java object is gone',
    'window.TelegramGameProxy.receiveEvent',
    'Java bridge method invocation error',
    'Unable to determine current environment and possible way to send event.',
  ],

  sampleRate: 1,
});

manualSentryClient.setClient(manualClient);
manualClient.init();

Sentry.init({
  debug: false,
  dsn: EnvironmentVariables.SENTRY_DSN,

  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation: useLocation,
      useNavigationType: useNavigationType,
      createRoutesFromChildren: createRoutesFromChildren,
      matchRoutes: matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.extraErrorDataIntegration(),
  ],
  environment: EnvironmentVariables.APP_ENV,
  // Performance Monitoring

  tracePropagationTargets: [
    'localhost',
    EnvironmentVariables.API_BASE_URL,
    /roke.to/,
  ],

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  ignoreTransactions: ['mixpanel'],
  ignoreErrors: [
    'Java object is gone',
    'window.TelegramGameProxy.receiveEvent',
    'Java bridge method invocation error',
    'Unable to determine current environment and possible way to send event.',
  ],
  sampleRate: 0.1,
  tracesSampler(samplingContext) {
    return 0.0005;
  },
  replaysSessionSampleRate: 0.001, // This sets the sample rate at 1%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export function SentryErrorBoundary({children}: {children: React.ReactNode}) {
  return (
    <Sentry.ErrorBoundary
      fallback={({error}) => <FullscreenError error={error} />}
      showDialog
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export * as Sentry from '@sentry/react';
