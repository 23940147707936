export const CrystalsMenuIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.00274 23.821L16.3385 34.934M5.00274 23.821L16.3385 34.934M5.00274 23.821C3.11541 21.9707 2.90486 19.0033 4.51203 16.9051L9.17347 10.8195C10.1633 9.52728 11.6982 8.76951 13.3259 8.76951H26.6746C28.3024 8.76951 29.8374 9.52731 30.8272 10.8196L35.4884 16.9052C37.0955 19.0033 36.8849 21.9707 34.9977 23.8209L23.6621 34.934C21.6281 36.928 18.3725 36.928 16.3385 34.934M5.00274 23.821L16.3385 34.934" fill="#D047B2" stroke="#FFC2F2" strokeWidth="6.46147" />
    <circle cx="32.9767" cy="6.97674" r="6.97674" fill="#FFC2F2" />
    <path d="M32.9771 9.97668V3.97668M29.9771 6.97668H35.9771" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.6002 32.627L7.26444 21.5139C6.54282 20.8065 6.46232 19.6719 7.07681 18.8697L11.7383 12.784C12.1167 12.29 12.7036 12.0002 13.3259 12.0002H26.6746C27.297 12.0002 27.8839 12.29 28.2623 12.7841L32.9236 18.8697C33.538 19.6719 33.4575 20.8065 32.7359 21.5139L21.4004 32.627C20.6227 33.3894 19.3779 33.3894 18.6002 32.627Z" fill="#D047B2" />
    <path d="M18.6002 32.627L7.26444 21.5139C6.54282 20.8065 6.46231 19.6719 7.07681 18.8697L11.7383 12.784C12.1167 12.29 12.7036 12.0002 13.3259 12.0002H26.6746C27.297 12.0002 27.8839 12.29 28.2623 12.7841L32.9236 18.8697C33.538 19.6719 33.4575 20.8065 32.7359 21.5139L21.4004 32.627C20.6227 33.3894 19.3779 33.3894 18.6002 32.627Z" stroke="black" strokeWidth="2" strokeLinejoin="round" />
    <path d="M8.23957 14.6239C8.05938 14.4533 7.68402 14.5499 7.61959 14.3059C7.55415 14.055 7.92895 13.9822 8.03005 13.7681C8.0514 13.7232 8.07187 13.6777 8.09311 13.632C8.16838 13.6307 8.20985 13.5877 8.23043 13.5208C8.39779 13.3642 8.53868 13.1894 8.63334 12.9802C8.69316 12.9432 8.71907 12.8913 8.70352 12.8218C8.80762 12.6506 8.91182 12.4803 9.01592 12.3091C9.04027 12.2796 9.06529 12.2492 9.08965 12.2197L9.08698 12.2232C9.10633 12.192 9.12557 12.1601 9.14492 12.1289L9.1467 12.1247C9.20407 12.0873 9.22065 12.0312 9.21777 11.967L9.21232 11.9733C9.23234 11.9412 9.25303 11.9083 9.27304 11.8762C9.35411 11.8101 9.39425 11.7247 9.39116 11.6202L9.39238 11.6177C9.41563 11.5639 9.4381 11.5101 9.46124 11.4555C9.64167 10.92 9.83544 10.3889 9.94527 9.83202C9.96352 9.7379 9.95454 9.60903 10.0671 9.56841C10.2256 9.51093 10.2096 9.68125 10.2672 9.75028C11.361 11.0666 12.6758 12.0791 14.3111 12.6527C14.4533 12.7025 14.6518 12.7245 14.4463 12.9473C13.2186 14.2761 12.4371 15.8422 11.9609 17.6247C11.1078 16.6524 10.2542 15.7306 9.12691 15.1219C9.09101 15.0987 9.05421 15.0748 9.01831 15.0515C8.98951 15.0336 8.96072 15.0156 8.93271 14.9976C8.90225 14.9791 8.87101 14.9607 8.84055 14.9422C8.82743 14.9283 8.81176 14.9242 8.79352 14.93C8.75395 14.9089 8.71448 14.8885 8.67491 14.8674L8.68102 14.8712C8.64856 14.8499 8.6161 14.8285 8.58442 14.807L8.58709 14.809C8.55574 14.7898 8.52506 14.7698 8.49371 14.7506C8.44435 14.7277 8.39577 14.7047 8.34641 14.6819C8.31028 14.6626 8.27404 14.6426 8.23869 14.6232L8.23957 14.6239Z" fill="white" />
    <path d="M20.2906 30.3297C19.5477 28.5803 19.1334 26.7243 18.5492 24.9234C17.6001 21.9964 16.6904 19.0579 15.7548 16.1274C15.5779 15.5708 15.5742 15.3193 16.3227 15.4516C19.4867 16.0113 22.6631 16.5115 25.834 17.0388C25.9803 17.0633 26.12 17.1323 26.2633 17.1818C26.2432 17.3582 26.2593 17.548 26.1986 17.7106C24.7047 21.6403 23.3371 25.626 21.1301 29.2481C20.8903 29.6407 20.7513 30.1072 20.2927 30.332L20.2906 30.3297Z" fill="#EE83FF" />
    <path d="M14.9536 19.0011C16.1802 22.2402 17.3463 25.3203 18.5517 28.5046C17.8927 28.0168 17.5671 27.376 17.0945 26.8671C15.7523 25.4174 14.4278 23.9524 13.0713 22.5146C12.7554 22.1804 12.6672 21.9425 12.9902 21.5487C13.6385 20.7611 14.2402 19.9329 14.9525 19L14.9536 19.0011Z" fill="#6236CC" />
    <path d="M27.0207 19.0011C25.5442 22.1342 24.1406 25.1136 22.6896 28.1937C23.3847 27.759 23.7594 27.1457 24.2705 26.6753C25.7221 25.3351 27.1572 23.9783 28.6221 22.6511C28.9632 22.3427 29.0698 22.1124 28.7786 21.6945C28.1939 20.8586 27.659 19.9858 27.0219 19L27.0207 19.0011Z" fill="#6236CC" />
  </svg>
);
