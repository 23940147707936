import {IDockInfoResponse} from '../dock';
import {apiClient} from '../shared/api-client';
import {IAuthorizationResponse} from '../user';

function throwInBlackHole(
  data: {
    amount: number;
  },
  token: IAuthorizationResponse,
) {
  return apiClient.post<IDockInfoResponse>(
    '/dock/throw-in-black-hole/',
    {
      amount: data.amount,
    },
    {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    },
  );
}

export const BlackHoleApi = {
  throwInBlackHole,
};
