// fill array to the given length repeating the given items
export function makeArray<T>(length: number, ...items: T[]) {
  const result = [];
  for (let i = 0; i < length; i++) {
    result.push(items[i % items.length]);
  }
  return result;
}

export function getElementCircularly(arr: any[], index: number) {
  return arr[index % arr.length];
}
