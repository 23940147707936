import React, {useEffect} from 'react';
import {DockBalanceCalculator, DockProgressCalculator} from './math';
import {toClientTimestamp} from '~/shared/utils/format/format-time';
import {IIdleMiningInfo} from '~/api/dock';

interface IDockProgressProps {
  idleMining: Pick<
    IIdleMiningInfo,
    | 'min_claim_time'
    | 'last_claimed_time'
    | 'dock_size'
    | 'combined_lunar_loot_speed'
  >;
}

interface IDockProgress {
  timeLeft: number;
  percentageProgress: number;
  canClaim: boolean;
  isFull: boolean;
  timeUntilFull: number;
}

export function useDockProgress({
  idleMining,
}: IDockProgressProps): IDockProgress {
  const [dockProgress, setDockProgress] = React.useState<IDockProgress>({
    timeLeft: 0,
    percentageProgress: 0,
    canClaim: false,
    isFull: false,
    timeUntilFull: 0,
  });

  useEffect(() => {
    const calculate = () => {
      const calculator = new DockProgressCalculator({
        minClaimTime: toClientTimestamp(idleMining.min_claim_time),
        lastClaimedTime: toClientTimestamp(idleMining.last_claimed_time),
      });

      const dockBalance = new DockBalanceCalculator({
        // transform to loot per MS
        combinedIdleMiningSpeed: idleMining.combined_lunar_loot_speed / 1000,
        lastClaimedTime: toClientTimestamp(idleMining.last_claimed_time),
        dockSize: toClientTimestamp(idleMining.dock_size),
      });

      setDockProgress({
        percentageProgress: calculator.percentageProgress,
        timeLeft: calculator.timeLeft,
        canClaim: calculator.canClaim,
        isFull: dockBalance.isFull,
        timeUntilFull: dockBalance.timeUntilFull,
      });
    };

    calculate();
    const interval = setInterval(() => {
      calculate();
    }, 1000);

    return () => clearInterval(interval);
  }, [
    idleMining.min_claim_time,
    idleMining.last_claimed_time,
    idleMining.combined_lunar_loot_speed,
  ]);

  return dockProgress;
}
