export const RocketPurple = () => (
  <svg
    height="77"
    width="96"
    fill="none"
    viewBox="0 0 96 77"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="45.4197"
      cy="35.8249"
      fill="#9A92FF"
      opacity="0.9"
      r="5.09091"
      transform="rotate(-27.6649 45.4197 35.8249)"
    />
    <circle
      cx="37.6277"
      cy="36.6253"
      fill="#9A92FF"
      opacity="0.8"
      r="6.54545"
      transform="rotate(-27.6649 37.6277 36.6253)"
    />
    <circle
      cx="39.1655"
      cy="48.957"
      fill="#9A92FF"
      opacity="0.7"
      r="8"
      transform="rotate(-27.6649 39.1655 48.957)"
    />
    <circle
      cx="26.7088"
      cy="43.9911"
      fill="#9A92FF"
      opacity="0.42"
      r="8"
      transform="rotate(-27.6649 26.7088 43.9911)"
    />
    <circle
      cx="19.2288"
      cy="61.0511"
      fill="#9A92FF"
      opacity="0.4"
      r="6.54545"
      transform="rotate(-27.6649 19.2288 61.0511)"
    />
    <circle
      cx="46.2198"
      cy="43.6169"
      fill="#9A92FF"
      opacity="0.9"
      r="5.09091"
      transform="rotate(-27.6649 46.2198 43.6169)"
    />
    <circle
      cx="31.6859"
      cy="66.0167"
      fill="#9A92FF"
      opacity="0.6"
      r="3.63636"
      transform="rotate(-27.6649 31.6859 66.0167)"
    />
    <circle
      cx="20.6929"
      cy="35.6485"
      fill="#9A92FF"
      opacity="0.6"
      r="3.63636"
      transform="rotate(-27.6649 20.6929 35.6485)"
    />
    <circle
      cx="8.42341"
      cy="40.4385"
      fill="#9A92FF"
      opacity="0.2"
      r="3.63636"
      transform="rotate(-27.6649 8.42341 40.4385)"
    />
    <path
      d="M87.9297 19.2414C88.1257 18.2399 87.6014 17.2364 86.6674 16.8253C82.7191 15.0876 72.8298 13.1782 62.6872 18.1846C60.5654 17.8865 58.6291 17.8546 56.4461 18.3211C54.0794 18.8268 51.5509 19.8915 48.273 21.6041C47.5405 21.9868 47.0871 22.7504 47.1019 23.5767C47.1166 24.403 47.5969 25.1501 48.3425 25.5064C52.1628 27.3323 55.0279 29.4306 57.27 33.7221C59.5122 38.0136 59.5982 41.5638 58.9151 45.7426C58.7818 46.5582 59.1207 47.3791 59.7905 47.8631C60.4604 48.3471 61.3462 48.4111 62.0787 48.0284C65.3566 46.3158 67.6747 44.8484 69.4415 43.1945C71.0712 41.6689 72.1509 40.0613 73.1181 38.1494C83.0203 32.6831 87.1011 23.4748 87.9297 19.2414Z"
      fill="#CACDE2"
      stroke="#151542"
      strokeLinejoin="round"
      strokeWidth="4.36364"
    />
    <mask
      height="30"
      id="mask0_1190_25393"
      style={{maskType: 'alpha'}}
      width="37"
      x="49"
      y="17"
      maskUnits="userSpaceOnUse"
    >
      <path
        d="M85.7885 18.8223C82.2373 17.2594 72.718 15.3962 63.0508 20.4469C58.5658 19.7136 55.7281 20.1707 49.2833 23.5379C53.3837 25.4976 56.6785 27.8782 59.2038 32.7118C61.7292 37.5453 61.8015 41.6095 61.0683 46.0946C67.5131 42.7274 69.509 40.6592 71.4687 36.5588C81.1359 31.5081 85.0432 22.63 85.7885 18.8223Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1190_25393)">
      <path
        d="M86.0781 18.6164C95.7498 23.7758 88.0423 35.7786 72.9798 39.4425C68.4948 38.7093 65.1377 46.0283 58.6929 49.3954C62.7933 51.3551 51.897 25.4509 54.4224 30.2845C56.9477 35.1181 58.2546 34.8442 57.5214 39.3293C63.9662 35.9621 65.9621 33.8939 67.9218 29.7935C77.589 24.7428 86.0781 18.6164 86.0781 18.6164Z"
        fill="#9A92FF"
      />
    </g>
  </svg>
);
