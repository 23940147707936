import {start} from 'repl';

class InvalidUserIdError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'InvalidUserIdError';
  }
}

class InvalidDateError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'InvalidDateError';
  }
}

type DateType = Date | {getTime(): number};

function isDateType(date: any): date is DateType {
  return (
    date instanceof Date ||
    (typeof date === 'object' && typeof date.getTime === 'function')
  );
}

export function getTimeWhenEnabled(
  userId: number,
  startDate: DateType,
  endDate: DateType,
): number {
  if (!Number.isInteger(userId) || userId < 0) {
    throw new InvalidUserIdError('User ID must be a non-negative integer');
  }
  if (!isDateType(startDate)) {
    throw new InvalidDateError('Start date must be a Date object');
  }
  if (!isDateType(endDate)) {
    throw new InvalidDateError('End date must be a Date object');
  }
  if (startDate.getTime() > endDate.getTime()) {
    throw new InvalidDateError(
      'Start date must be before or equal to end date',
    );
  }

  const totalDuration = endDate.getTime() - startDate.getTime();
  const userRequiredProgress = userId % 100;

  return startDate.getTime() + totalDuration * (userRequiredProgress / 100);
}

function isFeatureEnabled(
  userId: number,
  startDate: DateType,
  endDate: DateType,
): boolean {
  if (!Number.isInteger(userId) || userId < 0) {
    throw new InvalidUserIdError('User ID must be a non-negative integer');
  }
  if (!isDateType(startDate) || !isDateType(endDate)) {
    throw new InvalidDateError('Start and end dates must be Date objects');
  }
  if (startDate.getTime() > endDate.getTime()) {
    throw new InvalidDateError(
      'Start date must be before or equal to end date',
    );
  }

  const currentDate = new Date();
  if (currentDate.getTime() < startDate.getTime()) {
    return false;
  }
  if (currentDate.getTime() > endDate.getTime()) {
    return true;
  }

  const totalDuration =
    (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
  const currentProgress =
    (currentDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);

  const currentPercentage =
    1 + Math.floor((99 * currentProgress) / totalDuration);

  const userValue = userId % 100;

  return userValue < currentPercentage;
}

export default isFeatureEnabled;
