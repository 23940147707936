import {AppTheme, useAppTheme} from '~/components/kit';
import {rem} from '~/shared/utils/style-helpers';
import {Chip} from '../chip';

interface IBonusProps extends React.HTMLProps<HTMLDivElement> {
  color: 'primary' | 'alternative' | 'accent' | 'success' | 'critical';
  variant?: 'small' | 'medium' | 'large';
}

const colorSets: (theme: AppTheme) => Record<
  IBonusProps['color'],
  {
    bgColor: string;
    textColor: string;
    borderColor: string;
  }
> = (theme) => ({
  primary: {
    bgColor: theme.colors.surface,
    textColor: theme.colors.onSurface,
    borderColor: theme.colors.onSurface,
  },
  alternative: {
    bgColor: theme.colors.surfaceAlternative,
    textColor: theme.colors.onSurfaceAlternative,
    borderColor: theme.colors.surfaceAlternative,
  },
  accent: {
    bgColor: theme.colors.accents.yellow.surface,
    textColor: theme.colors.accents.yellow.onSurface,
    borderColor: theme.colors.accents.yellow.onSurface,
  },
  success: {
    bgColor: theme.colors.success.surface,
    textColor: theme.colors.success.onSurface,
    borderColor: theme.colors.success.surface,
  },
  critical: {
    bgColor: theme.colors.critical.surface,
    textColor: theme.colors.critical.onSurface,
    borderColor: theme.colors.critical.surface,
  },
});

export function Bonus({
  color,
  children,
  variant = 'small',
  ...rest
}: IBonusProps) {
  const theme = useAppTheme();
  const colors = colorSets(theme);

  const {bgColor, textColor, borderColor} = colors[color];

  return (
    <Chip
      css={{
        border: `1px solid ${borderColor}`,
        backgroundColor: bgColor,
        color: textColor,
      }}
      {...rest}
    >
      {children}
    </Chip>
  );
}
