import React, {useEffect, useState} from 'react';
import {useAppTheme} from '~/components/kit';
import {rem} from '~/shared/utils/style-helpers';

const speeches = [
  'No Refunds in Space!',
  'OM-NOM-NOM',
  'Tasty Stardust!',
  'More, please!',
  'Yum yum yum',
  'Cosmic Snacks',
  "Nom de l'espace",
  'Gulp',
  'Burp! Excuse me',
  'Mmm, crunchy!',
  'Feed me, Seymour!',
  'Stellar cuisine!',
  "Gravity's got taste!",
  'Spacetime snacktime!',
  'Singularity satisfied!',
  "I'm still hungry!",
  'Hole-some meal!',
  'Astronomic delicious!',
  'Voidappetit!',
  'Lunar-licious!',
  'Space junk food!',
  'Quasar quencher!',
];

const SpeechBubbleIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    height="49"
    width="92"
    fill="none"
    viewBox="0 0 92 49"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    vectorEffect={'non-scaling-stroke'}
    {...props}
  >
    <path
      d="M4.54805 10.9829C12.6425 2.76565 28.0361 0.501672 44.4346 0.739156C60.8331 0.97664 75.3807 2.90087 84.3859 9.02732C93.3911 15.1538 92.8827 26.5507 87.1539 31.9677C77.9231 40.6962 71.9208 40.6285 58.5311 42.2182C45.9575 43.7109 25.9522 41.8493 25.9522 41.8493C23.9899 46.8041 18.5784 47.2985 13.5705 48.2741C15.8713 45.2106 16.641 43.5095 16.2486 40.5275C16.2486 40.5275 13.2464 39.2976 8.48991 35.6794C0.709087 29.7606 -3.54634 19.2 4.54805 10.9829Z"
      vectorEffect="non-scaling-stroke"
      fill="white"
    />
  </svg>
);

export function BlackholeSpeech({
  size,
  ...rest
}: {size: number} & React.HTMLProps<HTMLDivElement>) {
  const theme = useAppTheme();

  const [speechIndex, setSpeechIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setSpeechIndex(Math.floor(Math.random() * speeches.length));
    setIsVisible(true);
  }, [size]);

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => setIsVisible(false), 3000);
    }
  }, [isVisible]);

  const text = speeches[speechIndex];
  // gradually decrease font size when text length is long
  const fontSize = text.length < 5 ? 18 : 10;

  return (
    <div
      css={{
        width: rem(90),
        height: rem(50),
        color: theme.colors.onSurface,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        transform: 'rotate(10deg)',
        opacity: isVisible ? 1 : 0,
        transition: 'opacity 0.3s ease',
      }}
      {...rest}
    >
      <SpeechBubbleIcon
        css={{
          width: '100%',
          position: 'absolute',
          zIndex: -1,
        }}
      />

      <div
        css={{
          padding: `${rem(8)} ${rem(6)}`,
          paddingBottom: rem(16),
          fontSize: rem(fontSize),
          fontWeight: 800,
          margin: 'auto',
        }}
      >
        {speeches[speechIndex]}
      </div>
    </div>
  );
}
