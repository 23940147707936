import React, {useEffect, useState} from 'react';
import {
  Modal,
  ModalControlButton,
  ModalSteps,
  ModalStepsControlButton,
  useModalSteps,
  useModals,
} from '~/components/kit/modal';

import {rem} from '~/shared/utils/style-helpers';
import {useDrag} from '@use-gesture/react';
import {Step1, Step2, Step3} from './steps';
import {ScrollableContent} from '../ui/scrollable-content';
import {useMutation} from '@tanstack/react-query';
import {UserApi} from '~/api';
import {
  useAuthorizationToken,
  useCurrentUser,
  useUserQuery,
} from '~/entities/user';
import {ArrowRightIcon} from '~/components/kit/icons/arrow-right-icon';
import {trackOnboardingStep} from './analytics';

function useOnboardingMutation() {
  const userQuery = useUserQuery();

  const token = useAuthorizationToken();

  const mutation = useMutation({
    mutationKey: ['user-onboarding'],
    mutationFn: async () => {
      return UserApi.completeOnboarding(token);
    },
    onSuccess: () => {
      userQuery.refetch();
    },
  });

  return mutation;
}

export function Onboarding({afterClose}: {afterClose?: () => void}) {
  const modals = useModals();
  const isOpen = modals.isOpen('onboarding');
  const user = useCurrentUser();
  const onboardingMutation = useOnboardingMutation();

  const steps = useModalSteps({
    steps: [Step1, Step2, Step3],
  });

  useEffect(() => {
    if (isOpen) {
      steps.setStep(0);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) return;

    trackOnboardingStep({
      step: steps.step + 1,
      source: user.is_onboarding_completed ? 'button' : 'registration',
    });
  }, [steps.step, isOpen]);

  return (
    <Modal
      isClosable={false}
      name="onboarding"
      afterClose={afterClose}
      controlButton={
        <ModalStepsControlButton
          steps={steps}
          afterLastStep={() => {
            onboardingMutation.mutate();
            modals.close();
          }}
        />
      }
    >
      <div
        css={{
          overflow: 'hidden',
        }}
        {...steps.bindDrag()}
      >
        <ScrollableContent
          css={{
            height: rem(430),
            userSelect: 'none',
          }}
        >
          <steps.Step />
        </ScrollableContent>
        <ModalSteps steps={steps.length} activeStep={steps.step} />
      </div>
    </Modal>
  );
}
